import React, { useContext } from 'react';
import AdvancedTableContext from '../../contexts/AdvancedTableContext';
import { useTranslation } from '../../contexts/TranslationProvider';
import { FaLongArrowAltUp } from "react-icons/fa";
import { FaLongArrowAltDown } from "react-icons/fa";
import TableHeaderCell from './TableHeaderCell';
import DateColumnFilter from './DateColumnFilter';
import BasicColumnFilter from './BasicColumnFilter';
import CustomCombobox from '../common/newDesign/CustomCombobox';
import OptionsColumnFilter from './OptionsColumnFilter';

function TableHeader({ hasFilters = true }) { 
  const { columns, data, sort, setSort, setAppliedFilters, appliedFilters } = useContext(AdvancedTableContext);
  const { language } = useTranslation();

  return (
    <thead>
      <tr style={{ display: "flex" }}>
        {
          columns.map((column, index) => (
            <TableHeaderCell column={column} index={index} key={index} />
          ))
        }
      </tr>

      {hasFilters && (  
        <tr className='table-filters' style={{ display: "flex" }}>
          {
            columns.map((column, index) => (
              <React.Fragment key={index}>
                {column.filtrable === false ? (
                  <th style={{ flex: column.width ?? 1 }}></th>
                ) : (
                  <th style={{ flex: column.width ?? 1 }} className={`${language === "ar" && "text-right"}`}>
                    {column.filterOptions ? (
                      <OptionsColumnFilter
                        options={column.filterOptions}
                        columnIdx={index}
                      />
                    ) : column.isDate ? (
                      <DateColumnFilter column={column} />
                    ) : (
                      <BasicColumnFilter column={column} />
                    )}
                  </th>
                )}
              </React.Fragment>
            ))
          }
        </tr>
      )}
    </thead>
  );
}

export default TableHeader;
