import { ContractScoringEntity, ContractTemplateEntity, RessourceScoringEntity } from "../../../domain/entities";
import Clause from "./Clause";

function EditContractClauseList({
  clauseListRef,
  clauses,
  containerId,
  canComment,
  canCompare,
  canEdit,
  mappedClauses,
  updateRessource,
  handleClauseAdd,
  handleClauseDismiss,
  handleClauseReplace,
}: {
  clauseListRef: React.MutableRefObject<any>;
  clauses: RessourceScoringEntity["clauses"] | ContractTemplateEntity["clauses"];
  containerId: 'source-container' | 'target-container';
  canEdit?: boolean;
  canCompare?: boolean;
  canComment?: boolean;
  mappedClauses?: RessourceScoringEntity["mappedClauses"];
  updateRessource: () => void;
  handleClauseAdd?: (clauseId: number) => void;
  handleClauseDismiss?: (clauseId: number) => void;
  handleClauseReplace?: (clauseId: number, replacedById: number) => void;
}) {
  const sortedClauses = clauses?.slice().sort((a: ContractTemplateEntity["clauses"][0] | RessourceScoringEntity["clauses"][0]
  , b: ContractTemplateEntity["clauses"][0] | RessourceScoringEntity["clauses"][0]
) => parseInt(a.index) - parseInt(b.index));

  return (
    <div className="clauses-list">
      {sortedClauses &&
        sortedClauses.length > 0 &&
        sortedClauses.map((clause, clauseIndex) => (
          <Clause
            key={clause.id}
            clause={clause}
            containerId={containerId}
            canComment={canComment}
            canCompare={canCompare}
            canEdit={canEdit}
            mappedClauses={mappedClauses}
            updateRessource={updateRessource}
            handleClauseAdd={handleClauseAdd}
            handleClauseDismiss={handleClauseDismiss}
            handleClauseReplace={handleClauseReplace}
          />
        ))}
    </div>
  );
}

export default EditContractClauseList;
