import React from "react";
import { SegmentedTextType } from "../../domain/types/ClauseParams";
import { renderStyledSegment } from "../../helpers/renderSegment";
import TableRender from "./TableRender";


interface RenderSingleSegmentProps {
  segment: any; // Replace with the correct type for your segment
  isSelected: boolean;
  isEditing: boolean;
  editingSegmentId: string | null;
  handleStaticContentChange: (id: string) => (content: string) => void;
  openFilePreview: (id: string) => void;
  changedParamBoundaries: [number, number][];
  stateSync: any;
  spanRef: any;
  segmentIdx: number;
  segmentation: any;
  inputValues: Record<string, any>;
  segmentsOverrides: Record<string, string>;
}

const RenderSingleSegment: React.FC<RenderSingleSegmentProps> = ({
  segment,
  isSelected,
  isEditing,
  editingSegmentId,
  handleStaticContentChange,
  openFilePreview,
  changedParamBoundaries,
  stateSync,
  spanRef,
  segmentIdx,
  segmentation,
  inputValues,
  segmentsOverrides
}) => {
  const { id, value, type } = segment;
  let text = value
  if (id in segmentsOverrides) {
    text = segmentsOverrides[id] || "\u200B"
  }
  const isHighlighted = changedParamBoundaries.some(
    (b) => b[0] < segmentIdx && segmentIdx < b[1]
  );
  const styledSegment = renderStyledSegment({ ...segment, value: text });

  const isSegmentEditing = isEditing && editingSegmentId === id;

  switch (type) {
    case SegmentedTextType.PARAM_START:
    case SegmentedTextType.PARAM_END:
      return null; // Skip rendering

    case SegmentedTextType.STATIC:
      return (<span
        key={id}
        className={`${isSelected ? "selected-text" : ""} ${isHighlighted ? "highlighted-text" : ""} preserve-spaces`}
        {...(isSegmentEditing && {
          onClick: (e) => stateSync.set(id),
          onBlur: e => {
            stateSync.set((prev) => id == prev ? null : prev)
            handleStaticContentChange(id)(e.target.innerText)
          },
          contentEditable: true
        }
          || isEditing && {
            contentEditable: true,
            suppressContentEditableWarning: true,
            onClick: (e) => stateSync.set(id),
          }
          || {})}
      >
        {isSegmentEditing ? text : styledSegment}
      </span>

      )

    case SegmentedTextType.COMMENT:
      return (
        <span
          key={id}
          className={`comment ${isHighlighted ? "highlighted-text" : ""} preserve-spaces`}
          dangerouslySetInnerHTML={{ __html: value.replace(/\n/g, "<br/>") }}
        />
      );

    case SegmentedTextType.PARAM:
      const paramPath = value.split(".");
      const param = segmentation.segmentedParams.find((param) => param.name === segment.paramName);
      const paramLabel = param?.label;
      const paramTextVal = `${paramLabel ?? value} ${(param.type === 'beneficial' || param.type === 'beneficial[]') && paramPath[1] ? `(${paramPath[1]})` : ''}`;
      const styledParamVal = renderStyledSegment({ ...segment, value: paramTextVal, style: segment.style });
      return (
        <span
          key={id}
          className={`param ${isSelected ? "selected-text" : ""} ${isHighlighted ? "highlighted-text" : ""
            } preserve-spaces`}
        >
          {styledParamVal}
        </span>
      );
    case SegmentedTextType.PARAM_VALUE:
      const paramX = segmentation.segmentedParams.find(
        (param) => param.name == segment.paramName
      );
      const styledParamValue = renderStyledSegment({
        ...segment,
        value: text,
        style: segment.style,
      });
      if (paramX && paramX?.type === "file") {
        return (
          <React.Fragment>
            <span
              onClick={() => {
                openFilePreview(inputValues[paramX.name]);
              }}
              style={{ whiteSpace: "pre-line", cursor: "pointer" }}
              className={`param-value ${isSelected ? "selected-text" : ""
                } ${isHighlighted ? "highlighted-text" : ""} preserve-spaces`}
            >
              <button onClick={() => console.log(segment)}>segmennt</button>
              {styledParamValue}
            </span>
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment>
            <span
              style={{ whiteSpace: "pre-line" }}
              className={`param-value ${isSelected ? "selected-text" : ""
                } ${isHighlighted ? "highlighted-text" : ""} preserve-spaces`}
            >
              {styledParamValue}
            </span>
          </React.Fragment>
        );
      }

    case SegmentedTextType.PARAM_TABLE_VALUE:
      const [transposed, tableRows] = JSON.parse(value);
      const [headers, ...rows] = tableRows as string[][];
      return (
        <TableRender
          key={id}
          transposed={transposed}
          headers={headers}
          rows={rows}
          isHighlighted={isHighlighted}
        />
      );

    // Handle other types as needed...

    default:
      return null; // Skip unknown segment types
  }
};

export default RenderSingleSegment;
