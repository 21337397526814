import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from "../../contexts/TranslationProvider";
import './table.scss';
import AdvancedTableContext, { AdvancedTableContextType } from '../../contexts/AdvancedTableContext';
import TableHeader from './TableHeader';

function AdvancedTable({
  originalData,
  columns,
  className,
  onSort,
  hasFilters,
}: {
  originalData: any;
  hasFilters?:boolean;
  className?: string;
  onSort?: (sortParams: { key: string; direction: "asc" | "desc" } | null) => void;
  columns: {
    display: string;
    accessorKey: string;
    width?: number;
    render?: (item: any) => any;
    sortable?: boolean;
    filter?: (setAppliedFilters: React.Dispatch<React.SetStateAction<{ key: string, value: any, type: "basic" | "date" | "custom" }[]>>, row: any) => any;
    isDate?: boolean;
    filtrable?: boolean;
    filterOptions?: { value: any, display: any }[];
  }[];
}) {
  const { t, language, setLanguage } = useTranslation();
  const [data, setData] = useState(originalData);
  const [displayedData, setDisplayedData] = useState(originalData);
  const [filteredData, setFilteredData] = useState(originalData);
  const [sort, setSort] = useState<{ key: string; direction: "asc" | "desc" } | null>(null);
  const [appliedFilters, setAppliedFilters] = useState<{ key: string; value: any; type: "basic" | "date" | "custom" }[]>([]);

  useEffect(() => {
    setData(originalData);
    setDisplayedData(originalData);
    setFilteredData(originalData);
  }, [originalData]);

  useEffect(() => {
    if (sort) {
      const isDate = columns.find(column => column.accessorKey === sort.key)?.isDate;
      const sortedData = [...filteredData].sort((a, b) => {
        if (isDate) {
          if (new Date(a[sort.key]).getTime() > new Date(b[sort.key]).getTime()) {
            return sort.direction === "asc" ? -1 : 1;
          }
          if (new Date(a[sort.key]).getTime() < new Date(b[sort.key]).getTime()) {
            return sort.direction === "asc" ? 1 : -1;
          }
        }
        if (a[sort.key] < b[sort.key]) {
          return sort.direction === "asc" ? -1 : 1;
        }
        if (a[sort.key] > b[sort.key]) {
          return sort.direction === "asc" ? 1 : -1;
        }
      });
      onSort?.(sort);
      setDisplayedData(sortedData);
    } else {
      setDisplayedData(filteredData);
    }
  }, [sort, filteredData]);

  useEffect(() => {
    const filteredData = data.filter(item => {
      return appliedFilters.every(filter => {
        if (filter.type === "basic") {
          return item[filter.key]?.toString()?.toLowerCase().includes(filter.value.toLowerCase());
        }
        if (filter.type === "date") {
          return new Date(item[filter.key]).toDateString() === new Date(filter.value).toDateString();
        }
        if (filter.type === "custom") {
          return item[filter.key] === filter.value;
        }
      });
    });
    setFilteredData(filteredData);
  }, [appliedFilters, data, columns]);

  const AdvancedTableContextValue: AdvancedTableContextType = useMemo(() => {
    return {
      data,
      columns,
      appliedFilters,
      setAppliedFilters,
      sort,
      setSort,
      displayedData,
      setDisplayedData,
    };
  }, [data, columns, appliedFilters, sort, displayedData]);

  return (
    <AdvancedTableContext.Provider value={AdvancedTableContextValue}>
      <div className="advanced-table">
        <table className="resource-table">
          <TableHeader hasFilters={hasFilters} />
          <tbody>
            {displayedData.map((item, index) => (
              <tr style={{ display: "flex" }} onClick={item.onClick} key={index} className="ressource-required-card-list">
                {columns.map((column, index) => (
                  <td style={{ flex: column.width ? column.width : 1 }} key={index} className={`${language === "ar" && "text-right"}`}>
                    {column.render ? column.render(item) : item[column.accessorKey]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </AdvancedTableContext.Provider>
  );
}

export default AdvancedTable;
