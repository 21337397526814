import React, {useState} from 'react'
import { IoArrowUpCircleSharp } from "react-icons/io5";
import { MdOutlineDashboardCustomize } from "react-icons/md";

function PromptInput({
  loading,
  handleSubmit,
  handleQueryChange,
  query,
}) {
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      if (event.shiftKey) {
        return;
      } else {
        event.preventDefault();
        if (!loading && query) {
          handleSubmit();
        }
      }
    }
  };
  return (
    <div>
      <div className='body-interactions-input-container'>
      <div className='input-section'>
        <textarea 
        className='query-input' 
        style={{paddingLeft: "20px"}}
        placeholder='Enter Instructions...' 
        onChange={(e) => handleQueryChange(e.target.value)} 
        onKeyDown={(e) => handleKeyDown(e)}
        value={query} />
      </div>
      <div className='button-section'>
        <button onClick={() => handleSubmit()} className='ask-btn'>
          <IoArrowUpCircleSharp className='icon' style={{
            color: (loading || !query) ? "lightgray" : "gray",
          }}/>
        </button>
      </div>
  </div>
    </div>
  )
}

export default PromptInput