import React, { useState, useEffect } from "react";
import { useTranslation } from "../../../../contexts/TranslationProvider";
import { ClauseEntity, ClauseScoringEntity, ClauseScoringResultEntity, RessourceScoringEntity } from "../../../../domain/entities";
import PromptInput from "./PromptInput";
import Loading from "../../../../components/common/Loading";
import ClauseDisplay from "./ClauseDisplay";
import "./aggregateModal.scss";
import RegenerateClause from "./RegenerateClause";
import useApiClientWithLoading from "../../../../services/api/ApiClient";
import { ClauseScoringClient } from "../../../../services/api/ClauseScoringClient";
import { toast } from "react-toastify";

const AggregateModal = ({
  onClose,
  onConfirmAggregation,
  currentClause,
  targetClauseId
}: {
  onClose: () => void;
  onConfirmAggregation: () => void;
  currentClause: ClauseScoringEntity;
  targetClauseId: ClauseEntity["id"];
}) => {

  const { t, language } = useTranslation();
  const [generatedClause, setGeneratedClause] = useState<ClauseScoringEntity | null>(null);
  const [currentResult, setCurrentResult] = useState<ClauseScoringEntity | null>(null);
  const [isGenerationLoading, setIsGenerationLoading] = useState<boolean>(false);
  const apiClient = useApiClientWithLoading();
  const clauseScoringClient = new ClauseScoringClient(apiClient);

  const handleResolveRegenerate = async (query: string) => {
    const {differences, legalImplications, recommendations} = currentClause.scoring?.comment[0];
    const analysis = `${differences} ${legalImplications} ${recommendations}`;
    try{
      setIsGenerationLoading(true);
      const generatedClause = await clauseScoringClient.generateFromAnalysis(currentClause.id, targetClauseId, analysis, query);
      setGeneratedClause(generatedClause);
      setIsGenerationLoading(false);
    }catch(err){
      console.log(err);
      setIsGenerationLoading(false);
    }
  }

  useEffect(() => {
    if (currentClause) {
      setCurrentResult(currentClause);
    }
  }, [currentClause]);
  const handleAcceptGeneration = async () => {
    try{
      if (generatedClause) {
        const newSegmentation = generatedClause.segmentation;
        const newClause = {...currentClause, segmentation: newSegmentation};
        const result = await clauseScoringClient.update(newClause.id, newClause);
        if(result){
        onConfirmAggregation();
        }
      }
    }catch(err){
      console.log(err)
    }
    
  }


  return (
    <div className="exchange-modal" onClick={() => onClose()}>
    <div className="modal-backdrop"></div>
    <div id="contractz-lab">
      <div className="modal d-flex justify-content-center align-items-center">
        <div className="clause-comparison-modal-content" onClick={(e)=>e.stopPropagation()}>
        <div className="modal-header">
                <h5 className="modal-title">
                  {t("contractCompliancePage.modalTitle")}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={onClose}
                  aria-label="Close"
                  style={{ marginRight: language === "ar" ? "80%" : undefined }}
                ></button>
              </div>
          <div className="body-comparison">
            <div className="clause-comparison-container">
              <div className="clause-exchange-current">
                {currentResult ? (
                  <ClauseDisplay clause={currentResult} />
                ) : null}
              </div>
              <div className="clause-exchange-found">
                <>
                  {generatedClause ? 
                  <ClauseDisplay clause={generatedClause} />
                   : null}
                  <RegenerateClause
                    clause={currentClause}
                    handleResolveRegenerate={handleResolveRegenerate}
                    generationLoading={isGenerationLoading}
                    setGenerationLoading={setIsGenerationLoading}
                    generatedClause={generatedClause}
                  />
                  </>
              </div>
            </div>
          </div>
          <div className="modal-actions">
            <>
              {generatedClause && <button
                className={`confirm-button`}
                onClick={handleAcceptGeneration}
                disabled={!generatedClause}
              >
               {t("contractCompliancePage.buttons.accept")}
              </button>}
              {generatedClause && <button
                className={`confirm-button ${true && "disabled-confirm-button"}`}
                onClick={() => setGeneratedClause(null)}
                style={{ cursor: "pointer" }}
              >
                {t("contractCompliancePage.buttons.cancel")}
              </button>}
            </>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
};

export default AggregateModal;
