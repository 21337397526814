import { useContext, useEffect, useMemo, useRef, useState } from "react";
import React from "react";
import SelectionContext from "../../../contexts/SelectionContext";
import { RessourceScoringEntity } from "../../../domain/entities";
import { useTranslation } from "../../../contexts/TranslationProvider";
import { useLocation } from "react-router-dom";
import EditContractClauseList from "./EditionContractClauseList";
import SummaryModal from "./SummaryModal";
import ReturnToSummaryButton from "./ReturnToSummaryButton";


function ContractCompliance({
  templateData,
  clauses,
  contractName,
  mappedClauses,
  updateRessource,
  handleClauseAdd,
  handleClauseDismiss,
  handleClauseReplace,
}: {
  templateData: RessourceScoringEntity["template"];
  clauses: RessourceScoringEntity["clauses"];
  contractName: RessourceScoringEntity["name"];
  mappedClauses: RessourceScoringEntity["mappedClauses"];
  updateRessource: () => void;
  handleClauseAdd: (clauseId: number) => void;
  handleClauseDismiss: (clauseId: number) => void;
  handleClauseReplace: (clauseId: number, replacedById: number) => void;
}) {
  const {
    selected,
    target,
    containerId,
  } = useContext(SelectionContext);
  const { t } = useTranslation();

  const [isClauseEditing, setIsClauseEditing] = useState(false);
  const [summaryOpen,setSummaryOpen] = useState(true);
  const [contractListParentRef, setContractListParentRef] = useState<any>();
  const clauseListRef = useRef(null);
  const inputListRef = useRef(null);

  const titleRef = useRef(null);
  const location = useLocation();
  useEffect(() => {
    if (selected && clauseListRef.current && inputListRef.current) {
      const clauseId = `${selected.clauseId}`;
      const element = document.getElementById(clauseId);
      if (element && containerId == "source-container") {
        setTimeout(() => {
          const container = clauseListRef.current;
          const containerRect = container.getBoundingClientRect();
          const elementRect = element.getBoundingClientRect();
          const newScrollTop =
            container.scrollTop + (elementRect.top - containerRect.top);
          container.scrollTo({
            top: newScrollTop,
            behavior: "smooth",
          });
          if (target.clauseId) {
            const targetId = `${target.clauseId}`;
            const targetElement = document.getElementById(targetId);
            if (targetElement) {
              const targetContainer = inputListRef.current;
              const targetContainerRect =
                targetContainer.getBoundingClientRect();
              const targetRect = targetElement.getBoundingClientRect();
              const newTargetScrollTop =
                targetContainer.scrollTop +
                (targetRect.top - targetContainerRect.top);
              targetContainer.scrollTo({
                top: newTargetScrollTop,
                behavior: "smooth",
              });
            }
          }
        }, 400);
      }
      if (element && containerId == "target-container") {
        setTimeout(() => {
          const container = inputListRef.current;
          const containerRect = container.getBoundingClientRect();
          const elementRect = element.getBoundingClientRect();
          const newScrollTop =
            container.scrollTop + (elementRect.top - containerRect.top);
          container.scrollTo({
            top: newScrollTop,
            behavior: "smooth",
          });
          if (target.clauseId) {
            const targetId = `${target.clauseId}`;
            const targetElement = document.getElementById(targetId);
            if (targetElement) {
              const targetContainer = clauseListRef.current;
              const targetContainerRect =
                targetContainer.getBoundingClientRect();
              const targetRect = targetElement.getBoundingClientRect();
              const newTargetScrollTop =
                targetContainer.scrollTop +
                (targetRect.top - targetContainerRect.top);
              targetContainer.scrollTo({
                top: newTargetScrollTop,
                behavior: "smooth",
              });
            }
          }
        }, 400);
      }
    }
  }, [selected, clauseListRef, inputListRef, containerId, target]);
  return (
    <>
      <ReturnToSummaryButton onClose={() => setSummaryOpen(true)} />
      <div className="row edit-contract">
        <div
          className="col-6"
          ref={contractListParentRef}
          id="contractListParent"
          style={{ position: "relative" }}
        >
          <div className="contract-header d-flex justify-content-between align-items-center px-2">
            <h1 className="contract-name" ref={titleRef} id="contractName">
              {contractName}
            </h1>
          </div>
          <div
            ref={clauseListRef}
            className="clause-list-container"
            id="clauseListContainer"
          >
            <EditContractClauseList
              mappedClauses={mappedClauses}
              clauseListRef={clauseListRef}
              clauses={clauses ?? []}
              containerId="source-container"
              canComment={false}
              canCompare={true}
              canEdit={true}
              updateRessource={updateRessource}
              handleClauseDismiss={handleClauseDismiss}
              handleClauseReplace={handleClauseReplace}
            />
          </div>
        </div>
        <div className="col-6 contract-clause">
          <div className="d-flex contract-clause-header justify-content-between align-items-center px-2">
            <h1 className="contract-name" ref={titleRef} id="contractName">
              {t("contractCompliancePage.proposedClauses")}
            </h1>
          </div>
          <div
            ref={inputListRef}
            className="clause-compliance-second-container"
          >
            <EditContractClauseList
              mappedClauses={mappedClauses}
              clauseListRef={clauseListRef}
              clauses={templateData.clauses ?? []}
              containerId="target-container"
              canComment={false}
              canCompare={false}
              canEdit={false}
              updateRessource={updateRessource}
              handleClauseAdd={handleClauseAdd}
            />
          </div>
        </div>
      </div>
      {summaryOpen && (
        <SummaryModal
          onClose={() => setSummaryOpen(false)}
          clauses={clauses}
          mappedClauses={mappedClauses}
          templateClauses={templateData.clauses}
        />
      )}
    </>
  );
}
export default ContractCompliance;
