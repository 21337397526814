import "./navbar.scss";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import EditionContractSaveButton from "../Buttons/EditionContractSaveButton";
import AiContractSaveButton from "../Buttons/AiContractSaveButton";
import React, { useContext, useEffect, useState } from "react";
import EditionContractValidateButton from "../Buttons/EditionContractValidateButton";
import { getAuthUser, getRestrictedUser } from "../../../services/api/UserAuth";
import FlagCombobox from "../../common/CustomCombobox/FlagCombobox";
import ContractExportModal from "../Buttons/ContractExportButton";
import AuditButton from "../Buttons/AuditButton";
import { logo } from "../../../assets/icons/logo";
import EditFormSaveButton from "../Buttons/EditFormSaveButton";
import ValidationResponseSaveButton from "../Buttons/ValidationResponseSaveButton";
import EditionContractTemplateValidateButton from "../Buttons/EditionContractTemplateValidateButton";
import { flags } from "../../../assets/icons/flags";
import { useTranslation } from '../../../contexts/TranslationProvider';
import { socket } from "../../../services/SocketServer";
import { toast } from "react-toastify";
import { NotificationEntity, Notification_UserEntity, UserEntity } from "../../../domain/entities";
import useApiClientWithLoading from "../../../services/api/ApiClient";
import NotificationPopup from "../../modals/NotificationPopup";
import UserPopup from "../../modals/UserPopup";
import { Notification_UserClient } from "../../../services/api/Notification_UserClient";
import FormExportButton from "../Buttons/FormExportButton";
import ActivateTemplateButton from "../Buttons/ActivateTemplate";
import PreviewTemplate from "../Buttons/PreviewTemplate";
import { getMessage } from "../../../helpers/Translation";
import DocumentSummaryButton from "../Buttons/DocumentSummaryButton";
import SaveSummaryButton from "../Buttons/SaveSummaryButton";
import ShareButton from "../Buttons/ShareButton";
import EditFormTemplateSaveButton from "../Buttons/EditFormTemplateSaveButton";
import EditFormTemplateCommitButton from "../Buttons/EditFormTemplateCommitButton";
import ExportTemplateButton from "../Buttons/ExportContractTemplate";
import ExportFormTemplateButton from "../Buttons/ExportFormTemplate";
import MigrateToContract from "../Buttons/MigrateToContract";
import { AuthUserContext } from "../../../contexts/AuthUserContext";
import UserRolePopup from "../../modals/UserRolePopup";
import EditionFormValidateButton from "../Buttons/EditionFormValidateButton";
import EditionProjectValidateButton from "../Buttons/EditionProjectValidateButton";
import SaveProjectTemplate from "../Buttons/SaveProjectTemplate";
import CommitProjectTemplate from "../Buttons/CommitProjectTemplate";
import SaveDraftProjectTemplate from "../Buttons/SaveDraftProjectTemplate";
import ExportJsonProjectTemplate from "../Buttons/ExportJsonProjectTemplate";
import ImportJsonProjectTemplate from "../Buttons/ImportJsonProjectTemplate";
import EditionProjectAuditButton from "../Buttons/EditionProjectAuditButton";
import EditContractTemplateSelectDocxExportButton from "../Buttons/EditContractTemplateSelectDocxExportButton";
import SaveDocumentButton from "../Buttons/SaveDocumentButton";
import EditFormTemplateSelectDocxExportButton from "../Buttons/EditFormTemplateSelectDocxExportButton";
import AuditFormButton from "../Buttons/AuditButtonForm";
import RessourceScoringSaveButton from "../Buttons/RessourceScoringSaveButton";
import RessourceScoringExportTemplateButton from "../Buttons/RessourceScoringExportTemplateButton";
import RessourceScoringRecompileButton from "../Buttons/RessourceScoringRecompileButton";
import RessourceScoringReorderButton from "../Buttons/RessourceScoringReorderButton";
const { default: NotificationOn } = require('../../../assets/notificationOn.svg');
const { default: NotificationOff } = require('../../../assets/notificationOff.svg');

function Navbar({ expanded = false, restricted = false }) {
  const location = useLocation();
  const { authUser, activeRole, setActiveRole } = useContext(AuthUserContext);
  const { setLanguage, language } = useTranslation();
  const [searchParams] = useSearchParams();
  const restrictedToken = searchParams.get("restrictedToken")
  const [restrictedUser, setRestrictedUser] = useState(null);

  const [notificationsData, setNotificationsData] = useState<{
    notifications: Notification_UserEntity[];
    notificationsCount: number | undefined;
  }>({
    notifications: [],
    notificationsCount: undefined,
  });
  const [showNotification, setShowNotification] = useState(false)
  const [showUserRole, setShowUserRole] = useState(false)
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const apiClient = useApiClientWithLoading()
  const navigate = useNavigate()
  const notification_UserClient = new Notification_UserClient(apiClient)
  useEffect(() => {
    if (restricted && restrictedToken) {
      const fetchRestrictedUser = async () => {
        try {
          const user = await getRestrictedUser(restrictedToken);
          setRestrictedUser(user);
        } catch (error) {
          console.error("Failed to fetch restricted user:", error);
          navigate("/sign-in")
        }
      };

      fetchRestrictedUser();
    }
  }, [restricted, restrictedToken]);
  useEffect(() => {
    if (!restricted) {
      const fetchRestrictedUser = async () => {
        try {
          const user = await getRestrictedUser(restrictedToken);
          setRestrictedUser(user);
        } catch (error) {
          console.error("Failed to fetch restricted user:", error);
        }
      };

      fetchRestrictedUser();
      const fetchNotifications = async () => {
        try {
          const response = await notification_UserClient.getAll();
          setNotificationsData({
            notifications: response?.rows,
            notificationsCount: response?.count,
          });
        } catch (error) {
          console.error('Error fetching notifications:', error);
        }
      };
      fetchNotifications();
      const onNotification = (notification: NotificationEntity) => {
        console.log('notification', notification);

        toast(getMessage(notification, language)!)
      }
      socket?.on('notification', onNotification)
      return () => {
        socket?.off('notification', onNotification)
      }
    }
  }, [socket])
  const handleNotificationClick = (id: NotificationEntity["id"]) => {
    const result = notificationsData?.notifications.find((item) => item?.Notification?.id == id)
    if (result) {
      result.seenDate = new Date()
      setNotificationsData({ ...notificationsData })
    }
  }
  const storedLanguage = localStorage.getItem("language") || "fr";
  const [flag, setFlag] = React.useState({
    language: storedLanguage,
    flag: flags[storedLanguage],
  });

  useEffect(() => {
    setFlag(languages.find((element) => element.language == language));
  }, [language]);

  const languages = [
    {
      language: "fr",
      flag: flags.fr,
    },
    {
      language: "en",
      flag: flags.en,
    },
    {
      language: "ar",
      flag: flags.ar,
    },
  ];
  useEffect(() => {
    console.log("location.pathname", location.pathname);


    return () => {

    }
  }, [location.pathname])


  return (
    <div className="navbar-custom d-flex justify-content-between align-items-center px-2 position-sticky top-0">
      <Link to={"/"}>
        {expanded || restricted ? logo.full : logo.default}
      </Link>
      <div className="flex-grow-1 d-flex justify-content-center align-items-center navbar-buttons">
        {
          location.pathname.includes("/contract-compliance") ? (
            <>
              <RessourceScoringSaveButton />
              <div className="vertical-line me-5"></div>
              <RessourceScoringExportTemplateButton />
              <div className="vertical-line me-5"></div>
              <RessourceScoringRecompileButton />
              <div className="vertical-line me-5"></div>
              <RessourceScoringReorderButton />
            </>
          ) : null
        }
        {location.pathname.includes("/edition-contrat") && !location.pathname.includes("/edition-contrat-template") ? (
          <React.Fragment>
            {!restricted && <EditionContractValidateButton />}
            <div className="vertical-line me-5"></div>
            {!restrictedToken && location.pathname.includes("/edition-contrat/") && (
              <React.Fragment>
                <AuditButton />
                <div className="vertical-line me-5"></div>
              </React.Fragment>
            )}

            <EditionContractSaveButton />
            <div className="vertical-line me-5"></div>

            {!restrictedToken && location.pathname.includes("/edition-contrat/") && (
              <React.Fragment>
                <ContractExportModal />
                <div className="vertical-line me-5"></div>
                <ShareButton />
                <div className="vertical-line me-5"></div>
              </React.Fragment>
            )}
          </React.Fragment>
        ) : location.pathname.includes("/ai-edition-contrat") ? (
          <React.Fragment>
            <AiContractSaveButton />
            <div className="vertical-line me-5"></div>
          </React.Fragment>
        ) : location.pathname.includes("/edit-form") ? (
          <React.Fragment>
            {location.pathname.includes("/edit-form/") &&
              <React.Fragment>
                <div className="vertical-line me-5"></div>
                <EditionFormValidateButton />
              </React.Fragment>
            }
            <div className="vertical-line me-5"></div>
            {!restrictedToken && location.pathname.includes("/edit-form") && (
              <React.Fragment>
                <AuditFormButton />
                <div className="vertical-line me-5"></div>
              </React.Fragment>
            )}
            <EditFormSaveButton />
            <div className="vertical-line me-5"></div>
            {location.pathname.includes("/edit-form/") &&
              <React.Fragment>
                <FormExportButton />
                <div className="vertical-line me-5"></div>
                <MigrateToContract />
                <div className="vertical-line me-5"></div>
              </React.Fragment>
            }

          </React.Fragment>
        ) : location.pathname.includes("/ai-document-response") ? (
          <React.Fragment>
            <ValidationResponseSaveButton />
            <div className="vertical-line me-5"></div>
          </React.Fragment>
        ) : location.pathname.includes("/edition-contrat-template") ? (
          <React.Fragment>
            <EditionContractTemplateValidateButton />
            <div className="vertical-line me-5"></div>
            <ExportTemplateButton />
            <div className="vertical-line me-5"></div>
            <ActivateTemplateButton />
            <div className="vertical-line me-5"></div>
            <PreviewTemplate />
            <div className="vertical-line me-5"></div>
            <EditContractTemplateSelectDocxExportButton />
          </React.Fragment>
        ) : location.pathname.includes("/document/") ? (
          <React.Fragment>
            <DocumentSummaryButton />
            <div className="vertical-line me-5"></div>
            <SaveDocumentButton />
            <div className="vertical-line me-5"></div>
          </React.Fragment>
        ) : location.pathname.includes("/document-summary/") ? (
          <React.Fragment>
            <SaveSummaryButton />
            <div className="vertical-line me-5"></div>
          </React.Fragment>) : location.pathname.includes("/edition-form-template/") ? (
            <React.Fragment>
              <EditFormTemplateSaveButton />
              <div className="vertical-line me-5"></div>
              <EditFormTemplateCommitButton />
              <div className="vertical-line me-5"></div>
              <ExportFormTemplateButton />
              <div className="vertical-line me-5"></div>
              <EditFormTemplateSelectDocxExportButton />
            </React.Fragment>
          ) : location.pathname.includes("/projet/") ? (
            <React.Fragment>
              <div className="vertical-line me-5"></div>
              <EditionProjectValidateButton />
              <div className="vertical-line me-5"></div>
              <EditionProjectAuditButton />
            </React.Fragment>
          ) : location.pathname.includes("/admin/create-project-template") ? (
            <React.Fragment>
              <SaveProjectTemplate />
              <div className="vertical-line me-5"></div>
              <ImportJsonProjectTemplate />
            </React.Fragment>) : location.pathname.includes("/edition-project-template/") ? (
              <React.Fragment>
                <SaveDraftProjectTemplate />
                <div className="vertical-line me-5"></div>
                <ExportJsonProjectTemplate />
                <div className="vertical-line me-5"></div>
                <CommitProjectTemplate />
                <div className="vertical-line me-5"></div>
              </React.Fragment>) : null}
      </div>
      <div>
        <div>
          <FlagCombobox
            optionValue={(option) => option?.language || null}
            optionDisplay={(option) => option?.flag || ""}
          />
        </div>
      </div>
      {!restricted && (<div className="notification-container">
        <img src={notificationsData?.notificationsCount == undefined || 0 ? NotificationOff : NotificationOn} width={notificationsData?.notificationsCount !== undefined || 0 ? 31 : 22} className="notification-icon" onClick={() => setShowNotification(true)} />
        {showNotification && (
          <NotificationPopup onNotificationClick={handleNotificationClick} notifications={notificationsData?.notifications} onClose={() => setShowNotification(false)} />
        )}
      </div>)}
      {!restricted && authUser.roles?.length > 1 && (<div className="notification-container">
        <div style={{ cursor: "pointer" }} onClick={() => setShowUserRole(true)}>
          {activeRole}
        </div>
        {showUserRole && (
          <UserRolePopup notifications={notificationsData?.notifications} onClose={() => setShowUserRole(false)} />
        )}
      </div>)}
      {!restricted && <div className="vertical-line me-5"></div>}
      <div className="user-display">
        {restricted === false ? (
          <><div className="avatar-user" onClick={() => setIsPopupOpen(true)}>{authUser?.email ? authUser.email.charAt(0).toUpperCase() : ''}</div>
            <div className="username-role">
              <h6>{authUser?.firstName}</h6>
              <h6>{authUser?.lastName}</h6>
            </div>
            {isPopupOpen && <UserPopup onClose={() => setIsPopupOpen(false)} />}
          </>) : (<>
            {restrictedUser?.email}
          </>)}
      </div>

      <div className="vertical-line me-5"></div>
    </div>
  );
}

export default Navbar;
