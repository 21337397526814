import { AxiosInstance } from "axios";
import { ClauseEntity, ClauseScoringEntity } from "../../domain/entities";
import { ModelClient } from "../ModelClient";

export class ClauseScoringClient extends ModelClient<ClauseScoringEntity> {

  constructor(apiClient: AxiosInstance) {
    super(apiClient, 'ClauseScoring')
  }
    async generateFromAnalysis(clauseId: ClauseScoringEntity['id'], targetClauseId: ClauseEntity['id'], analysis: string, instructions: string){
      const url = new URL(`${this.modelUrl}/generateFromAnalysis`)
      url.searchParams.append("id", String(clauseId))
      const response = await this.apiClient.post<ClauseScoringEntity>(url.href, { targetClauseId, analysis, instructions });
      return response.data;
    }

}