import "../commonmodal.scss";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import useApiClientWithLoading from "../../../services/api/ApiClient";
import Loading from "../../common/Loading";
import InputValuesContext from "../../../contexts/InputValuesContext";
import CustomCombobox from "../../common/CustomCombobox";
import { useTranslation } from '../../../contexts/TranslationProvider';
import { ContractTemplateEntity, FormEntity, ProjectEntity, ProjectTemplateRequirementEntity, RessourceScoringEntity, TypeLevel1Entity, TypeLevel2Entity, TypeLevel3Entity } from "../../../domain/entities";
import { ContractClient } from "../../../services/api/ContractClient";
import { toast } from "react-toastify";
import { RessourceScoringClient } from "../../../services/api/RessourceScoring";
interface ExportComplianceModalType {
  onClose: () => void;
  ressource: RessourceScoringEntity;
  template: ContractTemplateEntity;
}

function ExportComplianceModal({ onClose, ressource, template }: ExportComplianceModalType) {
  const { t, language } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [contractExistsError, setContractExistsError] = useState(false);
  const [checkContractExistsTimeout, setCheckContractExistsTimeout] = useState<NodeJS.Timeout>(null);
  const modalContentRef = useRef(null);
  const apiClient = useApiClientWithLoading();
  const contractClient = new ContractClient(apiClient)
  const ressourceScoringClient = new RessourceScoringClient(apiClient);
  const [openCombobox, setOpenCombobox] = useState<string | null>(null);


  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
    watch,
    setValue,
  } = useForm();
  const contractName = watch("contractName");

  const onSubmit = async () => {
    setLoading(true);
    try {
      if (!template) {
        toast.error(t("contractCompliancePage.toasts.exportError"))
        return;
      }
      const newContract = await ressourceScoringClient.exportToContract(contractName, ressource.id, template.level1Id, template.level2Id, template.level3Id);
      if (newContract) {
        navigate(`/edition-contrat/${newContract.id}`);
        toast.success(t("contractCompliancePage.toasts.exportSuccess"))
      }else{
        toast.error(t("contractCompliancePage.toasts.exportError"))
      }
    } catch (error) {
      toast.error(t("contractCompliancePage.toasts.exportError"))
      console.error(error);
    }
  };


  useEffect(() => {
    if (checkContractExistsTimeout) {
      clearTimeout(checkContractExistsTimeout)
    }
    setCheckContractExistsTimeout(setTimeout(async () => {
      try {
        const count = await contractClient.countAllByFilter({ "name": contractName });
        setContractExistsError(count >= 1);
      } catch (error) {
        console.error(error);
      } finally {
        setCheckContractExistsTimeout(null)
      }
    }, 500))
  }, [contractName]);

  return (

    <>
    <div className="modal-backdrop fade show"></div>
      <div id="contractz-lab">
        <div className="modal d-flex justify-content-center align-items-center">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">{t("contractCompliancePage.exportComplianceModal.title")}</h5>
                <button type="button" className="btn-close" onClick={onClose} aria-label="Close"
                  style={{ marginRight: language == "ar" && "88%" }}
                ></button>
              </div>
              <div className="modal-body modal-body-pilotage" ref={modalContentRef}>
                <p>{t("contractCompliancePage.exportComplianceModal.description")}</p>
                {loading ? (
                  <Loading height="35vh" />
                ) : (
                  <form action="" className="form-group" onSubmit={handleSubmit(onSubmit)}
                    style={{ maxHeight: '550px', marginBottom: "20%" }}>
                    <p className="fw-bolder">{t("pages.pilot.popups.contract.required")}</p>
                    <div className="form-input-content">
                      <div className="w-50">
                        <label htmlFor="contractName" className="case-form-labelName">{t("pages.pilot.popups.contract.inputs.contractName.name")}</label>
                        <div className="form-floating mb-3 case-form-labelInput">
                          <input
                            type="text"
                            className="form-control custom-color-input input-popup"
                            id="contractName"
                            placeholder={t("pages.pilot.popups.contract.inputs.contractName.placeholder")}
                            onChange={(e) => { setContractExistsError(false) }}
                            {...register("contractName", { required: true })}
                          />
                        </div>
                        {errors.contractName && <p className="text-danger py-0 my-0 py-0 my-0">{t("pages.pilot.popups.contract.inputs.contractName.error1")}</p>}
                        {contractExistsError && <p className="text-danger py-0 my-0 py-0 my-0">{t("pages.pilot.popups.contract.inputs.contractName.error2")}</p>}
                      </div>
                    </div>
                    <div >
                      <button
                        disabled={contractExistsError || !!checkContractExistsTimeout}
                        type="submit" className={`btn btn-primary  btn-display`} style={{ left: "4.5%" }}>
                        {t("contractCompliancePage.exportComplianceModal.button")}
                      </button>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>

  );
}

export default ExportComplianceModal;
