import React from "react";
import { useTranslation } from "../../../../contexts/TranslationProvider";
import { ClauseEntity, ClauseScoringEntity } from "../../../../domain/entities";
import RenderSegmentedText from "../../../../components/RenderSegmentedText";

interface ClauseDisplayProps {
  clause: ClauseEntity | ClauseScoringEntity;
  height?: string;
}
function ClauseDisplay({ clause, height }: ClauseDisplayProps) {
  const { t, language } = useTranslation();

  return (
    <>
      <div
        style={{ height: height ?? "100%" }}
        className="clause-comparison-current"
      >
        <div className="d-flex header-clause-current" style={{maxHeight: "50px"}}>
          <div className="clause-comparison-header mb-4" >
            <span className="clause-comparison-actual-title half-border-bottom" style={{fontSize: "15px"}}>
              {clause.name}
            </span>
          </div>
        </div>
        <div className="clause-comparison-body">
          <RenderSegmentedText
            segmentation={clause.segmentation}
            segmentsOverrides={{}}
            isEditing={false}
            isSelected={false}
            fileNames={[]}
            clauseId={clause.id}
            beneficialsMap={{}}
            onSegmentChange={() => {}}
            inputValues={{}}
            />
        </div>
      </div>
    </>
  );
}

export default ClauseDisplay;
