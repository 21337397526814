import React from 'react'
import { useTranslation } from '../../../contexts/TranslationProvider';
import { formatDate } from '../../../helpers/helper';
function DocumentBody({ data, updatedSince, createdSince }) {
  const { t, language } = useTranslation();
  const translationPath = "pages.ressources."

  const getUseCaseName = (useCases) => {
    if(!useCases)
      return
    let useCaseNames = []
    useCases?.forEach(useCase => {
      if(useCaseNames.find(name => name === useCase.type)){
        return
      }
      useCaseNames.push(useCase.type)
    })
    return useCaseNames.map(name => {
      if(name === "Question"){
        return t(translationPath + "cardLabels.question")
      }
      if(name === "Risk"){
        return t(translationPath + "cardLabels.risk")
      }
    }).map(name => name).join("/")
  }

  return (
    <div className='body'>
      <div className='body-section'>
        <span className='label'>
        {t(translationPath + "cardLabels.type")}
        </span>
        <span className='value'>
          {data.isScanned ? 'Scanned Document' : 'Text Document'}
        </span>
      </div>
      <div className='body-section'>
        <span className='label'>
        {t(translationPath + "cardLabels.source")}
        </span>
        <span className='value'>
        { data.useCaseResponses ?
          (data.useCaseResponses &&
          data.useCaseResponses.length == 0) ? t(translationPath + "cardLabels.imported")
          : getUseCaseName(data.useCaseResponses)
          : t(translationPath + "cardLabels.imported")
        }
        </span>
      </div>
      <div className='body-section'>
      </div>
      <div className='timestamp'>
        <div>
          <span className='value'>
          {t(translationPath + "cardLabels.updatedAt")}
          </span>
          <span className='value'>
          {formatDate(data.updatedAt as any as string)}
          </span>
        </div>
        <div>
          <span className='value'>
          {t(translationPath + "cardLabels.createdAt")}
          </span>
          <span className='value'>
          {formatDate(data.createdAt as any as string)}
          </span>
        </div>
      </div>
    </div>
  )
}

export default DocumentBody