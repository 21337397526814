import React from 'react'
import EditContractTemplateContext from "../../../../contexts/EditFormTemplateContext";
import { useTranslation } from "../../../../contexts/TranslationProvider";
import { FormType } from '../../../../domain/types/FormParams';
const { default: Delete } = require("../../../../assets/delete.svg");

function TableRender({ children, param, isEditing }: {
  children: React.JSX.Element;
  param: FormType.TableParam;
  isEditing: boolean;
}) {
  const { onParamChanged, onParamLabelChange, } = React.useContext(EditContractTemplateContext);
  const { t, language } = useTranslation();
  const translationPath = t("pages.editionFormTemplate.edition");
  const translationTypes = "pages.contractTemplateParam.params"

  return (
    <div className={`input-param`}>
      <div className="input-type">{t(`${translationTypes}.${param.type}`)}</div>
      {children}
      {!isEditing ? (
        <>
          <div className="d-flex px-3">
            <input
              type="checkbox"
              checked={param.transposed}
              onChange={(e) =>
                onParamChanged(param.name, {
                  ...param,
                  transposed: e.target.checked,
                })
              }
            />
            <label
              className="px-2"
              style={{ color: "#1f2b3a", fontWeight: "400" }}
            >
              {" "}
              {t(translationPath.table.transposed)}{" "}
            </label>
          </div>
          <div className="d-flex container-table-element mb-1 mt-1">
            <span className="header-table-edit">
              {" "}
              {t(translationPath.table.colonnes)}{" "}
            </span>
            <span className="header-table-edit">
              {t(translationPath.table.typesTitle)}
            </span>
          </div>
          {param?.args &&
            param?.args?.map((arg, index) => (
              <div key={index} className="d-flex  container-table-element">
                <p> {arg.header}</p>
                <p>{arg.type}</p>
              </div>
            ))}
        </>
      ) : (
        <>
        </>
      )}

      {param.args &&
        param?.args?.map((arg, index) => {
          return (
            isEditing && (
              <div
                className="d-flex justify-content-between  align-items-center px-2 mb-1 mt-2"
                style={{ width: "90%" }}
              >
                <input
                  className="label-input-edit"
                  style={{ height: "30px", width: "50%" }}
                  value={arg.header}
                  onChange={(e) => {
                    console.log(e.target.value);
                    console.log({
                      ...param,
                      args: param.args.map((arg, i) =>
                        i === index
                          ? { ...arg, header: e.target.value }
                          : arg
                      ),
                    });
                    onParamChanged(param.name, {
                      ...param,
                      args: param.args.map((arg, i) =>
                        i === index
                          ? { ...arg, header: e.target.value }
                          : arg
                      ),
                    });
                  }}
                />
                <select
                  value={arg.type}
                  style={{ height: "30px", width: "30%" }}
                  className="label-input-edit"
                  onChange={(e) =>
                    onParamChanged(param.name, {
                      ...param,
                      args: param.args
                        ? param.args.map((arg, i) =>
                          i === index
                            ? {
                              ...arg,
                              type: e.target.value as
                                | "string"
                                | "number"
                                | "date",
                            }
                            : arg
                        )
                        : [],
                    })
                  }
                >
                  <option value="string">
                    {" "}
                    {t(translationPath.table.types.string)}{" "}
                  </option>
                  <option value="number">
                    {" "}
                    {t(translationPath.table.types.number)}
                  </option>
                  <option value="date">
                    {" "}
                    {t(translationPath.table.types.date)}
                  </option>
                </select>

                <img
                  src={Delete}
                  className="delete-single-option"
                  onClick={() => {
                    param.args = param.args.splice(index, 1)
                    onParamChanged(param.name, param)
                  }
                  }
                />
              </div>
            )
          );
        })}
      {isEditing && (
        <p
          className="add-single-option"
          style={{ marginLeft: "8px" }}
          onClick={() => {
            param.args.push({ header: "", type: "string" })
            onParamChanged(param.name, param)
          }}
        >
          +
        </p>
      )}
    </div>
  )
}

export default TableRender