import { getAuthUser } from "../../services/api/UserAuth";
import AdminFunction from "../../components/AdminFunction";
import CreateContractModal from "../../components/modals/CreateContractModal";
import CreateContractAIModal from "../../components/modals/CreateContractAIModal";
import CreateFormModal from "../../components/modals/CreateFormModal";
import { useTranslation } from '../../contexts/TranslationProvider';
import QuestionUseCase from "./components/QuestionUseCase";
import FillDocumentSummaryModal from "../../components/modals/FillDocumentSummaryModal";
import CreateFormFromDocModal from "../../components/modals/CreateFormFromDocModal";
import chat from "../../assets/new-design-icons/Chat.svg"
import { useNavigate } from "react-router-dom";
import "./pilot.scss"
import CreateContractComparison from "../../components/modals/CreateContractComparison";

function PilotPage() {
  const navigate = useNavigate();
  const { t, language } = useTranslation();
  const { user } = getAuthUser();
  const chatIcon = chat.toString();
  return (
    <div className={`page ${language == "ar" && "arabic-page"}`} style={{ position: "relative" }}>
      <div className="px-5">
        <section id="greetings">
          <div className="mt-2 mb-2">
            <span style={{ marginTop: '10px' }} className="current-pathname">{t("pages").pilot.title}</span>
          </div>
          <h2>
            {t("pages").pilot.greeting}{" "}
            <span>
              {" "}
              <strong>{user?.firstName}, </strong>
            </span>
          </h2>
          <p>{t("pages.pilot.explanation")}</p>
        </section>
        <section id="main-function" className="pt-3">
          <div className="row mt-5">
            <div className="col-6">
              <AdminFunction title={t("pages.pilot.cards.contract.title")} description={t("pages.pilot.cards.contract.description")} buttonTitle={t("pages.pilot.cards.contract.button")}
                render={(onClose) => <CreateContractModal onClose={onClose} />} />
            </div>
            <div className="col-6">
              <AdminFunction title={t("pages.pilot.cards.form.title")} description={t("pages.pilot.cards.form.description")} buttonTitle={t("pages.pilot.cards.form.button")}
                render={(onClose) => <CreateFormModal onClose={onClose} />} />
            </div>

            <div className="col-6 mt-3 py-5">
              <AdminFunction title={t("pages.listener.cards.contract.title")} description={t("pages.listener.cards.contract.description")} buttonTitle={t("pages.listener.cards.contract.button")}
                render={(onClose) => <FillDocumentSummaryModal onClose={onClose} />} />
            </div>
            <div className="col-6 mt-3 py-5">
              <AdminFunction title={t("pages.listener.cards.form.title")} description={t("pages.listener.cards.form.description")} buttonTitle={t("pages.listener.cards.form.button")}
                render={(onClose) => <CreateFormFromDocModal onClose={onClose} />} />
            </div>
            <div className="col-6">
              <AdminFunction title={t("pages.pilot.cards.comparison.title")} description={t("pages.pilot.cards.comparison.description")} buttonTitle={t("pages.pilot.cards.comparison.button")}
                render={(onClose) => <CreateContractComparison onClose={onClose} />} />
            </div>
            <div className="col-xxl-3 col-0"></div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default PilotPage;
