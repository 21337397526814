import React from 'react'
import { EditFormTemplateContext } from "../../../../contexts/EditFormTemplateContext";
import { useTranslation } from "../../../../contexts/TranslationProvider";
import { FormType } from '../../../../domain/types/FormParams';
import { FormTemplatePageEntity } from '../../../../domain/entities';

function BooleanRender({ children, param, page }: {
  children: React.JSX.Element;
  param: FormType.BooleanParam;
  page: FormTemplatePageEntity;
}) {
  const { onValueChange, paramValues } = React.useContext(EditFormTemplateContext);
  const { t, language } = useTranslation();
  const translationPath = t("pages.editionFormTemplate.edition");
  const translationTypes = "pages.contractTemplateParam.params"

  return (
    <div className={`input-param`}>
      <div className="input-type">{t(`${translationTypes}.${param.type}`)}</div>
      {children}
      <div className="radio-group boolean-group">
        <label className="form-check-label">
          <input
            type="radio"
            value={"true"}
            checked={paramValues[param.name] !== undefined && paramValues[param.name] === (true as any)}
            className="form-check-input boolean-check"
            onChange={() => {
              onValueChange(page.id, param, true)
            }}
            name={param.name}
          />
          {t(translationPath.boolean.oui)}
        </label>
        <label className="form-check-label">
          <input
            type="radio"
            value="false"
            checked={paramValues[param.name] !== undefined && paramValues[param.name] === (false as any)}
            className="form-check-input boolean-check"
            onChange={() => {
              onValueChange(page.id, param, false)
            }}
            name={param.name}
          />
          {t(translationPath.boolean.non)}
        </label>
      </div>
    </div>

  )
}

export default BooleanRender