import React, { useEffect, useState, useContext, useMemo } from "react";
import { useTranslation } from "../../../../contexts/TranslationProvider";
import { EditFormTemplateContext } from "../../../../contexts/EditFormTemplateContext";
import "./formTemplateParameterInput.scss";
import BeneficialRender from "./BeneficialRender";
import TableRender from "./TableRender";
import BooleanRender from "./BooleanRender";
import FileRender from "./FileRender";
import ListRender from "./ListRender";
import EnumRender from "./EnumRender";
import DefaultRender from "./DefaultRender";
import NumberRender from "./NumberRender";
import PropertyRender from "./PropertyRender";
import { FormParam, FormType } from "../../../../domain/types/FormParams";
import BeneficialListRender from "./BeneficialListRender";
import DateRender from "./DateRender";
import { FormTemplatePageEntity } from "../../../../domain/entities";
import { getAllParams } from "../../../../domain/FormTemplate";

function FormTemplateParameterInput({
  page,
  param,
  paramIndex,
  isEditing,
}: {
  page: FormTemplatePageEntity;
  param: FormParam;
  paramIndex: number;
  isEditing: boolean;
}) {
  const { formTemplate, onParamChanged, onParamNameChanged, onParamLabelChange } = useContext(EditFormTemplateContext);
  const [paramName, setParamName] = useState<FormParam['name']>(param.name);
  const [errors, setErrors] = useState([])
  const [warnings, setWarnings] = useState([])
  const { t, language } = useTranslation();
  const translationPathErrors = t("pages.contractTemplateParam.addParamForm");

  useEffect(() => {
    setParamName(param.name)
  }, [param.name]);

  const allParams = useMemo(() => getAllParams(formTemplate), [formTemplate])

  useEffect(() => {
    const newErrors = [];
    const newWarnings = []
    if (!/^[a-z_][a-z0-9_]*$/i.test(paramName)) {
      newErrors.push({ where: "name", why: translationPathErrors.errors.nameWrong });
    }

    const existingParam = allParams.find(p => p.name === paramName && p.id !== param.id);
    if (existingParam) {
      if (existingParam.type !== param.type) {
        newErrors.push({ where: "name", why: translationPathErrors.errors.nameExists });
      }
      else {
        newWarnings.push({ where: "name", why: translationPathErrors.errors.nameExists });
      }
    }

    setErrors(newErrors);
    setWarnings(newWarnings);

    if (newErrors.length === 0) {
      onParamNameChanged(param.name, paramName);
    }
    return () => {
    }
  }, [paramName, param.type])

  const handleInputNameChange = (e) => {
    const value = e.target.value;
    setParamName(value);
  }

  const handleInputformulaChange = (e) => {
    const formula = e.target.value;
    onParamChanged(param.name, { ...param, formula });
  }

  const handleInputformatChange = (format: string) => {
    onParamChanged(param.name, { ...param, format });
  }
  const handleInputConstraintChange = (constraints: FormType.Constraint[]): (void) => {
    onParamChanged(param.name, { ...param, constraints: constraints });
  }

  const onChangeDescription = (e) => {
    const value = e.target.value;
    onParamChanged(param.name, { ...param, description: value });
  }

  const translationPath = t("pages.editionFormTemplate.edition");
  const basicInfoElements = (<React.Fragment>
    <div>
      {!isEditing ? (
        <label
          style={{ justifyContent: language === "ar" ? "right" : "left" }}
          className="custom-form-label"
        >
          {translationPath.parameterName} : {param.name}
        </label>
      ) : (
        <>
          <textarea
            className="custom-form-textarea"
            value={paramName}
            onChange={handleInputNameChange}
            placeholder={translationPath.parameterName}
          />
          {errors.map((err, idx) => {
            if (err.where === "name") {
              return (
                <div key={idx} className="error-message-dialog">
                  {err.why}
                </div>
              );
            }
          })}
          {warnings.map((err, idx) => {
            if (err.where === "name") {
              return (
                <div key={idx} className="warning-message-dialog">
                  {err.why}
                </div>
              );
            }
          })}
        </>
      )}
    </div>
    <div>
      {isEditing ? (
        <textarea
          className="custom-form-textarea"
          value={param.label}
          onChange={e => onParamLabelChange(page.id, param.id, e.target.value)}
          placeholder={translationPath.parameterLabel}
        />
      ) : (
        <div
          style={{ justifyContent: language === "ar" ? "right" : "left" }}
          className="custom-form-label"
        >
          {" "}
          {translationPath.parameterLabel} : {param.label}
        </div>
      )}
    </div>
    <div>
      {!isEditing ? (
        <label style={{ justifyContent: language === "ar" ? "right" : "left" }} className="custom-form-label">{translationPath.parameterDescription} : {param.description ?? ""}</label>
      ) : (
        <textarea
          className="custom-form-textarea"
          value={param.description ?? ""}
          onChange={onChangeDescription}
          placeholder={translationPath.parameterDescription}
        />
      )}
    </div>
  </React.Fragment>)
  switch (param.type) {
    case "beneficial":
      return (
        <BeneficialRender
          param={param}
        >
          {basicInfoElements}
        </BeneficialRender>
      );
    case "beneficial[]":
      return (
        <BeneficialListRender
          param={param}
        >
          {basicInfoElements}
        </BeneficialListRender>
      );
    case "table":
      return (
        <TableRender
          param={param}
          isEditing={isEditing}
        >
          {basicInfoElements}
        </TableRender>
      );
    case "boolean":
      return (
        <BooleanRender
          param={param}
          page={page}
        >
          {basicInfoElements}
        </BooleanRender>
      );
    case "file":
    case "csv":
      return (
        <FileRender
          param={param}
        >
          {basicInfoElements}
        </FileRender>
      );
    case "list":
      return (
        <ListRender
          param={param}
          isEditing={isEditing}
        >
          {basicInfoElements}
        </ListRender>
      );
    case "enum":
      return (
        <EnumRender
          param={param}
          page={page}
          isEditing={isEditing}
        >
          {basicInfoElements}
        </EnumRender>
      );
    case "number":
      return (
        <NumberRender
          onChangeFormula={handleInputformulaChange}
          onChangeConstraint={handleInputConstraintChange}
          onChangeFormat={handleInputformatChange}
          param={param}
          isEditing={isEditing}
        >
          {basicInfoElements}
        </NumberRender>
      );
    case "date":
      return (
        <DateRender
          page={page}
          onChangeFormat={handleInputformatChange}
          param={param}
          onChangeFormula={handleInputformulaChange}
          isEditing={isEditing}
          onChangeConstraint={handleInputConstraintChange}
        >
          {basicInfoElements}
        </DateRender>
      );

    case 'property':
      return (
        <PropertyRender
          param={param}
          isEditing={isEditing}
        >
          {basicInfoElements}
        </PropertyRender>
      );
    default:
      return (
        <DefaultRender
          param={param}
          onChangeFormula={handleInputformulaChange}
          isEditing={isEditing}
          onChangeConstraint={handleInputConstraintChange}
        >
          {basicInfoElements}
        </DefaultRender>
      );
  }
}

export default FormTemplateParameterInput;