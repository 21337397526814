import { useContext, useRef, useState, useEffect } from "react";
import InputValuesContext from "../../../contexts/InputValuesContext";
import RenderSegmentedText from "../../../components/RenderSegmentedText";
import { useTranslation } from "../../../contexts/TranslationProvider";
import { ClauseScoringResultEntity } from "../../../domain/entities";
import useApiClientWithLoading from "../../../services/api/ApiClient";
import { RessourceScoringClient } from "../../../services/api/RessourceScoring";
import FlagCombobox from "../../../components/common/newDesign/CustomCombobox/FlagCombobox";
import FlagSelector from "./FlagSelector";

function AIResponseOnClause({
  response,
  isAnalysisLoading
}: {
  response: ClauseScoringResultEntity;
  isAnalysisLoading: boolean;
}) {
  const { t, language } = useTranslation();
  const [comment, setComment] = useState<ClauseScoringResultEntity["comment"] | null>(response.comment);
  const [chosenLanguage, setChosenLanguage] = useState<"en" | "fr" | "ar">(language);
  const [chosenComment, setChosenComment] = useState<ClauseScoringResultEntity["comment"][0] | null>(comment && Array.isArray(comment) ? comment.find((c) => c.lang === chosenLanguage) : null);
  useEffect(() => {
    setComment(response.comment);
  }, [response.comment]);
  useEffect(() => {
    setChosenComment(comment && Array.isArray(comment) ? comment.find((c) => c.lang === chosenLanguage) : null);
  }, [comment, chosenLanguage]);

  return (
    <>
      {isAnalysisLoading ? <div className="skeleton-container mt-4 mb-2">
        <div className="skeleton-header"></div>
        <div className="skeleton-line"></div>
        <div className="skeleton-body"></div>
      </div> : <>
        <div className="compliance-description-container mt-4 mb-2">
          <div className="compliance-description-header">
            <strong>{t("contractCompliancePage.aiResponse.analysis")}</strong>
            <FlagSelector
              optionValue={(option) => option?.language || null}
              optionDisplay={(option) => option?.flag || ""}
              language={chosenLanguage}
              handleLanguageChange={(value) => setChosenLanguage(value)}
            />
          </div>
          <div style={{ direction: chosenLanguage === "ar" ? "rtl" : "ltr" }}>
          {
            chosenComment && (<>
              {chosenComment.differences}
              <br />
              <br />
              {chosenComment.legalImplications}
              <br />
              <br />
              {chosenComment.recommendations}
            </>)
          }
            </div>
          {/* <div className="mt-2">
      <strong>
        {t("contractCompliancePage.aiResponse.proposedClause")}
      </strong>
    </div> */}
          {/* <div
      key={response.proposedClause.id}
      className={`edit-contract-compliance-clause selectable px-4 py-2 mx-1 my-2 clause-${response.proposedClause.id}`}
      id={`${response.proposedClause.id}`}
    >
      <div className="edit-contract-clause-header">
        <div>
          <b className="mx-2">{response.proposedClause.name}</b>
        </div>
      </div>

      <div>
        {/* {response.proposedClause.segmentation?.segmentedText?.length >
          0 && (
          <div>
            <RenderSegmentedText
              clauseId={response.proposedClause.id}
              segmentation={response.proposedClause.segmentation}
              fileNames={fileNames}
              beneficialsMap={beneficialsMap}
              segmentsOverrides={segmentsOverrides}
              onSegmentChange={(id, text) =>
                setSegmentsOverrides({
                  ...segmentsOverrides,
                  [id]: text,
                })
              }
              isSelected={true}
              isEditing={false}
              formattedRawRef={formattedRawRef}
              inputValues={inputValues}
            />
          </div>
        )} 

        <button className="mt-4 mb-2 compliance-button">
          {t("contractCompliancePage.buttons.change")}
        </button>
      </div>
    </div> */}
        </div>
      </>}
    </>
  );
}

export default AIResponseOnClause;
