// src/components/common/CustomCombobox.js
import React, { useState, useEffect } from "react";
import "./flagselector.scss";
import { flags } from "../../../assets/icons/flags";
interface CustomComboboxProps {
  optionDisplay?: any,
  optionValue?: any,
  onClick?: any,
  language?: string,
  handleLanguageChange?: any
}
const FlagSelector = ({ optionDisplay, optionValue, onClick, language, handleLanguageChange }: CustomComboboxProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOptionClick = (option) => {
    const value = optionValue ? optionValue(option) : option;
    setIsOpen(false);
    handleFlagChange(value)
    handleLanguageChange(value)
  };

  const handleDropdownClick = (event) => {
    event.stopPropagation(); // Add this line to stop event propagation
    setIsOpen(!isOpen);
  };
  const languages = [
    {
      language: "fr",
      flag: flags.fr,
    },
    {
      language: "en",
      flag: flags.en,
    },
    {
      language: "ar",
      flag: flags.ar,
    },
  ];
  const [flag, setFlag] = React.useState({
    language: language,
    flag: flags[language],
  });
  useEffect(() => {
    setFlag(languages.find((element) => element.language == language));
  }, [language]);
  const handleFlagChange = async (value) => {
    handleLanguageChange(value);
    setFlag(languages.find((element) => element.language == value));
  }
  const currentOptionDisplay = optionDisplay ? optionDisplay(flag) : flag;

  return (
    // added a new div
    <div style={{margin: "0", display: "flex", alignItems: "center"}} className="flag-combobox-container" onClick={onClick}>
      <div className="custom-combobox">
        <div style={{borderRadius: "6px", height: "20px"}} className="flag-combobox-selected" onClick={handleDropdownClick}>
          {currentOptionDisplay}
        </div>
        {isOpen && (
          <div className="flag-combobox-options">
            {languages.map((option, index) => {
              const key = `${index}`;
              const display = optionDisplay ? optionDisplay(option) : option;
              if (display === currentOptionDisplay)
                return null;
              return (
                <div key={key} style={{borderRadius: "6px", height: "20px"}} className="flag-combobox-option" onClick={() => handleOptionClick(option)}>
                  {display}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default FlagSelector;
