import React from 'react'
import EventManager from "../../../../services/EventManager";
import { useTranslation } from '../../../../contexts/TranslationProvider';
import '../buttons.scss'

const RessourceScoringReorderButton = () => {
    const { t } = useTranslation();
    const handleResponseValidation = async () => {
        EventManager.invoke('ReorderRessourceScoring', true);
    };
    return (
        <button className="navbar-button" onClick={handleResponseValidation}>
            {t("navbar.ressourceScoring.reorder")}
        </button>
    )
}

export default RessourceScoringReorderButton