import React, { useContext, useState } from "react";
import "./contractTempleteParam.scss";
import InputValuesContext from "../../../contexts/InputValuesContext";
import { ListInputItem } from "../../../components/common/ListInputItem";
import { isFileType } from "../../../helpers/helper";
import { BeneficialEntity, beneficialTypes, ClauseEntity, SubClauseEntity } from "../../../domain/entities";
import { Control, FieldValues, UseFormRegister } from "react-hook-form";
import EditContractTemplateContext from "../../../contexts/EditContractTemplateContext";
import ParamCombobox from "./ParamCombobox";
import { useTranslation } from "../../../contexts/TranslationProvider";
import EditParamBasicInfo from "./EditParamBasicInfo";
import { SegmentedClauseParam, SegmentedTextType } from "../../../domain/types/ClauseParams";
import CustomPropertiesContext from "../../../contexts/CustomPropertiesContext";
import CustomCombobox from "../../../components/common/CustomCombobox";
import EditPropertyParam from "./EditPropertyParam";
import { lowerCase } from "lodash";
import { sortByOriginalOrder } from "../../../utils/array";
import TableGridSelector from "./TableGridSelector";
import TableColorPicker from "./TableColorPicker";
const { default: EditIcon } = require("../../../assets/edit_icon.svg");
const { default: EditIconOn } = require("../../../assets/edit_icon_on.svg");
const { default: Delete } = require("../../../assets/delete.svg");
const { default: Remove } = require("../../../assets/remove.svg");


function ContractTempleteParam({
  param,
  clauseId,
  subClauseId,
  currentValue,
  handleInputChange,
  control,
  register,
}:
  {
    handleInputChange: (
      e: any,
      paramName: string,
      clauseId: ClauseEntity['id'],
      subClauseId: SubClauseEntity['id'],
    ) => void;
    param: SegmentedClauseParam;
    clauseId: ClauseEntity['id'];
    subClauseId: SubClauseEntity['id'];
    currentValue: any,
    control: Control<FieldValues, any>;
    register: UseFormRegister<FieldValues>;
  }) {
  const { onParamNameChanged, onParamChanged, onParamDelete, onOptionAdd, onOptionChanged, onOptionDelete, onParamTypeChange } = useContext(EditContractTemplateContext);
  const [isFocused, setIsFocused] = useState(false);
  const { fileNames } = useContext(InputValuesContext);
  const fieldName = `${clauseId}_${subClauseId}_${param.name}`;
  //
  const [isOptionEditing, setIsOptionEditing] = useState(false);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const { t, language } = useTranslation();
  const translationPath = t("pages.contractTemplateParam.edition")
  const translationTypes = "pages.contractTemplateParam.params"

  switch (param.type) {
    // Inside the switch block of ContractTempleteParam function
    case 'beneficial':
      return <>
        <div className="form-group">
          <div
            className={`d-flex flex-column grey-border`}
            style={{ paddingRight: language == "ar" && "5px" }}
          >
            <div className="content-action-options" style={{ marginBottom: language == "ar" && "0" }}>
              <img
                className="edit-action-option pointer"
                src={isOptionEditing ? EditIconOn : EditIcon}
                onClick={() => {
                  setIsOptionEditing(!isOptionEditing);
                }}
              />
              <img src={Remove} className="delete-options pointer" onClick={() => { onParamDelete(clauseId, subClauseId, param) }} />
            </div>
            <div className="param-type ms-2">{t(`${translationTypes}.${param.type}`)}</div>
            <div className="d-flex align-items-start justify-content-between py-2 px-2">
              {!isOptionEditing ? <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                <label style={{ wordBreak: "break-all" }} className="ms-2">{t(translationPath.parameterName)} {param?.name}</label>
                <label style={{ wordBreak: "break-all" }} className="ms-2">{t(translationPath.parameterLabel)} {param?.label}</label>
              </div> : (
                <EditParamBasicInfo param={param} clauseId={clauseId} subClauseId={subClauseId} />

              )}
            </div>
            {beneficialTypes.map((type) => (
              <div className="checkbox-beneficial">
                <input
                  type="checkbox"
                  checked={param?.args?.beneficialTypes.includes(type)}
                  name={type}
                  onChange={(e) => {
                    const updatedParam = { ...param };
                    if (!updatedParam.args) {
                      updatedParam.args = { beneficialTypes: [] };
                    }
                    updatedParam.args.beneficialTypes = e.target.checked
                      ? sortByOriginalOrder(beneficialTypes, [...new Set([...updatedParam.args.beneficialTypes, type])])
                      : updatedParam.args.beneficialTypes?.filter(i => i != type);
                    onParamChanged(clauseId, subClauseId, updatedParam);
                  }}
                  disabled={false}
                />
                <label htmlFor={type}> {translationPath.beneficial[lowerCase(type)]} </label>
              </div>
            ))}
          </div>
        </div>
      </>
    case 'beneficial[]': {

      type BeneficialType = 'Person' | 'Company' | 'Minor';

      const updatedParam = { ...param };

      if (!updatedParam.args?.beneficialTypes || updatedParam.args.beneficialTypes.length === 0) {
        updatedParam.args = {
          ...updatedParam.args,
          beneficialTypes: beneficialTypes,
        };
        onParamChanged(clauseId, subClauseId, updatedParam);
      }

      const handleCheckboxChange = (checked: boolean, type: BeneficialType) => {
        const updatedParam = { ...param };
        if (checked) {
          updatedParam.args.beneficialTypes = sortByOriginalOrder(beneficialTypes, [...new Set([...updatedParam.args.beneficialTypes, type])])
        } else {
          updatedParam.args.beneficialTypes = updatedParam.args.beneficialTypes.filter(i => i !== type);
        }
        onParamChanged(clauseId, subClauseId, updatedParam);
      };

      return (
        <>
          <div className="form-group">
            <div
              className={`d-flex flex-column grey-border`}
              style={{ paddingRight: language === "ar" && "5px" }}
            >
              <div className="content-action-options" style={{ marginBottom: language === "ar" && "0" }}>
                <img
                  className="edit-action-option pointer"
                  src={isOptionEditing ? EditIconOn : EditIcon}
                  onClick={() => setIsOptionEditing(!isOptionEditing)}
                />
                <img
                  src={Remove}
                  className="delete-options pointer"
                  onClick={() => onParamDelete(clauseId, subClauseId, param)}
                />
              </div>

              <div className="param-type ms-2">
                {t(`${translationTypes}.${param.type}`)}
              </div>

              <div className="d-flex align-items-start justify-content-between py-2 px-2">
                {!isOptionEditing ? (
                  <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                    <label style={{ wordBreak: "break-all" }} className="ms-2">{t(translationPath.parameterName)} {param?.name}</label>
                    <label style={{ wordBreak: "break-all" }} className="ms-2">{t(translationPath.parameterLabel)} {param?.label}</label>
                  </div>
                ) : (
                  <EditParamBasicInfo param={param} clauseId={clauseId} subClauseId={subClauseId} />
                )}
              </div>

              {/* Checkbox List */}
              {beneficialTypes.map((type) => (
                <div className="checkbox-beneficial">
                  <input
                    type="checkbox"
                    checked={param?.args?.beneficialTypes.includes(type)}
                    name={type}
                    onChange={(e) => handleCheckboxChange(e.target.checked, type)}
                    disabled={false}
                  />
                  <label htmlFor={type}>{translationPath.beneficial[lowerCase(type)]}</label>
                </div>
              ))}
            </div>
          </div>
        </>
      );
    }
    case 'table':
      return (
        <div
          className={`d-flex flex-column grey-border`}
          style={{ paddingRight: language == "ar" && "5px" }}
        >
          <div className="d-flex flex-column">
            <div className="content-action-options" style={{ marginBottom: language == "ar" && "0" }}>
              <img
                className="edit-action-option pointer"
                src={isOptionEditing ? EditIconOn : EditIcon}
                onClick={() => {
                  setIsOptionEditing(!isOptionEditing);
                }}
              />
              <img src={Remove} className="delete-options pointer" onClick={() => { onParamDelete(clauseId, subClauseId, param) }} />
            </div>
            <div className="param-type ms-2">{t(`${translationTypes}.${param.type}`)}</div>
            <div className="d-flex align-items-start justify-content-between py-2 px-2">
              {!isOptionEditing ? <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                <label style={{ wordBreak: "break-all" }} className="ms-2">{t(translationPath.parameterName)} {param?.name}</label>
                <label style={{ wordBreak: "break-all" }} className="ms-2">{t(translationPath.parameterLabel)} {param?.label}</label>
              </div> : (
                <EditParamBasicInfo param={param} clauseId={clauseId} subClauseId={subClauseId} />

              )}
            </div>
            {!isOptionEditing ? (
              <>
                <div className="d-flex px-3">
                  <input
                    type="checkbox"
                    checked={param.transposed}
                    onChange={(e) =>
                      onParamChanged(clauseId, subClauseId, {
                        ...param,
                        transposed: e.target.checked
                      })
                    }
                  />
                  <label className="px-2" style={{ color: "#1f2b3a", fontWeight: "400" }}> {t(translationPath.table.transposed)} </label>
                </div>
                <div className="d-flex container-table-element mb-1 mt-1">
                  <span className="header-table-edit"> {t(translationPath.table.colonnes)} </span>
                  <span className="header-table-edit">{t(translationPath.table.typesTitle)}</span>
                </div>
                {param.args && param.args.map((arg, index) => (
                  <div key={index} className="d-flex  container-table-element">
                    <p> {arg.header}</p>
                    <p>{arg.type}</p>
                  </div>
                ))}
              </>
            ) : (
              <>
              </>
            )}
          </div>
          {param.args && param.args.map((arg, index) => {
            return (
              isOptionEditing &&
              (<div className="d-flex justify-content-between  align-items-center px-2 mb-1 mt-2" style={{ width: "90%" }}>
                <input
                  className="label-input-edit"
                  style={{ height: "30px", width: "50%" }}
                  value={arg.header}
                  onChange={(e) => {
                    console.log(e.target.value)
                    console.log({
                      ...param,
                      args: param.args.map((arg, i) =>
                        i === index
                          ? { ...arg, header: e.target.value }
                          : arg
                      ),
                    })
                    onParamChanged(clauseId, subClauseId, {
                      ...param,
                      args: param.args.map((arg, i) =>
                        i === index
                          ? { ...arg, header: e.target.value }
                          : arg
                      ),
                    })
                  }
                  }
                />
                <select
                  value={arg.type}
                  style={{ height: "30px", width: "30%" }}
                  className="label-input-edit"
                  onChange={(e) =>
                    onParamChanged(clauseId, subClauseId, {
                      ...param,
                      args: param.args ? param.args.map((arg, i) =>
                        i === index
                          ? { ...arg, type: e.target.value as "string" | "number" | "date" }
                          : arg
                      ) : []
                    })
                  }
                >
                  <option value="string">  {t(translationPath.table.types.string)} </option>
                  <option value="number"> {t(translationPath.table.types.number)}</option>
                  <option value="date"> {t(translationPath.table.types.date)}</option>
                </select>

                <img src={Delete} className="delete-single-option" onClick={() => onOptionDelete(param, index)} />
              </div>
              )
            );
          })}
          {isOptionEditing && <p className="add-single-option" style={{ marginLeft: "8px" }}
            onClick={() => { onOptionAdd(param, { header: "", type: 'string' }); }}
          >+</p>}
        </div>
      );
    case 'boolean':
      return (
        <div
          className={`d-flex flex-column grey-border`}
          style={{ paddingRight: language == "ar" && "5px" }}
        >
          <div className="d-flex flex-column">
            <div className="content-action-options" style={{ marginBottom: language == "ar" && "0" }}>
              <img
                className="edit-action-option pointer"
                src={isOptionEditing ? EditIconOn : EditIcon}
                onClick={() => {
                  setIsOptionEditing(!isOptionEditing);
                }}
              />
              <img src={Remove} className="delete-options pointer" onClick={() => { onParamDelete(clauseId, subClauseId, param) }} />
            </div>
            <div className="param-type ms-2">{t(`${translationTypes}.${param.type}`)}</div>
            <div className="d-flex flex-column gap-2  py-2 justify-content-between  align-items-center form-group">
              {!isOptionEditing ? <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                <label style={{ wordBreak: "break-all" }} className="ms-2">{t(translationPath.parameterName)} {param?.name}</label>
                <label style={{ wordBreak: "break-all" }} className="ms-2">{t(translationPath.parameterLabel)} {param?.label}</label>
              </div> : (
                <EditParamBasicInfo param={param} clauseId={clauseId} subClauseId={subClauseId} />

              )}
              <div className="radio-group boolean-group">
                <label className="form-check-label">
                  <input
                    type="radio"
                    value="true"
                    checked={currentValue === true}
                    className={`form-check-input boolean-check ${currentValue === true && "checked-input"}`}
                    onChange={() => {
                      if (!param.args.textIfTrue)
                        onOptionAdd(param, true)
                      handleInputChange(
                        { target: { value: true } },
                        param.name,
                        clauseId,
                        subClauseId,
                      );
                      onParamChanged(clauseId, subClauseId, param)
                    }}
                    name={param.name}
                  />
                  {t(translationPath.boolean.oui)}
                </label>
                <label className="form-check-label">
                  <input
                    type="radio"
                    value="false"
                    checked={currentValue === false}
                    className={`form-check-input boolean-check ${currentValue === false && "checked-input"}`}
                    onChange={() => {
                      if (!param.args.textIfFalse)
                        onOptionAdd(param, false)
                      handleInputChange(
                        { target: { value: false } },
                        param.name,
                        clauseId,
                        subClauseId,
                      );
                      onParamChanged(clauseId, subClauseId, param)
                    }}
                    name={param.name}
                  />
                  {t(translationPath.boolean.non)}
                </label>
              </div>
            </div>
          </div>
        </div>
      );
    case 'date':
      return (
        <div
          className={`d-flex flex-column ${currentValue ? "green-border" : "grey-border"
            }`}
          style={{ marginBottom: calendarOpen && "280px", paddingRight: language == "ar" && "5px" }}
        > <div className="content-action-options" style={{ marginBottom: language == "ar" && "0" }}>
            <img
              className="edit-action-option pointer"
              src={isOptionEditing ? EditIconOn : EditIcon}
              onClick={() => {
                setIsOptionEditing(!isOptionEditing);
              }}
            />
            <img src={Remove} className="delete-options pointer" onClick={() => { onParamDelete(clauseId, subClauseId, param) }} />
          </div>
          <div className="param-type ms-2">{t(`${translationTypes}.${param.type}`)}</div>
          <div className="d-flex align-items-start justify-content-between py-2 px-2">
            {!isOptionEditing ? <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
              <label style={{ wordBreak: "break-all" }} className="ms-2">{t(translationPath.parameterName)} {param?.name}</label>
              <label style={{ wordBreak: "break-all" }} className="ms-2">{t(translationPath.parameterLabel)} {param.label ?? "JJ/MM/AAAA"}</label>
              <label style={{ wordBreak: "break-all" }} className="ms-2">{t(translationPath.parameterFormat)} {param.format ?? ""}</label>
              <label style={{ justifyContent: language === "ar" ? "right" : "left", wordBreak: "break-all" }} className="ms-2">  {translationPath.parameterConstraints} :
                {param.constraints?.map((c, idx) => (
                  <>
                    {idx !== 0 && <span key={idx}> , </span>}
                    <span key={idx}> {c.label} </span>
                  </>
                ))}
              </label>
            </div> : (
              <EditParamBasicInfo param={param} clauseId={clauseId} subClauseId={subClauseId} />

            )}
          </div>
          <div className="d-flex gap-2 align-items-center">
            <div className="w-100 px-3">
              <div className="date-disabled">
                JJ/MM/AAAA
              </div>
            </div>
          </div>
        </div>
      );
    case 'file':
      const displayFile = currentValue && (isFileType(currentValue) ? currentValue : { name: fileNames[currentValue] })
      return (
        <div className={`d-flex flex-column ${currentValue ? "green-border" : "grey-border"}`} style={{ paddingRight: language == "ar" && "5px" }}>
          <div className="content-action-options" style={{ marginBottom: language == "ar" && "0" }}>
            <img
              className="edit-action-option pointer"
              src={isOptionEditing ? EditIconOn : EditIcon}
              onClick={() => {
                setIsOptionEditing(!isOptionEditing);
              }}
            />
            <img src={Remove} className="delete-options pointer" onClick={() => { onParamDelete(clauseId, subClauseId, param) }} />
          </div>
          <div className="param-type ms-2">{t(`${translationTypes}.${param.type}`)}</div>
          <div className="d-flex align-items-start justify-content-between py-2 px-2">
            {!isOptionEditing ? <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
              <label style={{ wordBreak: "break-all" }} className="ms-2">{t(translationPath.parameterName)} {param?.name}</label>
              <label style={{ wordBreak: "break-all" }} className="ms-2">{t(translationPath.parameterLabel)} {param.label ?? "JJ/MM/AAAA"}</label>
            </div> : (
              <EditParamBasicInfo param={param} clauseId={clauseId} subClauseId={subClauseId} />

            )}
          </div>

        </div>
      );
    case 'csv':
      return (
        <div className={`d-flex flex-column ${currentValue ? "green-border" : "grey-border"}`} style={{ paddingRight: language == "ar" && "5px" }}>
          <div className="content-action-options" style={{ marginBottom: language == "ar" && "0" }}>
            <img
              className="edit-action-option pointer"
              src={isOptionEditing ? EditIconOn : EditIcon}
              onClick={() => {
                setIsOptionEditing(!isOptionEditing);
              }}
            />
            <img src={Remove} className="delete-options pointer" onClick={() => { onParamDelete(clauseId, subClauseId, param) }} />
          </div>
          <div className="param-type ms-2">{t(`${translationTypes}.${param.type}`)}</div>
          <div className="d-flex align-items-start justify-content-between py-2 px-2">
            {!isOptionEditing ? <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
              <label style={{ wordBreak: "break-all" }} className="ms-2">{t(translationPath.parameterName)} {param?.name}</label>
              <label style={{ wordBreak: "break-all" }} className="ms-2">{t(translationPath.parameterLabel)} {param.label ?? "JJ/MM/AAAA"}</label>
            </div> : (
              <EditParamBasicInfo param={param} clauseId={clauseId} subClauseId={subClauseId} />

            )}
          </div>

        </div>
      );
    case 'list':
      return (
        <div
          className={`d-flex flex-column ${currentValue ? "green-border" : "grey-border"
            }`}
          style={{ marginBottom: calendarOpen && "280px", paddingRight: language == "ar" && "5px" }}
        > <div className="content-action-options" style={{ marginBottom: language == "ar" && "0" }}>
            <img
              className="edit-action-option pointer"
              src={isOptionEditing ? EditIconOn : EditIcon}
              onClick={() => {
                setIsOptionEditing(!isOptionEditing);
              }}
            />
            <img src={Remove} className="delete-options pointer" onClick={() => { onParamDelete(clauseId, subClauseId, param) }} />
          </div>
          <div className="param-type ms-2">{t(`${translationTypes}.${param.type}`)}</div>
          <div className="d-flex align-items-start justify-content-between py-2 px-2">
            {!isOptionEditing ? <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
              <label style={{ wordBreak: "break-all" }} className="ms-2">{t(translationPath.parameterName)} {param?.name}</label>
              <label style={{ wordBreak: "break-all" }} className="ms-2">{t(translationPath.parameterLabel)} {param.label ?? "JJ/MM/AAAA"}</label>
            </div> : (
              <EditParamBasicInfo param={param} clauseId={clauseId} subClauseId={subClauseId} />

            )}
          </div>
          <div style={{ padding: "30px" }}>
            {param.args && param.args.map((arg, index) => {
              return (
                !isOptionEditing ? (
                  <ListInputItem
                    key={index}
                    index={index}
                    handleInputChange={handleInputChange}
                    clauseId={clauseId}
                    subClauseId={subClauseId}
                    control={control}
                    fieldName={fieldName}
                    param={param}
                    isEditing={false}
                    editable={false}
                  />
                ) : (
                  <div className="d-flex w-100 param-list-container">
                    <div className="d-flex flex-column w-100 mb-2">
                      <textarea
                        className="label-input-edit mt-2"
                        style={{ height: "50px" }}
                        value={arg.option}
                        onChange={(e) =>
                          onOptionChanged(param, { ...param.args[index], option: e.target.value }, index)
                        }
                      />
                      <div style={{ marginTop: "10px", gap: "5px" }} className="d-flex">
                        <input
                          type="checkbox"
                          checked={arg.required}
                          onChange={(e) =>
                            onOptionChanged(param, { ...param.args[index], required: e.target.checked }, index)
                          }
                        />
                        <label> {t(translationPath.list.required)} </label>
                      </div>
                    </div>
                    <img src={Delete} style={{ marginLeft: language != "ar" && "-15px", marginTop: "-30px" }} className="delete-single-option" onClick={() => onOptionDelete(param, index)} />
                  </div>
                )
              );
            })}
            {isOptionEditing && <p className="add-enum-single-option" onClick={() => { onOptionAdd(param, { option: "", required: false }); }}>+ {t("pages.contractTemplateParam.edition.enum.option")}</p>}
          </div>

        </div>
      );
    case 'enum':
      const enumOptions = param.args && param.args.map((arg) => arg.option);
      const hasValue = null;
      return (
        <>
          <div className="form-group">
            <div
              className={`d-flex flex-column  ${!hasValue ? "grey-border" : "green-border"
                }`}
              style={{ paddingRight: language == "ar" && "5px" }}
            > <div className="content-action-options" style={{ marginBottom: language == "ar" && "0" }}>
                <img
                  className="edit-action-option pointer"
                  src={isOptionEditing ? EditIconOn : EditIcon}
                  onClick={() => {
                    setIsOptionEditing(!isOptionEditing);
                  }}
                />
                <img src={Remove} className="delete-options pointer" onClick={() => { onParamDelete(clauseId, subClauseId, param) }} />
              </div>
              <div className="param-type ms-2">{t(`${translationTypes}.${param.type}`)}</div>
              <div className="d-flex align-items-start justify-content-between py-2 px-2">
                {!isOptionEditing ? <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                  <label style={{ wordBreak: "break-all" }} className="ms-2">{t(translationPath.parameterName)} {param?.name}</label>
                  <label style={{ wordBreak: "break-all" }} className="ms-2">{t(translationPath.parameterLabel)} {param.label ?? "JJ/MM/AAAA"}</label>
                </div> : (
                  <EditParamBasicInfo param={param} clauseId={clauseId} subClauseId={subClauseId} />

                )}
              </div>
              <div className=" flex-column align-items-start px-3 pb-1 gap-0">
                {enumOptions?.map((option, index) => (
                  <div key={`${fieldName}_${index}`} className="form-check">
                    <div style={{ display: isOptionEditing ? "flex" : "" }}>
                      {!isOptionEditing ? <input
                        type={"radio"}
                        className={"form-check-input"}
                        value={!isOptionEditing ? index : option}
                        checked={currentValue === index}
                        onChange={(e) => {
                          handleInputChange(
                            { target: { value: index } },
                            param.name,
                            clauseId,
                            subClauseId
                          )
                        }}
                      /> : <textarea
                        className="label-input-edit mt-2"
                        style={{ marginLeft: "-15px" }}
                        value={option}
                        onChange={(e) =>
                          onOptionChanged(param, e.target.value, index)
                        }
                      />}
                      {!isOptionEditing ? <label className="form-check-label" htmlFor={String(index)}>
                        {option}
                      </label> : <img src={Delete} style={{ marginRight: language == "ar" && "20px" }} className="delete-single-option" onClick={() => onOptionDelete(param, index)} />}
                    </div>
                  </div>
                ))}
              </div>
              {isOptionEditing && <p className="add-enum-single-option" onClick={() => { onOptionAdd(param, { option: "", text: "" }); }}>+ {t("pages.contractTemplateParam.edition.enum.option")}</p>}
            </div>
          </div>
        </>
      );
    case 'number':
      return (
        <div
          className={`d-flex flex-column ${currentValue ? "green-border" : "grey-border"
            }`}
          style={{
            boxShadow: isFocused ? "0 0 0 2px #893aee" : "none",
            paddingRight: language == "ar" && "5px",
            marginBottom: "10px",
          }}
        >
          <div className="content-action-options" style={{ marginBottom: language == "ar" && "0" }}>
            <img
              className="edit-action-option pointer"
              src={isOptionEditing ? EditIconOn : EditIcon}
              onClick={() => {
                setIsOptionEditing(!isOptionEditing);
              }}
            />
            <img src={Remove} className="delete-options pointer" onClick={() => { onParamDelete(clauseId, subClauseId, param) }} />
          </div>
          <ParamCombobox param={param} onChangeParamType={(type) => onParamTypeChange(param, type)} />
          <div className="d-flex align-items-start justify-content-between py-2 px-2">
            {!isOptionEditing ? <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
              <label style={{ wordBreak: "break-all" }} className="ms-2">{t(translationPath.parameterName)} {param?.name}</label>
              <label style={{ wordBreak: "break-all" }} className="ms-2">{t(translationPath.parameterLabel)} {param.label ?? "JJ/MM/AAAA"}</label>
              <label style={{ justifyContent: language === "ar" ? "right" : "left", wordBreak: "break-all" }} className="ms-2">  {translationPath.parameterFormat} :{param.format ?? ""}</label>
              <label style={{ justifyContent: language === "ar" ? "right" : "left", wordBreak: "break-all" }} className="ms-2">  {translationPath.parameterFormula} :{param.formula ?? ""}</label>
              <label style={{ justifyContent: language === "ar" ? "right" : "left", wordBreak: "break-all" }} className="ms-2">  {translationPath.parameterConstraints} :
                {param.constraints?.map((c, idx) => (
                  <>
                    {idx !== 0 && <span key={idx}> , </span>}
                    <span key={idx}> {c.label} </span>
                  </>
                ))}
              </label>
            </div> : (
              <>
                <EditParamBasicInfo param={param} clauseId={clauseId} subClauseId={subClauseId} />
              </>
            )}
          </div>
        </div>
      );
    case 'property':
      return (
        <div
          className={`d-flex flex-column ${currentValue ? "green-border" : "grey-border"
            }`}
          style={{
            boxShadow: isFocused ? "0 0 0 2px #893aee" : "none",
            paddingRight: language == "ar" && "5px",
            marginBottom: "10px",
          }}
        >
          <div className="content-action-options" style={{ marginBottom: language == "ar" && "0" }}>
            <img
              className="edit-action-option pointer"
              src={isOptionEditing ? EditIconOn : EditIcon}
              onClick={() => {
                setIsOptionEditing(!isOptionEditing);
              }}
            />
            <img src={Remove} className="delete-options pointer" onClick={() => { onParamDelete(clauseId, subClauseId, param) }} />
          </div>
          <ParamCombobox param={param} onChangeParamType={(type) => onParamTypeChange(param, type)} />
          <div className="d-flex align-items-start justify-content-between py-2 px-2">
            {!isOptionEditing ? <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
              <label style={{ wordBreak: "break-all" }} className="ms-2">{t(translationPath.parameterName)} {param?.name}</label>
              <label style={{ wordBreak: "break-all" }} className="ms-2">{t(translationPath.parameterLabel)} {param.label ?? "JJ/MM/AAAA"}</label>
              <label style={{ wordBreak: "break-all" }} className="ms-2">
                {translationPath.parameterTag}: {param.args.tag ? param.args.tag : ""}
              </label>
              {param.args.parentParam &&
                <label style={{ wordBreak: "break-all" }} className="ms-2">
                  {translationPath.parentTag}: {param.args.parentParam}
                </label>
              }
            </div> : (
              <EditPropertyParam param={param} clauseId={clauseId} subClauseId={subClauseId} />
            )}
          </div>
        </div>
      );
    case 'string':
      return (
        <div
          className={`d-flex flex-column ${currentValue ? "green-border" : "grey-border"
            }`}
          style={{
            boxShadow: isFocused ? "0 0 0 2px #893aee" : "none",
            paddingRight: language == "ar" && "5px",
            marginBottom: "10px",
          }}
        >
          <div className="content-action-options" style={{ marginBottom: language == "ar" && "0" }}>
            <img
              className="edit-action-option pointer"
              src={isOptionEditing ? EditIconOn : EditIcon}
              onClick={() => {
                setIsOptionEditing(!isOptionEditing);
              }}
            />
            <img src={Remove} className="delete-options pointer" onClick={() => { onParamDelete(clauseId, subClauseId, param) }} />
          </div>
          <ParamCombobox param={param} onChangeParamType={(type) => onParamTypeChange(param, type)} />
          <div className="d-flex align-items-start justify-content-between py-2 px-2">
            {!isOptionEditing ? <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
              <label style={{ wordBreak: "break-all" }} className="ms-2">{t(translationPath.parameterName)} {param?.name}</label>
              <label style={{ wordBreak: "break-all" }} className="ms-2">{t(translationPath.parameterLabel)} {param.label ?? "JJ/MM/AAAA"}</label>
              <label style={{ justifyContent: language === "ar" ? "right" : "left", wordBreak: "break-all" }} className="ms-2">  {translationPath.parameterFormula} :{param.formula ?? ""}</label>
              <label style={{ justifyContent: language === "ar" ? "right" : "left", wordBreak: "break-all" }} className="ms-2">  {translationPath.parameterConstraints} :
                {param.constraints?.map((c, idx) => (
                  <>
                    {idx !== 0 && <span key={idx}> , </span>}
                    <span key={idx}> {c.label} </span>
                  </>
                ))}
              </label>
            </div> : (
              <>
                <EditParamBasicInfo param={param} clauseId={clauseId} subClauseId={subClauseId} />
              </>
            )}
          </div>
        </div>
      );
    case 'static-table':
      const { cells } = param.args;
      const { args } = param;
      const borderColor = args?.style?.borderColor || "#000000";
      const rows = cells?.length;
      const cols = cells[0] ? cells[0].length : 0;
      const prepareNewTable = (rows, cols, oldTable = []) => {
        const newTable = [];
        for (let i = 0; i < rows; i++) {
          const row = [];
          for (let j = 0; j < cols; j++) {
            if (oldTable[i] && oldTable[i][j]) {
              row.push(oldTable[i][j]);
            } else {
              row.push({
                content: [["id", "_", SegmentedTextType.STATIC]],
                cellStyle: {}
              });
            }
          }
          newTable.push(row);
        }

        return newTable;
      };

      return (
        <div
          className={`d-flex flex-column ${currentValue ? "green-border" : "grey-border"
            }`}
          style={{
            boxShadow: isFocused ? "0 0 0 2px #893aee" : "none",
            paddingRight: language == "ar" && "5px",
            marginBottom: "10px",
          }}
        >
          <div className="content-action-options" style={{ marginBottom: language == "ar" && "0" }}>
            <img
              className="edit-action-option pointer"
              src={isOptionEditing ? EditIconOn : EditIcon}
              onClick={() => {
                setIsOptionEditing(!isOptionEditing);
              }}
            />
            <img src={Remove} className="delete-options pointer" onClick={() => { onParamDelete(clauseId, subClauseId, param) }} />
          </div>
          <div className="param-type ms-2">{t(`${translationTypes}.${param.type}`)}</div>
          <div className="d-flex align-items-start justify-content-between py-2 px-2">
            {!isOptionEditing ? <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
              <label style={{ wordBreak: "break-all" }} className="ms-2">{t(translationPath.parameterName)} {param?.name}</label>
              <label style={{ wordBreak: "break-all" }} className="ms-2">{t(translationPath.parameterLabel)} {param.label}</label>
              <label className="ms-2">{t(translationPath.parameterDimensions)} {`${rows} x ${cols}`}</label>
              <div style={{ margin: "0px", display: "flex", alignItems: "center", gap: "10px" }}>
                <label className="ms-2">
                  {t(translationPath.parameterBorders)}
                  {`${borderColor}`}

                </label>
                <div style={{ width: "10px", height: "10px", borderRadius: "2", backgroundColor: borderColor, border: "1px solid black" }}></div>
              </div>

            </div> : (
              <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                <EditParamBasicInfo param={param} clauseId={clauseId} subClauseId={subClauseId} />
                <div style={{ alignItems: "flex-start", justifyItems: "start", display: "flex", flexDirection: "column" }}>
                  <label className="ms-2">{t(translationPath.parameterDimensions)} {`${rows} x ${cols}`}</label>

                  <TableGridSelector
                    onGridSelect={(r, c) => {
                      onParamChanged(clauseId, subClauseId, {
                        ...param,
                        args: {
                          ...param.args,
                          cells: prepareNewTable(r, c, param.args?.cells ?? []),
                        },
                      });
                    }}
                    defaultCols={cols}
                    defaultRows={rows} />
                </div>
                <div style={{ alignItems: "flex-start", justifyItems: "start", display: "flex", flexDirection: "column" }}>
                  <label className="ms-2">{t(translationPath.parameterBorders)} {`${borderColor}`}</label>
                  <TableColorPicker
                    onColorChange={(color) => {
                      onParamChanged(clauseId, subClauseId, {
                        ...param,
                        args: {
                          ...param.args,
                          style: {
                            ...param.args.style,
                            borderColor: color,
                          },
                        },
                      });
                    }}
                    color={borderColor}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      );
    case 'index':
      const { start, step } = param.args ? param.args : { start: 0, step: 1 };
      return (
        <div
          className={`d-flex flex-column ${currentValue ? "green-border" : "grey-border"
            }`}
          style={{
            boxShadow: isFocused ? "0 0 0 2px #893aee" : "none",
            paddingRight: language == "ar" && "5px",
            marginBottom: "10px",
          }}
        >
          <div className="content-action-options" style={{ marginBottom: language == "ar" && "0" }}>
            <img
              className="edit-action-option pointer"
              src={isOptionEditing ? EditIconOn : EditIcon}
              onClick={() => {
                setIsOptionEditing(!isOptionEditing);
              }}
            />
            <img src={Remove} className="delete-options pointer" onClick={() => { onParamDelete(clauseId, subClauseId, param) }} />
          </div>
          <div className="param-type ms-2">{t(`${translationTypes}.${param.type}`)}</div>
          <div className="d-flex align-items-start justify-content-between py-2 px-2">
            {!isOptionEditing ? <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
              <label style={{ wordBreak: "break-all" }} className="ms-2">{t(translationPath.parameterName)} {param?.name}</label>
              <label style={{ wordBreak: "break-all" }} className="ms-2">{t(translationPath.parameterLabel)} {param.label}</label>
              <label className="ms-2">{t(translationPath.parameterStart)} {`${start}`}</label>
              <label className="ms-2">{t(translationPath.parameterStep)} {`${step}`}</label>
            </div> : (
              <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                <EditParamBasicInfo param={param} clauseId={clauseId} subClauseId={subClauseId} />
                <div style={{ gap: "5px" }} className="edit-param-container">
                  <div className="edit-param-name">
                    <label className="ms-2 label">{t(translationPath.parameterStart)}</label>
                    <input
                      className="input"
                      type="number"
                      value={start}
                      onChange={(e) => {
                        onParamChanged(clauseId, subClauseId, {
                          ...param,
                          args: {
                            ...param.args,
                            start: Number(e.target.value),
                          },
                        });
                      }}
                    />
                  </div>
                  <div className="edit-param-name">
                    <label className="ms-2 label">{t(translationPath.parameterStep)}</label>
                    <input
                      className="input"
                      type="number"
                      value={step}
                      onChange={(e) => {
                        onParamChanged(clauseId, subClauseId, {
                          ...param,
                          args: {
                            ...param.args,
                            step: Number(e.target.value),
                          },
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      );
    default:
      return (
        <div
          className={`d-flex flex-column ${currentValue ? "green-border" : "grey-border"
            }`}
          style={{
            boxShadow: isFocused ? "0 0 0 2px #893aee" : "none",
            paddingRight: language == "ar" && "5px",
            marginBottom: "10px",
          }}
        >
          <div className="content-action-options" style={{ marginBottom: language == "ar" && "0" }}>
            <img
              className="edit-action-option pointer"
              src={isOptionEditing ? EditIconOn : EditIcon}
              onClick={() => {
                setIsOptionEditing(!isOptionEditing);
              }}
            />
            <img src={Remove} className="delete-options pointer" onClick={() => { onParamDelete(clauseId, subClauseId, param) }} />
          </div>
          <ParamCombobox param={param} onChangeParamType={(type) => onParamTypeChange(param, type)} />
          <div className="d-flex align-items-start justify-content-between py-2 px-2">
            {!isOptionEditing ? <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
              <label style={{ wordBreak: "break-all" }} className="ms-2">{t(translationPath.parameterName)} {param?.name}</label>
              <label style={{ wordBreak: "break-all" }} className="ms-2">{t(translationPath.parameterLabel)} {param.label ?? "JJ/MM/AAAA"}</label>
            </div> : (
              <EditParamBasicInfo param={param} clauseId={clauseId} subClauseId={subClauseId} />
            )}
          </div>
        </div>
      );
  }
}
export default ContractTempleteParam;
