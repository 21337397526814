import React, { useState } from "react";
import { useTranslation } from "../../../contexts/TranslationProvider";
import "./EditStep.scss";
import {
  GroupEntity,
} from "../../../domain/entities";
import { FormParam } from "../../../domain/types/FormParams";
import { WorkflowStep } from "../../../domain/Workflow";
import CustomCombobox from "../../common/newDesign/CustomCombobox";
import { RessourceStatus, statuses } from "../../../domain/types/status";


interface EditStepProps {
  step: WorkflowStep;
  groups: GroupEntity[];
  params: FormParam[];
  onClose: () => void;
  onSave: (step: WorkflowStep) => void;
}

function EditStep({ onClose, onSave, groups, step }: EditStepProps) {
  const [stepData, setStepData] = useState(step);
  const [isOpen, setIsOpen] = useState<keyof WorkflowStep>(null);

  const { t, language } = useTranslation();

  const handleSave = async (e) => {
    onSave({
      ...stepData,
    })
    onClose()

  };

  return (
    <>
      <div className="modal-backdrop"></div>
      <div id="type-add-popup" className="type-add-popup">
        <div className="modal modal-add-type d-flex justify-content-center align-items-center">
          <div>
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  {"Edit Step"}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={onClose}
                  aria-label="Close"
                  style={{ marginRight: language === "ar" && "75%" }}
                ></button>
              </div>
              <div className="modal-body body-add-type">
                <div className="form-group" style={{ position: "relative" }}>
                  <label >name</label>
                  <div className="d-flex justify-content-between align-items-center">
                    <input
                      required
                      value={stepData.name}
                      onChange={(e) => {
                        const name = e.target.value
                        setStepData({
                          ...stepData,
                          name,
                        })
                      }}
                    />
                  </div>
                  <label >status</label>
                  <div className="d-flex justify-content-between align-items-center">
                    <CustomCombobox
                      isValid={!!stepData.status}
                      options={statuses}
                      value={stepData.status}
                      onChange={(status: RessourceStatus) => setStepData({ ...stepData, status })}
                      isOpen={isOpen == 'status'}
                      setIsOpen={(value) => setIsOpen(value && 'status' || null)}
                      canDeselect={true}
                    />
                  </div>
                  <label >assign to group</label>
                  <div className="d-flex justify-content-between align-items-center">
                    <CustomCombobox
                      isValid={!!stepData.assignGroupId}
                      options={groups}
                      optionDisplay={group => group?.name ?? ""}
                      value={groups.find(g => g.id == stepData.assignGroupId)}
                      onChange={(group: GroupEntity) => setStepData({ ...stepData, assignGroupId: group?.id ?? null })}
                      isOpen={isOpen == 'assignGroupId'}
                      setIsOpen={(value) => setIsOpen(value && 'assignGroupId' || null)}
                      canDeselect={true}
                    />
                  </div>
                  <label >validate group</label>
                  <div className="d-flex justify-content-between align-items-center">
                    <CustomCombobox
                      options={groups}
                      optionDisplay={group => group?.name ?? ""}
                      value={groups.find(g => g.id == stepData.validateGroupId)}
                      onChange={(group: GroupEntity) => setStepData({ ...stepData, validateGroupId: group?.id ?? null })}
                      isOpen={isOpen == 'validateGroupId'}
                      setIsOpen={(value) => setIsOpen(value && 'validateGroupId' || null)}
                      canDeselect={true}
                    />
                  </div>

                </div>
                <div className="actions-popup-type">
                  <button className="add-signle-type"
                    onClick={handleSave}
                    disabled={!(stepData.name && stepData.status && stepData.assignGroupId)}
                  >
                    {"Save"}
                  </button>
                  <button
                    type="reset"
                    className="cancel-adding-type"
                    onClick={onClose}
                  >
                    {"Cancel"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditStep;
