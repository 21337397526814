import React, { useState, useEffect } from "react";
import { useTranslation } from "../../../contexts/TranslationProvider";
import { RessourceScoringEntity } from "../../../domain/entities";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const ReorderModal = ({
  onClose,
  clauseList,
  handleReorderClauses
}: {
  onClose: () => void;
  clauseList: RessourceScoringEntity["clauses"];
  handleReorderClauses: (clauseList: RessourceScoringEntity["clauses"]) => void;
}) => {

  const { t, language } = useTranslation();
  const [orderedClauses, setOrderedClauses] = useState(clauseList);
  useEffect(() => {
    const sortedClauses = clauseList?.slice().sort((a, b) => parseInt(a.index) - parseInt(b.index));
    setOrderedClauses(sortedClauses);
  }, [clauseList]);

  const handleDrop = (result: any) => {
    if (!result.destination) return;
    const items = Array.from(orderedClauses);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setOrderedClauses(items);
  };
  const onConfirmOrder = () => {
    handleReorderClauses(orderedClauses);
    onClose();
  };
  return (
    <div className="reorder-modal">
      <div className="modal-backdrop"></div>
      <div id="contractz-lab">
        <div className="modal d-flex justify-content-center align-items-center">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  {t("contractCompliancePage.modalTitle")}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={onClose}
                  aria-label="Close"
                  style={{ marginRight: language === "ar" ? "80%" : undefined }}
                ></button>
              </div>

              <div className="modal-body pb-3">
                <div
                  style={{
                    width: "100%",
                    marginTop: "10px",
                    minHeight: "80vh",
                  }}
                >
                  <p
                    style={{
                      marginBottom: "20px",
                      color: "#6c757d",
                      fontSize: "14px",
                    }}
                  >
                    {t("contractCompliancePage.modalDescription")}
                  </p>
                  <DragDropContext onDragEnd={handleDrop}>
                    <Droppable droppableId="droppable">
                      {(provided) => (
                        <div
                          className="clause-list"
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {orderedClauses.map((clause, index) =>
                            <Draggable
                              key={clause.id}
                              draggableId={`${clause.id}`}
                              index={index}
                            >
                              {(provided) => (
                                <div
                                  key={clause.id}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <div
                                  className={`edit-contract-compliance-clause selectable px-4 py-2 mx-1 clause-${clause.id}`}
                                  id={`${clause.id}`}
                                  style={{
                                    backgroundColor: "white",
                                    overflow: "hidden",
                                    marginBottom: "10px",
                                  }}>
                                    <div className="edit-contract-clause-header">
                                    <div>
                                      <b className="mx-2">{clause.name}</b>
                                    </div>
                                  </div>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          )}
                          {provided.placeholder}
                        </div>)}
                    </Droppable>
                  </DragDropContext>
                </div>
                <div className="form-group">
                  <div className="modal-buttons">
                    <button className="btn confirm-order-button" onClick={onConfirmOrder}>
                      {t("contractCompliancePage.buttons.confirmOrder")}
                    </button>
                    <button className="btn cancel-button" onClick={onClose}>
                      {t("contractCompliancePage.buttons.cancel")}
                    </button>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReorderModal;
