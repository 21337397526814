import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import ReactFlow, {
  Node,
  Edge,
  Connection,
  addEdge,
  Background,
  ReactFlowProvider,
  Handle,
  Position,
  reconnectEdge,
  NodeMouseHandler,
} from 'reactflow';

import 'reactflow/dist/style.css';
import './workflowSettings.scss';
import { ClauseEntity, GroupEntity } from '../../../domain/entities';
import EditTransition from './EditTransition';
import { FormParam } from '../../../domain/types/FormParams';
import { WorkflowGraph, WorkflowStep, WorkflowTransition } from '../../../domain/Workflow';
import EditStep from './EditStep';
import { v4 as uuidv4 } from 'uuid';

const calculateMidpoint = (sourcePos: { x: number; y: number }, targetPos: { x: number; y: number }) => {
  return {
    x: (sourcePos.x * 0.25 + targetPos.x * 0.75),
    y: (sourcePos.y * 0.25 + targetPos.y * 0.75),
  };
};

type NodeContextMenuProps = {
  label: string;
  style: React.HTMLAttributes<HTMLDivElement>['style'];
  actions: {
    label: string,
    onAction: () => void
  }[];
}
function NodeContextMenu({
  label,
  style,
  actions
}: NodeContextMenuProps) {

  return (
    <div
      style={{ ...style }}
      className="context-menu"
    >
      <p style={{ margin: '0.5em' }}>
        <small>{label}</small>
      </p>
      {actions.map((action, idx) => (
        <button key={idx} onClick={action.onAction}>{action.label}</button>
      ))}
    </div>
  );
}
interface WorkflowSettingsModalProps {
  groups: GroupEntity[];
  groupsGraph: WorkflowGraph;
  params: FormParam[];
  onClose: () => void;
  onSave: (groupsGraph: WorkflowGraph) => void;
  clauses: ClauseEntity[];
}

const WorkflowSettingsModal: React.FC<WorkflowSettingsModalProps> = ({
  params,
  groups,
  groupsGraph,
  onClose,
  onSave,
  clauses
}) => {
  type StepNodeType = Node<WorkflowStep & {
    assignGroup: GroupEntity;
  }>
    & { type: 'StepNode' }
  type TransitionNodeType = Node<WorkflowTransition>
    & { type: 'TransitionNode' }

  const [steps, setSteps] = useState<WorkflowStep[]>(groupsGraph?.steps ?? []);
  const [transitions, setTransitions] = useState<WorkflowTransition[]>(groupsGraph?.transitions ?? []);
  const [transitionToEdit, setTransitionToEdit] = useState<WorkflowTransition | null>(null);
  const [stepToEdit, setStepToEdit] = useState<WorkflowStep | null>(null);


  const edgeReconnectSuccessful = useRef(true)
  const ref = useRef(null);

  const [stepNodes, setStepNodes] = useState<StepNodeType[]>([]);
  const [transitionNodes, setTransitionNodes] = useState<TransitionNodeType[]>([]);

  const [edges, setEdges] = useState<Edge[]>(groupsGraph?.transitions?.map((transition) => ({
    id: `${transition.fromId}-${transition.toId}`,
    source: transition.fromId?.toString() || '',
    target: transition.toId?.toString() || '',
    animated: true,
    style: { stroke: '#A1C4FD', strokeWidth: 2 },
    deletable: true,
    type: 'default',
  })) ?? []);

  const [menu, setMenu] = useState<NodeContextMenuProps | null>(null);

  useEffect(() => {
    setEdges(groupsGraph?.transitions?.map((transition) => ({
      id: `${transition.fromId}-${transition.toId}`,
      source: transition.fromId?.toString() || '',
      target: transition.toId?.toString() || '',
      animated: true,
      style: { stroke: '#A1C4FD', strokeWidth: 2 },
      deletable: true,
      type: 'default',
    })) ?? [])
  }, [])

  //handle new or deleted edges
  useEffect(() => {
    let updatedTransitions = transitions.filter((t) => edges.find(edge => edge.source == String(t.fromId) && edge.target == String(t.toId)))
    edges.forEach((edge, index) => {
      let transition = updatedTransitions.find(t => edge.source == String(t.fromId) && edge.target == String(t.toId))
      if (!transition) {
        transition = {
          fromId: edge.source,
          toId: edge.target,
          conditions: []
        }
        updatedTransitions.push(transition)
      }
    })
    updatedTransitions = updatedTransitions.filter(t => {
      const from = steps.find(s => s.id == t.fromId)
      const to = steps.find(s => s.id == t.toId)
      return from && to
    })
    setTransitions(updatedTransitions);
  }, [edges]);


  useEffect(() => {
    const updatedStepNodes: StepNodeType[] = steps.map((step, index) => {
      const assignGroup = groups.find(g => g.id == step.assignGroupId)
      return {
        id: step.id,
        data: {
          ...step,
          assignGroup,
        },
        position: step?.position ?? { x: Math.random() * 300, y: Math.random() * 300 },
        style: { backgroundColor: '#D4F1F4', borderRadius: '5px', padding: '10px', width: 'auto' },
        type: 'StepNode',
        selectable: false,
        deletable: false,
      }
    })
    setStepNodes(updatedStepNodes);
  }, [groups, steps]);

  useEffect(() => {
    const transitionNodes = edges.map((edge, index) => {
      const sourceNode = stepNodes.find((node) => node.id === edge.source);
      const targetNode = stepNodes.find((node) => node.id === edge.target);
      const transition = transitions.find(t => edge.source == String(t.fromId) && edge.target == String(t.toId))
      if (sourceNode && targetNode && transition) {
        const midpoint = calculateMidpoint(sourceNode.position, targetNode.position);
        const conditionsLength = transition.conditions.length
        return {
          id: `midpoint-${index}`,
          position: midpoint,
          data: transition,
          style: { backgroundColor: conditionsLength ? '#FFD700' : "green", borderRadius: '50%', width: 10, height: 10 },
          type: 'TransitionNode',
          selectable: false,
          draggable: false,
        } as TransitionNodeType;
      }
      return null;
    }).filter(Boolean);
    setTransitionNodes(transitionNodes);
  }, [edges, stepNodes, transitions]);


  // Handle node connections
  const onConnect = (params: Connection) => {
    setEdges((prevEdges) => {
      const isDuplicate = prevEdges?.some(
        (edge) => edge.source === params.source && edge.target === params.target
      );
      if (!isDuplicate) {
        return addEdge({ ...params, animated: true, style: { stroke: '#A1C4FD', strokeWidth: 2 } }, prevEdges);
      }
      return prevEdges;
    });
  };

  const handleSave = async () => {
    try {
      const updatedGroupsGraph: WorkflowGraph = {
        steps: stepNodes.map((s) => {
          const { assignGroup, ...step } = s.data
          return step
        }),
        transitions: transitions
      }
      onSave(updatedGroupsGraph)
      onClose()
      return
    } catch (error) {
      console.error('Error saving transitions:', error);
    }
  };
  const handleSaveTransition = (transition: WorkflowTransition) => {
    let updatedTransitions = transitions.map((t) => t.fromId == transition.fromId && t.toId == transition.toId ?
      transition : t)
    setTransitions(updatedTransitions)
  }

  const handleSaveStep = (step: WorkflowStep) => {
    if (!step.id) {
      step.id = `${uuidv4()}`
      setSteps([...steps, step])
      return
    }
    let updatedSteps = steps.map((s) => s.id == step.id ?
      step : s)
    setSteps(updatedSteps)
  }


  // Custom connectable node
  const StepNode = ({ data }: { data: StepNodeType['data'] }) => (
    <div style={{
      textAlign: 'center', padding: '10px',
      // ...(data.isInitial ? {
      //   border: '1px solid green', borderRadius: '5px',
      // } : {})
    }}
      onClick={() => {
      }}>
      <Handle type="target" position={Position.Top} style={{ background: '#555' }} />
      <div>{data.name}</div>
      <Handle type="source" position={Position.Bottom} style={{ background: '#555' }} />
    </div>
  );

  const TransitionNode = ({ data }: { data: TransitionNodeType['data'] }) => {
    const transition = data
    return (
      <div style={{ opacity: 0 }}>
      </div>
    )
  };

  const addStep = () => {
    const newStep: WorkflowStep = {
      id: "",
      name: "",
      position: { x: Math.random() * 300, y: Math.random() * 300 },
      status: null,
      assignGroupId: null,
      validateGroupId: null,
    }
    setStepToEdit(newStep)
  }

  // Register the custom node type
  const nodeTypes = { StepNode, TransitionNode };

  const onNodeClick: NodeMouseHandler = (event: any, element: StepNodeType | TransitionNodeType) => {

    if (element.type == 'TransitionNode') {
      setTransitionToEdit(element.data)
    } else if (element.type == 'StepNode') {
    }
  }

  const onReconnectStart = useCallback(() => {
    edgeReconnectSuccessful.current = false;
  }, []);

  const onReconnect = useCallback((oldEdge, newConnection) => {
    edgeReconnectSuccessful.current = true;
    setEdges((els) => reconnectEdge(oldEdge, newConnection, els));
  }, []);

  const onReconnectEnd = useCallback((_, edge) => {
    if (!edgeReconnectSuccessful.current) {
      setEdges((eds) => eds.filter((e) => e.id !== edge.id));
    }

    edgeReconnectSuccessful.current = true;
  }, []);

  const onNodeContextMenu = useCallback(
    (event, node: StepNodeType | TransitionNodeType) => {

      // Prevent native context menu from showing
      event.preventDefault();
      if (node.type == 'StepNode') {

        const editStep = () => {
          setStepToEdit({ ...node.data, position: node.position })
          setMenu(null)
        }

        const deleteStep = () => {
          setSteps(prev => prev.filter(gn => gn.id != node.id))
          setMenu(null)
        }

        setMenu({
          label: node.data.name,
          style: {
            top: event.clientY - 250,
            left: event.clientX - 500,
          },
          actions: [
            {
              label: "edit",
              onAction: editStep,
            },
            {
              label: "delete",
              onAction: deleteStep,
            }
          ]
        });
      }
    },
    [setMenu],
  );
  useMemo(() => console.log(menu), [menu])
  // Close the context menu if it's open whenever the window is clicked.
  const onPaneClick = useCallback(() => setMenu(null), [setMenu]);

  const allNodes = useMemo(() => [...stepNodes, ...transitionNodes], [stepNodes, transitionNodes])
  return (
    <ReactFlowProvider>
      <div>
        <div className="modal-backdrop"></div>
        <div id="contractz-lab">
          <div className="modal d-flex justify-content-center align-items-center">
            <div>
              <div className="modal-content" style={{ width: '960px' }}>
                <div className="modal-header">
                  <h5 className="modal-title">Workflow Settings</h5>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={onClose}
                    aria-label="Close"
                  ></button>
                </div>
                <div
                  className="modal-body"
                  style={{ paddingBottom: '5%', position: 'relative', height: '500px' }}
                >
                  <ReactFlow
                    ref={ref}
                    nodes={allNodes}
                    edges={edges}
                    style={{ width: '100%', height: '100%' }}
                    onNodeClick={onNodeClick}
                    onConnect={onConnect}
                    onReconnect={onReconnect}
                    onReconnectStart={onReconnectStart}
                    onReconnectEnd={onReconnectEnd}
                    onNodeContextMenu={onNodeContextMenu}
                    nodeTypes={nodeTypes}
                    onPaneClick={onPaneClick}
                    onNodeDragStop={(event, node) => {
                      setSteps(
                        steps.map((step) => {
                          if (step.id == node.id)
                            step.position = node.position
                          return step
                        })
                      )
                    }}
                  >
                    {menu && <NodeContextMenu
                      {...menu} />}
                    <Background />
                  </ReactFlow>
                </div>
                {transitionToEdit && <EditTransition
                  conditionTypes={['constraint', 'clause']}
                  groups={groups}
                  transition={transitionToEdit}
                  onClose={() => setTransitionToEdit(null)}
                  onSave={handleSaveTransition}
                  params={params}
                  clauses={clauses}
                />}
                {stepToEdit && <EditStep
                  groups={groups}
                  step={stepToEdit}
                  onClose={() => setStepToEdit(null)}
                  onSave={handleSaveStep}
                  params={params}
                />}
                <div className="modal-footer">
                  <button className="btn btn-secondary" onClick={addStep}>
                    Add Step
                  </button>
                  <button className="btn btn-primary" onClick={handleSave}>
                    Save Changes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ReactFlowProvider>
  );
};

export default WorkflowSettingsModal;


