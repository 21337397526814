import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "../../../../contexts/TranslationProvider";
import EditFormTemplateContext from "../../../../contexts/EditFormTemplateContext";
import { getAllParams } from '../../../../domain/FormTemplate';
import { predefinedDateFormats, predefinedFormulas, validateFormula } from "../../../../domain/Form";
import ConstraintRender from "./ConstraintRender";
import ParamCombobox from "../../../ContractTemplate/components/ParamCombobox";
import { FormType } from "../../../../domain/types/FormParams";
import { FormTemplatePageEntity } from "../../../../domain/entities";

function DateRender({
  children,
  param,
  isEditing,
  onChangeFormat,
  onChangeFormula,
  onChangeConstraint,
}: {
  children: React.JSX.Element;
  page: FormTemplatePageEntity;
  param: FormType.AnyParam;
  isEditing: boolean;
  onChangeFormat: (value: string) => void;
  onChangeFormula: React.ChangeEventHandler<HTMLTextAreaElement>;
  onChangeConstraint: (constraints: FormType.Constraint[]) => any;
}) {
  const { formTemplate, onParamTypeChange } = useContext(EditFormTemplateContext);

  const { t, language } = useTranslation();
  const translationPath = t("pages.editionFormTemplate.edition");
  const [filteredParams, setFilteredParams] = useState([]);
  const [filteredFormulas, setFilteredFormulas] = useState([]);
  const [selectedFormat, setSelectedFormat] = useState(param.format);
  const [formatOptions, setFormatOptions] = useState(predefinedDateFormats);

  useEffect(() => {

    setFormatOptions(predefinedDateFormats);
  }, [param.type]);

  useEffect(() => {
    const filteredParams = otherParams.filter((p) =>
      p.name.toLowerCase().includes(formulaInput?.toLowerCase())
    );
    const filteredFormulas = predefinedFormulas.filter((f) =>
      f.toLowerCase().includes(formulaInput?.toLowerCase())
    );
    setFilteredParams(filteredParams);
    setFilteredFormulas(filteredFormulas);
  }, []);
  const [formulaInput, setFormulaInput] = useState(param.formula ?? "");
  const [showDropDown, setShowDropDown] = useState(false);
  const params = getAllParams(formTemplate);
  const otherParams = useMemo(() => getAllParams(formTemplate)
    .filter(p => ['number', 'boolean', 'enum', 'date'].includes(p.type))
    .filter(p => p.name !== param.name), [formTemplate]);

  const unvalidFormula = useMemo(
    () => param.formula && !validateFormula(param.formula, otherParams),
    [param?.formula, otherParams]
  );
  const formulaOperators = ["+", "-", "*", "/", ",", "(", ")", ":", "?"];
  const formatOperators = ["+", "-", "*", "/", ",", "(", ")", ":", "?"];
  const handleFormulaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    console.log(getAllParams(formTemplate))
    setFormulaInput(value);
    onChangeFormula(e);

    const lastPart = value
      .split(
        new RegExp(`[${formulaOperators.map((op) => "\\" + op).join("")}]`)
      )
      .pop()
      ?.trim();
    if (lastPart) {
      setFilteredParams(
        otherParams.filter((p) =>
          p.name.toLowerCase().includes(lastPart.toLowerCase())
        )
      );
      setFilteredFormulas(
        predefinedFormulas.filter((f) =>
          f.toLowerCase().includes(lastPart.toLowerCase())
        )
      );
    }
    console.log(lastPart)
    setShowDropDown(lastPart && lastPart.length > 0);
  };
  const handleFormatChange = (event) => {
    const format = event.target.value;
    const formatted = `${format}(${param.name})`;
    setSelectedFormat(formatted);
    onChangeFormat(formatted);
  };
  useEffect(() => {
    if (selectedFormat) {
      setSelectedFormat(prevFormat => {
        const newFormat = prevFormat.replace(/\(([^)]+)\)/, `(${param.name})`);
        onChangeFormat(newFormat);
        return newFormat;
      });
    }
  }, [param.name]);
  const replaceLastPart = (selectedValue: string) => {
    const regex = new RegExp(`([^${formulaOperators.join("\\")}]+)$`);
    const newFormula = formulaInput.replace(regex, selectedValue);
    handleFormulaChange({ target: { value: newFormula } } as any);
    setShowDropDown(false);
  };
  return (
    <div className={`input-param`}>
      {/* <div className="input-type">{t(`${translationTypes}.${param.type}`)}</div> */}
      <ParamCombobox param={param} onChangeParamType={(type) => onParamTypeChange(param.name, type as any)} />
      {children}
      {isEditing ? (
        <div style={{ position: "relative", width: "95%" }}>
          <select
            style={{ width: "100%", borderRadius: "8px", border: "1px solid #E0E0E1" }}
            className="custom-form-select"
            value={selectedFormat || ""}
            onChange={handleFormatChange}
          >
            <option value={selectedFormat} disabled>{selectedFormat}</option>
            {formatOptions?.map((format, idx) => (
              <option key={idx} value={format}>{format}</option>
            ))}
          </select>
        </div>
      ) : (
        <div style={{ justifyContent: language === "ar" ? "right" : "left" }} className="custom-form-label">
          {translationPath.parameterFormat} : {param.format}
        </div>
      )}
      {param.type === 'date' && (
        <>
          <ConstraintRender constraints={param.constraints} isEditing={isEditing} param={param} onChangeConstraint={onChangeConstraint} params={params} />
        </>
      )}
      {param.type === "string" && (
        <>
          {isEditing ? (
            <div style={{ position: "relative" }}>
              <textarea
                className="custom-form-textarea formula mt-2"
                value={formulaInput ? formulaInput : param.formula ?? ""}
                onChange={handleFormulaChange}
              />
              {showDropDown &&
                (filteredParams.length > 0 || filteredFormulas.length > 0) && (
                  <ul className="dropdown-list-formula">
                    {filteredParams.map((p, idx) => (
                      <li
                        key={idx}
                        onClick={() => {
                          replaceLastPart(p.name);
                          setShowDropDown(false);
                        }}
                      >
                        {p.name}
                      </li>
                    ))}
                    {filteredFormulas.map((f, idx) => (
                      <li
                        key={idx}
                        onClick={() => {
                          replaceLastPart(f);
                          setShowDropDown(false);
                        }}
                      >
                        {f}
                      </li>
                    ))}
                  </ul>
                )}
              {unvalidFormula && (
                <div className="error-message-dialog">
                  {translationPath.unvalidFormula}
                </div>
              )}
            </div>
          ) : (
            <div
              style={{ justifyContent: language === "ar" ? "right" : "left" }}
              className="custom-form-label"
            >
              {translationPath.parameterFormula} : {param.formula ?? ""}
            </div>
          )}
          <div>
            <ConstraintRender constraints={param.constraints} isEditing={isEditing} param={param} onChangeConstraint={onChangeConstraint} params={params} />
          </div>
        </>
      )}
    </div>
  );
}

export default DateRender;
