import { useTranslation } from "../../../contexts/TranslationProvider";

function ReturnToSummaryButton({ onClose }: { onClose: () => void }) {
  const { t } = useTranslation();
  function handleClick(): void {
    onClose();
  }

  return (
    <div className="return-to-summary" onClick={() => handleClick()}>
      {t("contractCompliancePage.buttons.returnToSummary")}
    </div>
  );
}

export default ReturnToSummaryButton;
