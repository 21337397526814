import React, { useMemo, useState, useContext, useEffect } from 'react';
import { useTranslation } from "../../../../contexts/TranslationProvider";
import { FormParam, FormType } from '../../../../domain/types/FormParams';
import { predefinedFormulas, predefinedNumberFormats, validateFormula } from '../../../../domain/Form';
import EditFormTemplateContext from '../../../../contexts/EditFormTemplateContext';
import { getAllParams } from '../../../../domain/FormTemplate';
import ConstraintRender from './ConstraintRender';
import ParamCombobox from '../../../ContractTemplate/components/ParamCombobox';
import { FormTemplatePageEntity } from '../../../../domain/entities/FormTemplatePage';

function NumberRender({
  children,
  param,
  isEditing,
  onChangeFormula,
  onChangeConstraint,
  onChangeFormat,
}: {
  children: React.JSX.Element;
  param: FormType.NumberParam;
  isEditing: boolean;
  onChangeFormat: (value: string) => void;
  onChangeFormula: React.ChangeEventHandler<HTMLTextAreaElement>;
  onChangeConstraint: (constraints: FormType.Constraint[]) => any;
}) {
  const { t, language } = useTranslation();
  const translationPath = t("pages.editionFormTemplate.edition");
  const { formTemplate, onParamTypeChange, onParamLabelChange } = useContext(EditFormTemplateContext);
  const params = getAllParams(formTemplate);
  const [filteredFormulaParams, setFilteredFormulaParams] = useState([]);
  const [filteredFormulas, setFilteredFormulas] = useState([]);
  const [filteredConstraints, setFilteredConstraints] = useState([]);
  useEffect(() => {
    const filteredFormulaParams = otherParams.filter(p => p.name.toLowerCase().includes(formulaInput?.toLowerCase()));
    const filteredFormulas = predefinedFormulas.filter(f => f.toLowerCase().includes(formulaInput?.toLowerCase()));
    setFilteredFormulaParams(filteredFormulaParams);
    setFilteredFormulas(filteredFormulas);
    setFilteredConstraints(filteredConstraints);
  }, []);
  const [formulaInput, setFormulaInput] = useState(param.formula ?? "");
  const [showFormulaDropDown, setShowFormulaDropDown] = useState(false);
  const [selectedFormat, setSelectedFormat] = useState(param.format);

  const otherParams = useMemo(() => getAllParams(formTemplate)
    .filter(p => ['number', 'boolean', 'enum', 'date'].includes(p.type))
    .filter(p => p.name !== param.name), [formTemplate]);

  const unvalidFormula = useMemo(() => param.formula && !validateFormula(param.formula, otherParams), [param?.formula, otherParams]);
  const formulaOperators = ['+', '-', '*', '/', ',', '(', ')', ':', '?'];
  const handleFormulaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    setFormulaInput(value);
    onChangeFormula(e);

    const lastPart = value.split(new RegExp(`[${formulaOperators.map(op => '\\' + op).join('')}]`)).pop()?.trim();
    if (lastPart) {
      setFilteredFormulaParams(otherParams.filter(p => p.name.toLowerCase().includes(lastPart.toLowerCase())));
      setFilteredFormulas(predefinedFormulas.filter(f => f.toLowerCase().includes(lastPart.toLowerCase())));
    }

    setShowFormulaDropDown(lastPart && lastPart.length > 0);
  };

  const replaceLastPart = (selectedValue: string, operators: string[], input: string, onClose: () => void) => {
    const regex = new RegExp(`([^${operators.join('\\')}]+)$`);
    const newFormula = input.replace(regex, selectedValue);
    setFormulaInput(newFormula);
    onClose();
  };
  const [formatOptions, setFormatOptions] = useState(predefinedNumberFormats);
  useEffect(() => {

    setFormatOptions(predefinedNumberFormats);
  }, [param.type]);
  const handleFormatChange = (event) => {
    const format = event.target.value;
    const formatted = `${format}(${param.name})`;
    setSelectedFormat(formatted);
    onChangeFormat(formatted);
  };
  useEffect(() => {
    if (selectedFormat) {
      setSelectedFormat(prevFormat => {
        const newFormat = prevFormat.replace(/\(([^)]+)\)/, `(${param.name})`);
        onChangeFormat(newFormat);
        return newFormat;
      });
    }
  }, [param.name]);
  return (
    <div className={`input-param`}>
      <ParamCombobox param={param} onChangeParamType={(type) => onParamTypeChange(param.name, type as any)} />
      {children}
      <div>
        {isEditing ? (
          <div style={{ position: "relative", width: "95%" }}>
            <select
              style={{ width: "100%", borderRadius: "8px", border: "1px solid #E0E0E1" }}
              className="custom-form-select"
              value={selectedFormat || ""}
              onChange={handleFormatChange}
            >
              <option value={selectedFormat} disabled>{selectedFormat}</option>
              {formatOptions?.map((format, idx) => (
                <option key={idx} value={format}>{format}</option>
              ))}
            </select>
          </div>
        ) : (
          <div style={{ justifyContent: language === "ar" ? "right" : "left" }} className="custom-form-label">
            {translationPath.parameterFormat} : {param.format}
          </div>
        )}
      </div>
      <div>
        {isEditing ? (
          <div style={{ position: "relative" }}>
            <textarea
              className="custom-form-textarea"
              value={formulaInput ? formulaInput : param.formula ?? ""}
              onChange={handleFormulaChange}
              placeholder={translationPath.parameterFormula}
            />
            {showFormulaDropDown && (filteredFormulaParams.length > 0 || filteredFormulas.length > 0) && (
              <ul className="dropdown-list-formula">
                {filteredFormulaParams.map((p, idx) => (
                  <li key={idx} onClick={() => { replaceLastPart(p.name, formulaOperators, formulaInput, () => setShowFormulaDropDown(false)); setShowFormulaDropDown(false); }}>
                    {p.name}
                  </li>
                ))}
                {filteredFormulas.map((f, idx) => (
                  <li key={idx} onClick={() => { replaceLastPart(f, formulaOperators, formulaInput, () => setShowFormulaDropDown(false)); setShowFormulaDropDown(false); }}>
                    {f}
                  </li>
                ))}
              </ul>
            )}
            {unvalidFormula && <div className="error-message-dialog">{translationPath.unvalidFormula}</div>}
          </div>
        ) : (
          <div style={{ justifyContent: language === "ar" ? "right" : "left" }} className="custom-form-label">
            {translationPath.parameterFormula} : {param.formula ?? ""}
          </div>
        )}
      </div>
      <div>
        <ConstraintRender constraints={param.constraints} isEditing={isEditing} param={param} onChangeConstraint={onChangeConstraint} params={params} />
      </div>
    </div>
  );
}

export default NumberRender;
