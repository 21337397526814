import React, { useContext, useEffect, useMemo, useState } from 'react'
import ParamTypeCombobox from '../../../components/common/CustomCombobox/ParamTypeCombobox'
import "./addParamForm.scss"
import { useTranslation } from '../../../contexts/TranslationProvider'
import { FormParam } from '../../../domain/types/FormParams'
import { getAllParams } from '../../../domain/FormTemplate'
import EditFormTemplateContext from '../../../contexts/EditFormTemplateContext'

function AddParamForm({ handleAdd, handleCancel, params }: {
  handleAdd: (param: FormParam) => void;
  handleCancel: () => void;
  params: FormParam[];
}) {
  const { formTemplate } = useContext(EditFormTemplateContext);

  const [paramType, setParamType] = useState<FormParam['type']>(null)
  const [paramName, setParamName] = useState<FormParam['name']>("")
  const [paramLabel, setParamLabel] = useState<FormParam['label']>("")

  const { t } = useTranslation()
  const translationPath = t("pages.contractTemplateParam.addParamForm");
  const [errors, setErrors] = useState([])
  const [warnings, setWarnings] = useState([])

  const allParams = useMemo(() => getAllParams(formTemplate), [formTemplate])

  useEffect(() => {
    const newErrors = [];
    const newWarnings = []
    if (paramName && !/^[a-z_][a-z0-9_]*$/i.test(paramName)) {
      newErrors.push({ where: "name", why: translationPath.errors.nameWrong });
    }

    const existingParam = allParams.find(p => p.name === paramName);
    if (existingParam) {
      if (existingParam.type !== paramType) {
        newErrors.push({ where: "name", why: translationPath.errors.nameExists });
      }
      else {
        newWarnings.push({ where: "name", why: translationPath.errors.nameExists });
      }
    }

    setErrors(newErrors);
    setWarnings(newWarnings);

    return () => {
    }
  }, [paramName, paramType, paramLabel])

  const handleConfirm = () => {
    if (errors.length > 0 || !paramType || !paramName || !paramLabel) {
      return
    }
    handleAdd({ type: paramType, name: paramName, label: paramLabel } as any)
    setParamType(null)
    setParamName("")
    setParamLabel("")
    handleCancel()
  }
  const handleTypeChange = (value) => {
    setParamType(value);
  }
  const handleNameChange = (e) => {
    setParamName(e.target.value);
  }
  const handleLabelChange = (e) => {
    setParamLabel(e.target.value);
  }
  return (
    <div
      className="dialog-container"
      style={{
        width: "100%"
      }}>
      <div className="dialog-input-container">
        <div className="dialog-input-label">
          {translationPath.type}
        </div>
        <ParamTypeCombobox
          options={['string', 'number', 'date', 'enum', 'boolean', 'list', 'file', 'beneficial', 'beneficial[]', 'table', 'property']}
          value={paramType}
          onChange={handleTypeChange}
        />
        {
          errors.map(err => {
            if (err.where === "type") {
              return <div className="error-message-dialog">{err.why}</div>
            }
          })
        }
      </div>

      <div className="dialog-input-container">
        <div className="dialog-input-label">
          {translationPath.name}
        </div>
        <input className="dialog-input" type="text" value={paramName} onChange={(e) => handleNameChange(e)} />
        {
          errors.map((err, idx) => {
            if (err.where === "name") {
              return <div key={idx} className="error-message-dialog">{err.why}</div>
            }
          })
        }
        {
          warnings.map((warn, idx) => {
            if (warn.where === "name") {
              return <div key={idx} className="warning-message-dialog">{warn.why}</div>
            }
          })
        }
      </div>

      <div className="dialog-input-container">
        <div className="dialog-input-label">
          {translationPath.label}
        </div>
        <input className="dialog-input" type="text" value={paramLabel} onChange={(e) => handleLabelChange(e)} />
        {
          errors.map((err, idx) => {
            if (err.where === "label") {
              return <div key={idx} className="error-message-dialog">{err.why}</div>
            }
          })
        }
      </div>
      <div className="dialog-buttons">
        <button className="dialog-button" onClick={handleCancel}>
          {translationPath.cancel}
        </button>
        <button
          className={`dialog-button ${errors.length > 0 || !paramType || !paramName || !paramLabel ? "dialog-button-disabled" : ""}`}
          disabled={errors.length > 0 || !paramType || !paramName || !paramLabel}
          onClick={handleConfirm}>

          {translationPath.confirm}
        </button>
      </div>

    </div>
  )
}

export default AddParamForm