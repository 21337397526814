import { AxiosInstance } from "axios";
import { ModelClient } from "../ModelClient";
import { RessourceEntity } from "../../domain/entities/Ressource";




export class RessourceClient extends ModelClient<RessourceEntity> {

  constructor(apiClient: AxiosInstance) {
    super(apiClient, 'Ressource')
  }
};

