import { useNavigate } from "react-router-dom";
import RessourceCard from "../../components/common/newDesign/RessourceCard";
import useApiClientWithLoading from "../../services/api/ApiClient";
import { ContractClient } from "../../services/api/ContractClient";
import { FormClient } from "../../services/api/FormClient";
import { DocumentClient } from "../../services/api/DocumentClient";
import ConfirmDeletePopup from "../../components/modals/ConfirmDetelePopup";
import { useState } from "react";
import { useTranslation } from "../../contexts/TranslationProvider";
import { RessourceEntity } from "../../domain/entities/Ressource";
import { RessourceScoringClient } from "../../services/api/RessourceScoring";

function RessourcesList({
  ressources,
  reloadRessources,
}: {
  ressources: RessourceEntity[];
  reloadRessources: () => Promise<void>;
}) {
  const navigate = useNavigate();
  const [showConfirmDelete, setConfirmDelete] = useState(false);
  const { t } = useTranslation();
  const [ressourceToHandle, setRessourceToHandle] =
    useState<RessourceEntity | null>(null);

  const handleClick = (ressource: RessourceEntity) => {
    switch (ressource.type) {
      case "Form":
        return navigate(`/edit-form/${ressource.formId}`);
      case "Contract":
        return navigate(`/edition-contrat/${ressource.contractId}`);
      case "RessourceScoring":
        return navigate(`/contract-compliance/${ressource.ressourceScoringId}`); 
      case "Document":
        if (ressource.level1Id == null)  {
          return navigate(`/document/${ressource.documentId}`);
        } else {
          return navigate(`/document-summary/${ressource.documentId}`);
        }
    }
  };
  const apiClient = useApiClientWithLoading();
  const handleDelete = async (ressource: RessourceEntity) => {
    const contractClient = new ContractClient(apiClient);
    const documentClient = new DocumentClient(apiClient);
    const formClient = new FormClient(apiClient);
    const ressourceScoringClient = new RessourceScoringClient(apiClient);
    let deleted = false;
    switch (ressource.type) {
      case "Contract":
        ({ deleted } = await contractClient.delete(ressource.contractId));
        break;
      case "Form":
        ({ deleted } = await formClient.delete(ressource.formId));
        break;
      case "RessourceScoring":
        ({ deleted } = await ressourceScoringClient.delete(ressource.ressourceScoringId));
      break;  
      case 'Document':
        ({ deleted } = await documentClient.delete(ressource.documentId));  
    }

    if (deleted) {
      await reloadRessources();
    }
  };

  return (
    <section id="contracts-list" className="contracts-list">
      <div className="ressources-list-container">
        {ressources.map((ressource) => (
          <RessourceCard
            key={ressource.id + "-" + ressource.type}
            data={ressource}
            onDelete={() => {
              setRessourceToHandle(ressource);
              setConfirmDelete(true);
            }}
            onClick={() => handleClick(ressource)}
          />
        ))}
      </div>
      {showConfirmDelete && ressourceToHandle && (
        <ConfirmDeletePopup
          msgDelete={t("pages.contracts.msgDeleteRessource")}
          onClose={() => {
            setConfirmDelete(false);
            setRessourceToHandle(null);
          }}
          entityToDelete={ressourceToHandle?.name || ""}
          onDelete={() => handleDelete(ressourceToHandle)}
        />
      )}
    </section>
  );
}

export default RessourcesList;
