import React, { useEffect, useState, useContext, useMemo } from "react";
import useApiClientWithLoading from "../../../services/api/ApiClient";
import { toast } from "react-toastify";
import Loading from "../../common/Loading";
import { intersectionByKey } from "../../../utils/array";
import CustomCombobox from "../../common/CustomCombobox";
import { useNavigate } from "react-router-dom";
import { getUserGroups } from "../../../services/api/UserAuth";
import InputValuesContext from "../../../contexts/InputValuesContext";
import { useParamProcessing } from "../../../hooks/useParamProcessing";
import { useSubmitContract } from "../../../hooks/useSubmitContract";
import { handleContractSaveLogic } from "../SaveDraftModal";
import { GroupEntity, UserEntity, ValidateReq } from "../../../domain/entities";
import { useTranslation } from "../../../contexts/TranslationProvider";
import { GroupClient } from "../../../services/api/GroupClient";
import { UserClient } from "../../../services/api/UserClient";
import { ContractClient } from "../../../services/api/ContractClient";
import { getName } from "../../../helpers/Translation";
import { getAllParams } from "../../../domain/ContractTemplate";
import { areConditionsMet } from "../../../domain/Group";
import { WorkflowStep, WorkflowTransitionCondition } from "../../../domain/Workflow";
import { calculateCompletionPercentage } from "../../../helpers/CompletionPercentage";
import { statuses } from "../../../domain/types/status";

const { default: WorkflowStepSvg } = require('../../../assets/workflow_step.svg');
const { default: WorkflowStepCurrentSvg } = require('../../../assets/workflow_step_current.svg');
const { default: WorkflowStepTargetSvg } = require('../../../assets/workflow_step_target.svg');
const { default: ActiveStepSvg } = require('../../../assets/active_step.svg');
const { default: InactiveStepSvg } = require('../../../assets/inactive_step.svg');

interface ValidateContractModalProps {
  onClose: () => void;
}

const ValidateContractWorkflowModal: React.FC<ValidateContractModalProps> = ({ onClose }) => {
  const {
    contract,
    contractTemplate,
    inputValues,
    segmentsOverrides,
    commentsOverrides,
    additionalClauses,
    excludedClauses,
    excludedSubClauses,
    templateId,
    completionPercentage,
  } = useContext(InputValuesContext);
  const processParamValues = useParamProcessing();
  const { t, language } = useTranslation();
  const submitContract = useSubmitContract(null);
  const contractId = contract?.id;
  const allSteps = contractTemplate?.groupsGraph?.steps?.filter(s => s.id) ?? []


  const [validationData, setValidationData] = useState<ValidateReq>({
    stepsId: contract?.stepsId ?? [],
    validateGroupId: contract?.assignedToGroupId,
    assignToGroupId: undefined,
    assignToUserId: undefined,
  });
  const [targetStepId, setTargetStepId] = useState<WorkflowStep['id']>(null)

  const contractSteps = contract?.stepsId?.map(id => allSteps?.find(s => s.id == id)).filter(Boolean) ?? []

  //excluding the target
  const validationSteps = useMemo(() => validationData.stepsId?.map(id => allSteps?.find(s => s.id == id)).filter(Boolean) ?? [], [validationData])
  const currentStep = validationSteps[validationSteps.length - 1]


  const targetStep = useMemo(() => allSteps?.find(s => s.id == targetStepId), [targetStepId])


  const initialStep = allSteps?.find(s => {
    const transitionsTo = contractTemplate?.groupsGraph.transitions.find(t => t.toId == s.id)
    return !transitionsTo
  })

  const targetSteps = useMemo(() => {
    if (!currentStep) {
      return [initialStep]
    }
    return allSteps?.filter(s => contractTemplate?.groupsGraph?.transitions.find(t => t.fromId == currentStep.id && t.toId == s?.id))
  }, [currentStep])


  useEffect(() => {
    setValidationData((prev) => ({
      ...prev,
      assignToGroupId: targetStep?.assignGroupId || null,
      validateGroupId: targetStep?.validateGroupId || null,
    }))
    return () => {
    }
  }, [targetStep])

  const [invalidData, setInvalidData] = useState(false);


  const [userGroups, setUserGroups] = useState<GroupEntity[]>([]);
  const [openCombobox, setOpenCombobox] = useState<'destination' | 'source' | 'assignee' | 'step' | null>(null);


  const validateGroupOptions: GroupEntity[] = useMemo(() => {
    if (targetStep) {
      return contractTemplate?.groups?.filter(g => targetStep.validateGroupId == g.id) ?? []
    }
    return intersectionByKey(contractTemplate?.groups ?? [], userGroups, "id")
  }, [contractTemplate, targetStep, userGroups]);

  const assignToGroupOptions = useMemo(() => {
    if (targetStep) {
      return contractTemplate?.groups?.filter(g => targetStep.assignGroupId == g.id)
    }
    return contractTemplate?.groups?.filter(g => contractSteps.find(s => s.assignGroupId == g.id)) ?? []
  }, [contractTemplate, targetStep, contractSteps]);

  const [assignToUserOptions, setAssignToUserOptions] = useState<UserEntity[]>([]);

  const currentValidateGroupOption = validateGroupOptions.find(
    (o) => o.id == validationData.validateGroupId
  );

  const currentAssignToGroupOption = contractTemplate?.groups.find(
    (o) => o.id == validationData.assignToGroupId
  );
  const currentAssignToUserOption = assignToUserOptions.find(
    (o) => o.id == validationData.assignToUserId
  );

  const navigate = useNavigate();

  const params = useMemo(() => getAllParams(contractTemplate), [contractTemplate])

  const toBeValidatedGroup = useMemo(() => {
    return [...contract?.validationGroups ?? [], contractTemplate?.groups?.find(g => g.id == validationData.validateGroupId)].filter(Boolean)
  }, [contract?.validationGroups, validationData.validateGroupId])

  const canValidateGroup = (group: GroupEntity): boolean => {
    if (targetStep)
      return group?.id == targetStep.validateGroupId
    return false
  }

  const unmetConditions: WorkflowTransitionCondition[] = useMemo(() => {
    let result: WorkflowTransitionCondition[] = []
    if (!currentStep)
      return []
    if (!targetStep)
      return []
    if (currentStep.validateGroupId && !userGroups.find(g => g.id == currentStep.validateGroupId)) {
      return [{
        type: 'validation',
        groupId: currentStep.validateGroupId,
        requirementId: null
      }]
    }
    const groupsGraph = contractTemplate?.groupsGraph

    const transition = groupsGraph?.transitions?.find(transition => transition.fromId == currentStep.id && transition.toId == targetStep.id)
    if (transition) {
      result = areConditionsMet(transition.conditions, {
        status: contract?.status,
        validatedGroups: toBeValidatedGroup,
        values: inputValues,
        params,
        clauses: contractTemplate?.clauses ?? [],
      })
    }
    const targetStatusIndex = statuses.findIndex(s => s === targetStep.status)
    const doneStatusIndex = statuses.findIndex(s => s === 'Done')
    if (targetStatusIndex >= doneStatusIndex && completionPercentage !== 100) {
      result.push({
        type: 'completion',
        requirementId: null
      })
    }
    return result

  }, [validationData])



  const [loading, setLoading] = useState(false);
  const apiClient = useApiClientWithLoading();
  useEffect(() => {
    getUserGroups(apiClient).then(({ rows }) => {
      setUserGroups(rows);
      if (validationData.validateGroupId && !rows.find(g => g.id == validationData.validateGroupId)) {
        setValidationData((prev) => {
          return {
            ...prev,
            validateGroupId: null,
          }
        })
      }
    });
  }, []);

  const contractClient = new ContractClient(apiClient)
  const groupClient = new GroupClient(apiClient)
  const userClient = new UserClient(apiClient)

  useEffect(() => {
    setAssignToUserOptions([]);
    if (validationData.assignToGroupId) {
      groupClient.getUsers(validationData.assignToGroupId).then(
        ({ rows }) => {
          setAssignToUserOptions(rows);
          if (rows.find((row) => row.id == contract?.assignedToUserId))
            setValidationData({
              ...validationData,
              assignToUserId: contract?.assignedToUserId,
            });
          else
            setValidationData({
              ...validationData,
              assignToUserId: null,
            });
          setLoading(false);
        }
      );
    } else
      setAssignToUserOptions([])
  }, [validationData.assignToGroupId]);

  const handleInputChange = (field: keyof ValidateReq) => (value) => {
    setValidationData({
      ...validationData,
      [field]: value,
    });
  };

  const submitValidationData = async () => {
    try {
      if (validationData.assignToGroupId && validationData.assignToUserId && validationData.assignToGroupId) {
        const savedContractId = await handleContractSaveLogic(
          inputValues,
          processParamValues,
          submitContract,
          setLoading,
          templateId,
          segmentsOverrides,
          commentsOverrides,
          additionalClauses,
          excludedSubClauses,
          excludedClauses,
          completionPercentage,
          contractId
        );
        setLoading(true);
        if (targetStepId)
          validationData.stepsId.push(targetStepId)
        await contractClient.validate(savedContractId, validationData);
        setLoading(false);
        toast.success(t("modals.validateContract.messages.success"));
        navigate("/contrats");
        onClose();
      }
      else {
        setInvalidData(true)
      }
    } catch (error) {
      setLoading(false);
      toast.error(t("modals.validateContract.messages.error"));
      console.error(error);
      onClose();
    }
  };

  useEffect(() => {
    setInvalidData(false)
  }, [validationData])


  const handleSelectStep = (stepId: WorkflowStep['id']) => {
    const validationStepIndex = validationData.stepsId.findIndex(id => id === stepId)
    const contractStepIndex = contract.stepsId?.findIndex(id => id === stepId)
    if (contractStepIndex > -1) {
      validationData.stepsId = contract.stepsId?.slice(0, contractStepIndex + 1)
      setTargetStepId(null)
      setValidationData({
        ...validationData,
      })
    } else if (validationStepIndex > -1) {
      validationData.stepsId = validationData.stepsId.slice(0, validationStepIndex + 1)
      setTargetStepId(null)
      setValidationData({
        ...validationData,
      })

    } else if (targetStepId == stepId) {
      setTargetStepId(null)
    } else if (targetSteps.find(s => s.id === stepId)) {
      setTargetStepId(stepId)
    } else {
      console.warn({
        validationSteps,
        contractSteps,
        stepId
      });
    }
  }

  return (
    <>
      <div
        className="modal-backdrop"
      ></div>
      <div id="contractz-lab">
        <div className="modal d-flex justify-content-center align-items-center">
          <div>
            <div className="modal-content" style={{ width: "960px" }}>
              <div className="modal-header">
                <h5 className="modal-title">{t("modals.validateContract.title")}</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={onClose}
                  aria-label="Close"
                  style={{ marginRight: language == "ar" && "88%" }}
                ></button>
              </div>
              {loading ? (
                <Loading height="50vh" />
              ) : (
                <div className="modal-body" style={{ paddingBottom: "5%" }}>
                  <div className="form-group validate">

                    <div className={`audit-workflow-steps  ${language == "ar" && "audit-workflow-steps-arabic"} `}>
                      <div className="content-steps">
                        {validationSteps.map((step, index) => (
                          <div key={index} className="edit-audit-step">
                            <div className="audit-step">
                              <img
                                src={
                                  step.id == currentStep?.id && WorkflowStepCurrentSvg
                                  || WorkflowStepSvg
                                }
                                height="24px"
                                width="auto"
                                alt=""
                                onClick={() => handleSelectStep(step.id)}

                              />
                              <p className="step-name">{step.name}</p>
                            </div>
                            {index < validationSteps.length - 1 && (
                              <img
                                src={InactiveStepSvg}
                                className={`edit-audit-step-line  ${language == "ar" && "edit-audit-step-line-arabic"}`}
                              />
                            )}
                          </div>
                        ))}
                        <div className="edit-audit-step">

                          {[...targetSteps]?.map((step, index) => (
                            <div key={step.id} className="audit-step">
                              <img
                                src={
                                  step.id == targetStep?.id && WorkflowStepTargetSvg
                                  || WorkflowStepSvg
                                }
                                height="24px"
                                width="auto"
                                alt=""
                                // onClick={() => setTargetStepId(prev => prev == step.id ? null : step.id)}
                                onClick={() => handleSelectStep(step.id)}

                              />
                              <p className="step-name">{step.name}</p>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="form-group validate">
                      <div className="form-floating mb-3">
                        <CustomCombobox
                          isValid={(invalidData && !validationData.assignToGroupId) ? false : true}
                          label={t("modals.validateContract.destination")}
                          onDropdownOpen={() => { }}
                          options={assignToGroupOptions}
                          value={currentAssignToGroupOption}
                          onChange={handleInputChange("assignToGroupId")}
                          optionValue={(option) => option?.id || null}
                          optionDisplay={(option) => getName(option, language)}
                          isOpen={!targetStep && openCombobox === "destination"}
                          setIsOpen={(isOpen) => !targetStep && setOpenCombobox(isOpen ? "destination" : null)}
                        />
                      </div>
                      <div className="form-floating mb-3"
                        style={{
                          visibility: targetStep?.validateGroupId ? 'visible' : 'hidden'
                        }}
                      >
                        <CustomCombobox
                          canDeselect={!targetStep}
                          label={t("modals.validateContract.source")}
                          onDropdownOpen={() => { }}
                          options={validateGroupOptions}
                          value={currentValidateGroupOption}
                          onChange={handleInputChange("validateGroupId")}
                          optionValue={(option) => option?.id || null}
                          optionDisplay={(option) => getName(option, language)}
                          optionIsEnabled={(option) =>
                            canValidateGroup(option)
                          }
                          isOpen={openCombobox === "source"}
                          setIsOpen={(isOpen) => !targetStep && setOpenCombobox(isOpen ? "source" : null)}
                        />
                      </div>
                      <div className="form-floating mb-3">
                        <CustomCombobox
                          isValid={(invalidData && !validationData.assignToUserId) ? false : true}
                          label={t("modals.validateContract.assignee")}
                          onDropdownOpen={() => { }}
                          options={assignToUserOptions}
                          value={currentAssignToUserOption}
                          onChange={handleInputChange("assignToUserId")}
                          optionValue={(option) => option?.id || null}
                          optionDisplay={(option: UserEntity) => option?.fullName}
                          isOpen={openCombobox === "assignee"}
                          setIsOpen={(isOpen) => setOpenCombobox(isOpen ? "assignee" : null)}
                        />
                      </div>
                    </div>
                  </div>
                  {
                    unmetConditions.length > 0 &&
                    <div>
                      {unmetConditions.map((condition) => {
                        let message = ""
                        switch (condition.type) {
                          case 'validation':
                            const group = contractTemplate?.groups.find(g => g.id == condition.groupId)
                            message = `Group '${group?.name}' must be validated`
                            break;
                          case 'status':
                            message = `Contract must have been in status '${condition.status}'`
                            break;
                          case 'constraint':
                            message = condition.label
                            break;
                          case 'clause':
                            const clause = contractTemplate?.clauses.find(c => c.id == condition.clauseId)
                            message = `Clause '${clause.name}' must be completed`
                            break;
                          case 'completion':
                            message = `Contract must be 100% completed`
                            break;
                          default:
                            break;
                        }
                        return (
                          <div className="error-message-dialog">
                            {message}
                          </div>
                        )
                      })}
                    </div>
                  }
                </div >
              )}
              <div className="modal-footer">
                <button
                  disabled={unmetConditions.length > 0}
                  type="submit"
                  className="btn btn-primary submit-validate-contract-btn"
                  onClick={submitValidationData}
                >
                  {t("modals.validateContract.validate")}
                </button>
              </div>
            </div >
          </div >
        </div >
      </div >
    </>
  );
}

export default ValidateContractWorkflowModal;
