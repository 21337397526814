import { ClauseScoringEntity } from "../../../domain/entities";
import { RenderSegments, SegmentedClauseParam, SegmentedClauseParams, SegmentedText, SegmentedTextType } from "../../../domain/types/ClauseParams";
import _ from 'lodash'

export function idfySegmentedText(segmentedText: ClauseScoringEntity['segmentation']['segmentedText'], genId: () => string): ClauseScoringEntity['segmentation']['segmentedText'] {
  return segmentedText.map((segment, idx) => {
    return [genId(), segment[1], segment[2], segment[3]]
  })
}
export function styleSegmentation(oldSegmentation: ClauseScoringEntity['segmentation'], styledTextSegmentation: ClauseScoringEntity['segmentation']['segmentedText'], deletedSegments: RenderSegments): ClauseScoringEntity['segmentation'] {
  let segmentedParams: SegmentedClauseParams = [];
  let segmentedText: SegmentedText = [];
  oldSegmentation.segmentedParams.forEach((paramSegment, idx) => {
    switch (paramSegment.type) {
      case 'boolean':
        return segmentedParams.push(
          {
            ...paramSegment,
            type: 'boolean',
            args: {
              textIfFalse: styleSegmentation({ segmentedText: paramSegment.args?.textIfFalse, segmentedParams: [] }, styledTextSegmentation, deletedSegments).segmentedText,
              textIfTrue: styleSegmentation({ segmentedText: paramSegment.args?.textIfTrue, segmentedParams: [] }, styledTextSegmentation, deletedSegments).segmentedText
            }
          }
        )
      case 'enum':
        return segmentedParams.push(
          {
            ...paramSegment,
            type: 'enum',
            args: paramSegment.args?.map((arg => {
              return {
                option: arg.option,
                text: styleSegmentation({ segmentedText: arg.text, segmentedParams: [] }, styledTextSegmentation, deletedSegments).segmentedText
              }
            })),
          }
        )
      case 'static-table':
        return segmentedParams.push(
          {
            ...paramSegment,
            type: 'static-table',
            args: {
              ...paramSegment.args,
              cells: paramSegment.args.cells.map(row => row.map(cell => {
                return {
                  ...cell,
                  content: styleSegmentation({ segmentedText: cell.content, segmentedParams: [] }, styledTextSegmentation, deletedSegments).segmentedText,
                }
              })),
            }
          }
        )
      default:
        return segmentedParams.push(paramSegment)
    }
  })
  oldSegmentation.segmentedText.forEach(([segmentId, segmentText, segmentType, segmentStyle], idx) => {

    const styledSegments = styledTextSegmentation
      .map((styledSegment, index) => ({ segment: styledSegment, index })) // Map to an object with segment and index
      .filter(({ segment }) => segment[0] === segmentId); // Filter based on segmentId

    // find the indexes of the firs and last appearences of segmentIs
    if (styledSegments.length > 0 && segmentType === SegmentedTextType.STATIC) {
      styledSegments.forEach((styledSegment) => {
        // check if previous item in styledTextSegmentation is a paragraph start
        if (styledSegment.index > 0 && styledTextSegmentation[styledSegment.index - 1][2] === SegmentedTextType.PARAGRAPH_START) {
          segmentedText.push(["new-paragraph", " ", SegmentedTextType.PARAGRAPH_START, styledTextSegmentation[styledSegment.index - 1][3]])
        }
        // check if previous item in styledTextSegmentation is a list element start
        if (styledSegment.index > 0 && styledTextSegmentation[styledSegment.index - 1][2] === SegmentedTextType.LIST_ITEM_START) {
          segmentedText.push(["new-list-item", " ", SegmentedTextType.LIST_ITEM_START, styledTextSegmentation[styledSegment.index - 1][3]])
        }
        segmentedText.push([segmentId, styledSegment.segment[1], segmentType, styledSegment.segment[3]])
      })
    } else if (segmentType === SegmentedTextType.PARAM && styledSegments.length === 1) {
      segmentedText.push([segmentId, segmentText, segmentType, styledSegments[0].segment[3]])
    }else{
      if (!deletedSegments.find((segment) => segment.id === segmentId)) {
        segmentedText.push([segmentId, segmentText, segmentType, segmentStyle])
      }
    }
  });
  return { segmentedText, segmentedParams }
}
export function idfySegmentation(segmentation: ClauseScoringEntity['segmentation'], genId: () => string): ClauseScoringEntity['segmentation'] {
  segmentation = cleanSegmentation(segmentation.segmentedText, segmentation.segmentedParams)
  if (segmentation.segmentedText.length === 0) {
    segmentation.segmentedText = [['tmp', '_', SegmentedTextType.STATIC]]
  }
  let segmentedText = idfySegmentedText(segmentation.segmentedText, genId)
  let segmentedParams: SegmentedClauseParams = [];
  segmentation.segmentedParams.forEach((paramSegment, idx) => {
    switch (paramSegment.type) {
      case 'boolean':
        return segmentedParams.push(
          {
            ...paramSegment,
            type: 'boolean',
            args: {
              textIfFalse: idfySegmentedText(paramSegment.args?.textIfFalse ?? [], genId),
              textIfTrue: idfySegmentedText(paramSegment.args?.textIfTrue ?? [], genId)
            }
          }
        )
      case 'enum':
        return segmentedParams.push(
          {
            ...paramSegment,
            type: 'enum',
            args: paramSegment.args?.map((arg => {
              return {
                option: arg.option,
                text: idfySegmentedText(arg.text, genId)
              }
            })),
          }
        )
      case 'static-table':
        return segmentedParams.push(
          {
            ...paramSegment,
            type: 'static-table',
            args: {
              ...paramSegment.args,
              cells: paramSegment.args.cells.map(row => row.map(cell => {
                return {
                  ...cell,
                  content: idfySegmentedText(cell.content.length > 0 ? cell.content : [["id", "_", SegmentedTextType.STATIC]], genId),
                }
              })),
            }
          }
        )
      default:
        return segmentedParams.push(paramSegment)
    }
  })
  return { segmentedText, segmentedParams }
}
export function cleanSegmentation(segmentedText: SegmentedText, segmentedParams: SegmentedClauseParam[]): { segmentedText: SegmentedText, segmentedParams: SegmentedClauseParam[] } {
  if (segmentedParams.length) {
    segmentedParams = segmentedParams.map(param => {
      switch (param.type) {
        case 'boolean':
          return {
            ...param,
            args: {
              textIfFalse: cleanSegmentation(param.args?.textIfFalse.length > 0 ? param.args.textIfFalse : [["", "_", SegmentedTextType.STATIC]], []).segmentedText,
              textIfTrue: cleanSegmentation(param.args?.textIfTrue.length > 0 ? param.args.textIfTrue : [["", "_", SegmentedTextType.STATIC]], []).segmentedText,
            },
          }
        case 'enum':
          return {
            ...param,
            args: param.args?.map(arg => {
              return {
                option: arg.option,
                text: cleanSegmentation(arg.text.length > 0 ? arg.text : [["", "_", SegmentedTextType.STATIC]], []).segmentedText
              }
            })
          }
        case 'static-table':
          return {
            ...param,
            args: {
              ...param.args,
              cells: param.args.cells.map(row => row.map(cell => {
                return {
                  ...cell,
                  content: cell.content.length > 0 ? cleanSegmentation(cell.content, []).segmentedText : [["", "_", SegmentedTextType.STATIC]],
                }
              })),

            }
          }
        default:
          return param
      }
    })
  }
  // remove empty segments
  segmentedText = segmentedText.filter(segment => {
    const [segmentId, text, type] = segment
    return text.length > 0 || type === SegmentedTextType.PARAGRAPH_START
  })
  // if first or last segments are not static add static segments
  if (segmentedText.length) {
    const contentSegmentedText = segmentedText.filter(([id, text, type]) => type !== SegmentedTextType.PARAGRAPH_START)
    const firstSegment = contentSegmentedText[0]
    const lastSegment = contentSegmentedText[contentSegmentedText.length - 1]
    if (firstSegment && firstSegment[2] !== SegmentedTextType.STATIC) {
      segmentedText.unshift(["id-first", " ", SegmentedTextType.STATIC])
    }
    if (lastSegment && lastSegment[2] !== SegmentedTextType.STATIC) {
      segmentedText.push(["id-last", " ", SegmentedTextType.STATIC])
    }
    if (contentSegmentedText.length === 0) {
      segmentedText.push(["id-last", " ", SegmentedTextType.STATIC])
    }
  }
  // if two consecutive segments are both params or both paragraph start then add a static segment between them
  const newSegmentedText = [] as SegmentedText
  for (let i = 0; i < segmentedText.length; i++) {
    const currentSegment = segmentedText[i]
    const nextSegment = segmentedText[i + 1]
    newSegmentedText.push(currentSegment)
    if (currentSegment[2] === SegmentedTextType.PARAM && nextSegment && (nextSegment[2] === SegmentedTextType.PARAM || nextSegment[2] === SegmentedTextType.LIST_ITEM_START)) {
      newSegmentedText.push(["id-between", " ", SegmentedTextType.STATIC])
    }
    if(currentSegment[2] === SegmentedTextType.PARAGRAPH_START && nextSegment && nextSegment[2] === SegmentedTextType.PARAGRAPH_START) {
      newSegmentedText.push(["id-between", " ", SegmentedTextType.STATIC])
    }
  }

  // if a paragraph only contains a param add static segments before and after it
  const cleanedSegmentedText = [] as SegmentedText
  for (let i = 0; i < newSegmentedText.length; i++) {
    const currentSegment = newSegmentedText[i]
    const nextSegment = newSegmentedText[i + 1]
    const staticTables = segmentedParams.filter(param => param.type === 'static-table')
    const previousSegment = newSegmentedText[i - 1]
    if (currentSegment[2] === SegmentedTextType.PARAM) {
      if (previousSegment && previousSegment[2] === SegmentedTextType.PARAGRAPH_START) {
        cleanedSegmentedText.push(["id-before", " ", SegmentedTextType.STATIC])
      }
      cleanedSegmentedText.push(currentSegment)
      const isStaticTable = staticTables.find(param => param.name === currentSegment[1])
      if (!isStaticTable && nextSegment && nextSegment[2] === SegmentedTextType.PARAGRAPH_START) {
        cleanedSegmentedText.push(["id-before", " ", SegmentedTextType.STATIC])
      }
    } else {
      cleanedSegmentedText.push(currentSegment)
    }
  }

  // if two consecutive segments are static and share the same styles then merge them
  const mergedSegmentedText = [] as SegmentedText
  for (let i = 0; i < cleanedSegmentedText.length; i++) {
    const currentSegment = cleanedSegmentedText[i]
    const nextSegment = cleanedSegmentedText[i + 1]
    const [segmentId, text, type, style] = currentSegment
    const [nextSegmentId, nextText, nextType, nextStyle] = nextSegment || []
    if (nextSegment
      && type === SegmentedTextType.STATIC
      && nextType === SegmentedTextType.STATIC
      && _.isEqual(style, nextStyle)
    ) {
      mergedSegmentedText.push([segmentId, text + nextText, type, style])
      i++
    } else {
      mergedSegmentedText.push(currentSegment)
    }
  }
  return { segmentedText: mergedSegmentedText, segmentedParams }
}