import React, {useMemo} from 'react'
import { ClauseScoringEntity } from '../../../domain/entities'
import { RenderSegments} from '../../../domain/types/ClauseParams'
import { idfySegmentation, styleSegmentation } from './ClauseScoring'
import { genIdFactory } from '../../../domain/ContractTemplate'
import SlateEditor from './StaleEditor/SlateEditor'
import { getRenderSegments } from '../../../helpers/segmentation'
import { useTranslation } from '../../../contexts/TranslationProvider'

function ClauseScoringContentEditor({
  applyChanges,
  clause
}: {
  applyChanges: (clause: ClauseScoringEntity) => void,
  clause: ClauseScoringEntity
}) {
  const { t, language } = useTranslation()

  const onApplySegmentation = async (clause: ClauseScoringEntity, styledSegments: ClauseScoringEntity['segmentation']['segmentedText'], deletedSegments: RenderSegments) => {
		let segmentation: ClauseScoringEntity['segmentation']
		segmentation = clause.segmentation
		const styledSegmentation = styleSegmentation(segmentation, styledSegments, deletedSegments)
		const newSegmentation = idfySegmentation(styledSegmentation, genIdFactory(clause.index))
		clause.segmentation = newSegmentation
		applyChanges(clause)
	}

  const persistentSegments = useMemo(() => {
		const renderSegments = getRenderSegments(clause.segmentation.segmentedText, {},
			{}, {}, clause.segmentation.segmentedParams, t, language)
			.map(seg => {
				return {
					...seg,
					value: seg.value?.replaceAll(/\r\n/g, "\n"),
				}
			})
		return renderSegments
	}, [clause.segmentation.segmentedText, clause.segmentation.segmentedParams])

  return (
    <div style={{border: "2px solid #fff3cd", marginTop: "30px"}}>
      <SlateEditor
        segments={persistentSegments}
        onApplySegmentation={onApplySegmentation}
        clause={clause}
        params={clause.segmentation.segmentedParams}
        />
    </div>
  )
}

export default ClauseScoringContentEditor