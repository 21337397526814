import { useContext, useEffect, useRef, useState } from "react";
import InputValuesContext from "../../../contexts/InputValuesContext";
import SelectionContext from "../../../contexts/SelectionContext";
import RenderSegmentedText from "../../../components/RenderSegmentedText";
import { useCollapse } from "react-collapsed";
import { GoChevronDown } from "react-icons/go";
import { GoChevronUp } from "react-icons/go";
import { useTranslation } from "../../../contexts/TranslationProvider";
import {
  ClauseEntity,
  ClauseScoringEntity,
  ContractScoringEntity,
  RessourceScoringEntity,
} from "../../../domain/entities";
import AIResponseOnClause from "./AIResponseOnClause";
import useApiClientWithLoading from "../../../services/api/ApiClient";
import { RessourceScoringClient } from "../../../services/api/RessourceScoring";
import ClauseComparisonModal from "../../../components/modals/ClauseComparisonModal";
import { toast } from "react-toastify";
import AggregateModal from "./AggregateModal";
import ClauseScoringContentEditor from "./ClauseScoringContentEditor";
import { ClauseScoringClient } from "../../../services/api/ClauseScoringClient";
const { default: EditIcon } = require("../../../assets/edit_icon.svg");
const { default: EditIconOn } = require("../../../assets/edit_icon_on.svg");
const { default: CommentIcon } = require("../../../assets/comment_icon.svg");
const {
  default: CommentIconOn,
} = require("../../../assets/comment_icon_on.svg");
const { default: AiComparison } = require("../../../assets/compare-icon.svg");

function Clause({
  clause,
  containerId,
  canEdit = true,
  canCompare = true,
  canComment = true,
  mappedClauses,
  updateRessource,
  handleClauseAdd,
  handleClauseDismiss,
  handleClauseReplace,
}: {
  clause: ClauseScoringEntity | ClauseEntity;
  containerId: "source-container" | "target-container";
  canEdit?: boolean;
  canCompare?: boolean;
  canComment?: boolean;
  mappedClauses: RessourceScoringEntity["mappedClauses"];
  updateRessource: () => void;
  handleClauseAdd?: (clauseId: number) => void;
  handleClauseDismiss?: (clauseId: number) => void;
  handleClauseReplace?: (clauseId: number, replacedById: number) => void;
}) {
  const {
    inputValues,
    fileNames,
    beneficialsMap,
    segmentsOverrides,
    setSegmentsOverrides,
  } = useContext(InputValuesContext);
  const {
    setSelected,
    selected,
    setTarget,
    target,
    setContainerId,
  } = useContext(SelectionContext);
  const apiClient = useApiClientWithLoading();
  const ressourceScoringClient = new RessourceScoringClient(apiClient);
  const clauseScoringClient = new ClauseScoringClient(apiClient);
  const isReadable = true;
  const isSelected = selected.clauseId === clause.id;
  const isTarget = target.clauseId === clause.id;
  const [isCommenting, setIsCommenting] = useState(false);
  const [showComparisonModal, setShowComparisonModal] = useState(false);
  const [isExpanded, setExpanded] = useState(isReadable);
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });
  const [score, setScore] = useState<number | null>(null);
  const [isClauseAnalysisLoading, setIsClauseAnalysisLoading] = useState(false);
  const [openAggregateModal, setOpenAggregateModal] = useState(false);
  const [correspondantClauseId, setCorrespondantClauseId] = useState<
    number | null
  >(null);
  const [isClauseEditing, setIsClauseEditing] = useState(false);
  const { t, language } = useTranslation();
  const getBadgeContent = () => {
    if (score === 1) {
      return {
        text: t("contractCompliancePage.categories.acceptable"),
        className: "badge-green",
      };
    } else if (score === 2 || score === 3) {
      return {
        text: t("contractCompliancePage.categories.nonStandard"),
        className: "badge-yellow",
      };
    } else if (score === 4) {
      if (containerId === "source-container") {
        return {
          text: t("contractCompliancePage.categories.noMatch"),
          className: "badge-red",
        };
      } else if (containerId === "target-container") {
        return {
          text: t("contractCompliancePage.categories.missing"),
          className: "badge-gray",
        };
      }
    }
    return { text: "Unknown", className: "badge-gray" };
  };
  const { text, className } = getBadgeContent();
  useEffect(() => {
    setExpanded(isReadable);
    return () => {};
  }, [isSelected, isReadable, isTarget]);

  const handleClick = (event) => {
    if (!event.defaultPrevented) {
      setSelected({
        eventType: "Text",
        clauseId: clause.id,
        paramName: null,
      });
      setContainerId(containerId);
      if (correspondantClauseId) {
        setTarget({
          eventType: "Text",
          clauseId: correspondantClauseId,
          paramName: null,
        });
      } else {
        setTarget({
          eventType: null,
          clauseId: null,
          paramName: null,
        });
      }
    }
  };
  useEffect(() => {
    let foundValue;
    if (containerId === "source-container") {
      foundValue = Object.values(mappedClauses).find(
        (value) => value.sourceClauseId === clause.id
      );
      if (foundValue) {
        setScore(foundValue.score);
        setCorrespondantClauseId(foundValue.targetClauseId);
      }
    } else if (containerId === "target-container") {
      foundValue = Object.values(mappedClauses).find(
        (value) => value.targetClauseId === clause.id
      );
      if (foundValue) {
        setScore(foundValue.score);
        setCorrespondantClauseId(foundValue.sourceClauseId);
      }
    }
  }, [clause.id, containerId, mappedClauses]);
  const analyzeClause = async (clauseId: number) => {
    setIsClauseAnalysisLoading(true);
    try {
      const response = await ressourceScoringClient.analyzeRessourceClause(
        clauseId
      );
      if (response) {
        updateRessource();
        setIsClauseAnalysisLoading(false);
      }
    } catch (error) {
      toast.error(t("contractCompliancePage.errorMessage"));
    }
  };
  const [currentClause, setCurrentClause] = useState<ClauseScoringEntity | null>(
    null
  );
  useEffect(() => {
    if (clause) {
      setCurrentClause(clause as ClauseScoringEntity);
    }
  }, [clause]);

  const applyContentChanges = async (resultClause: ClauseScoringEntity) => {
    try {
      setCurrentClause(prev=> {return {...prev, segmentation: resultClause.segmentation}})
    } catch (error) {
      toast.error(t("contractCompliancePage.errorMessage"));
    }
  };

  useEffect(() => {
    const clauseScoring = (clause as ClauseScoringEntity).scoring;
    if (clauseScoring) {
      if (
        (clauseScoring.score == 2 || clauseScoring.score == 3) &&
        (!clauseScoring.comment || clauseScoring.comment.length == 0)
      ) {
        analyzeClause(clause.id);
      }
    }
  }, [(clause as ClauseScoringEntity).scoring]);

  // update content from editor
 const handleToggleEdit = () => {
  if(containerId === "source-container"){
    try{
    const updatedClause = clauseScoringClient.update(clause.id, currentClause);
    if(updatedClause){
      toast.success(t("contractCompliancePage.toasts.clauseUpdatedSuccess"));
    }
    }catch(error){
      console.log(error);
      toast.error(t("contractCompliancePage.toasts.clauseUpdatedError"));
    }
  }
 }
  const formattedRawRef = useRef(null);
  const clauseRef = useRef(null);
  const textareaRef = useRef(null);
  return (
    <div
      key={clause.id}
      className={`edit-contract-compliance-clause selectable px-4 py-2 mx-1 my-2 clause-${
        clause.id
      } ${
        isSelected || (isTarget && isExpanded) ? "clause-selected" : ""
      } score-${(score == 4 && containerId == "target-container" ) ? 5 : score}`}
      onClick={handleClick}
      id={`${clause.id}`}
      ref={clauseRef}
      style={{
        minHeight: isExpanded ? "130px" : undefined,
        width: isSelected ? "99%" : undefined,
      }}
    >
      <div {...getToggleProps()} className="edit-contract-clause-header">
        <div>
          <div className={`badge ${className}`}>{text}</div>
          <b className="mx-2">{clause.name}</b>
        </div>
        <div>
          {!isExpanded ? (
            <GoChevronDown
              style={{ marginLeft: "15px" }}
              onClick={(e) => {
                e.preventDefault();
                isReadable && setExpanded(!isExpanded);
              }}
            />
          ) : (
            <GoChevronUp
              onClick={(e) => {
                e.preventDefault();
                if (isReadable) {
                  setExpanded(!isExpanded);
                  setIsCommenting(false);
                  setIsClauseEditing(false);
                }
              }}
            />
          )}
        </div>
      </div>
      {isExpanded && (
        <div
          className={`edit-contract-clause-header-icons ${
            language == "ar" && "edit-contract-clause-header-icons-arabic"
          }`}
        >
          {/* {canCompare && (
            <img
              src={AiComparison}
              style={{ paddingLeft: language == "ar" && "10px" }}
              className="edit-contract-clause-header-icon"
              alt="#"
              onClick={() => setShowComparisonModal(true)}
            />
          )} */}
          {canComment && (
            <img
              src={isCommenting ? CommentIconOn : CommentIcon}
              className="edit-contract-clause-header-icon"
              alt="#"
              style={{ paddingLeft: language == "ar" && "10px" }}
              onClick={() => setIsCommenting(!isCommenting)}
            />
          )}
          {canEdit && (
            <img
              src={isClauseEditing ? EditIconOn : EditIcon}
              className="edit-contract-clause-header-icon"
              alt="#"
              style={{ paddingLeft: language == "ar" && "10px" }}
              onClick={() => {
                setIsClauseEditing(prev => {
                  if(prev){
                    handleToggleEdit();
                  }
                  return !prev;
                });
              }}
            />
          )}
        </div>
      )}

      <div {...getCollapseProps()}>
        {clause.segmentation?.segmentedText?.length > 0 && !(containerId == "source-container") && (
          <div ref={isSelected ? formattedRawRef : null}>
            <RenderSegmentedText
              clauseId={clause.id}
              segmentation={clause.segmentation}
              fileNames={fileNames}
              beneficialsMap={beneficialsMap}
              segmentsOverrides={segmentsOverrides}
              onSegmentChange={(id, text) =>
                setSegmentsOverrides({
                  ...segmentsOverrides,
                  [id]: text,
                })
              }
              isSelected={isSelected}
              isEditing={isClauseEditing && isSelected && canEdit}
              formattedRawRef={formattedRawRef}
              inputValues={inputValues}
            />
          </div>
        )}

        {
          clause.segmentation?.segmentedText?.length > 0 && (containerId == "source-container") && (
            <>
            {!isClauseEditing ? 
            <>
            <div ref={isSelected ? formattedRawRef : null}>
              <RenderSegmentedText
                clauseId={clause.id}
                segmentation={clause.segmentation}
                fileNames={fileNames}
                beneficialsMap={beneficialsMap}
                segmentsOverrides={segmentsOverrides}
                onSegmentChange={(id, text) =>
                  setSegmentsOverrides({
                    ...segmentsOverrides,
                    [id]: text,
                  })
                }
                isSelected={isSelected}
                isEditing={isClauseEditing && isSelected && canEdit}
                formattedRawRef={formattedRawRef}
                inputValues={inputValues}
              />
            </div>
            </> : 
            <>
            <ClauseScoringContentEditor
            applyChanges={applyContentChanges}
            clause={currentClause}
            />
            </>}
            </>
          )
        }

        {showComparisonModal && (
          <ClauseComparisonModal
            onClose={() => setShowComparisonModal(false)}
            currentClause={clause}
            inputValues={inputValues}
            fileNames={fileNames}
            beneficialsMap={beneficialsMap}
            segmentsOverrides={segmentsOverrides}
            excludedSubClauses={null}
          />
        )}
        {openAggregateModal && (
          <AggregateModal
            onClose={() => setOpenAggregateModal(false)}
            onConfirmAggregation={async () =>{
              updateRessource();
              setOpenAggregateModal(false);
            }}
            currentClause={clause}
            targetClauseId={correspondantClauseId}
          />
        )}
        {containerId == "target-container" && score == 4 && (
          <>
            <button
              className="mt-4 mb-2 compliance-button"
              onClick={() => handleClauseAdd(clause.id)}
            >
              {" "}
              {t("contractCompliancePage.buttons.add")}
            </button>
          </>
        )}
        {containerId == "source-container" && (score == 2 || score == 3) && (
          <div className="button-grp">
            <button
              className="mt-4 mb-2 compliance-grp-button"
              onClick={() =>
                handleClauseReplace(clause.id, correspondantClauseId)
              }
            >
              {t("contractCompliancePage.buttons.replace")}
            </button>
            <button 
            className="compliance-grp-button mt-4 mb-2"
            onClick={() => setOpenAggregateModal(true)}
            >
            {t("contractCompliancePage.buttons.aggregate")}
              </button>
          </div>
        )}
        {containerId == "source-container" && score == 4 && (
          <>
            <button
              className="mt-4 mb-2 compliance-button"
              onClick={() => handleClauseDismiss(clause.id)}
            >
              {t("contractCompliancePage.buttons.delete")}
            </button>
          </>
        )}
        {containerId == "source-container" && (score == 2 || score == 3) && (
          <>
            {
              <AIResponseOnClause
                response={(clause as ClauseScoringEntity).scoring}
                isAnalysisLoading={isClauseAnalysisLoading}
              />
            }
          </>
        )}
      </div>
    </div>
  );
}

export default Clause;
