import React, { useContext, useState } from "react";
import { useTranslation } from "../../../contexts/TranslationProvider";
import { RessourceScoringEntity } from "../../../domain/entities";
import SelectionContext from "../../../contexts/SelectionContext";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
type Value = {
  targetClauseId: number | null;
  sourceClauseId: number | null;
  score: 1 | 2 | 3 | 4;
};
const categorizeValues = (values: Value[]) => {
  const categories = {
    unacceptable: [] as Value[],
    nonStandard: [] as Value[],
    acceptable: [] as Value[],
    missing: [] as Value[],
  };

  values.forEach((value) => {
    const { targetClauseId, sourceClauseId, score } = value;
    if (targetClauseId === null) {
      categories.unacceptable.push(value);
    } else if (sourceClauseId === null) {
      categories.missing.push(value);
    } else if (score === 2 || score === 3) {
      categories.nonStandard.push(value);
    } else if (score === 1 || score === 4) {
      categories.acceptable.push(value);
    }
  });

  return categories;
};

const SummaryModal = ({
  onClose,
  clauses,
  mappedClauses,
  templateClauses,
}: {
  onClose: () => void;
  clauses: any[];
  mappedClauses: RessourceScoringEntity["mappedClauses"];
  templateClauses: any;
}) => {

  const { setSelected, setTarget, setContainerId } =
    useContext(SelectionContext);
  const [expandedCategory, setExpandedCategory] = useState<string | null>(null);
  const { t, language } = useTranslation();
  const { unacceptable, nonStandard, acceptable, missing } =
    categorizeValues(mappedClauses);
  const toggleCategory = (category: string) => {
    setExpandedCategory((prev) => (prev === category ? null : category));
  };
  const getClausesForCategory = (values: Value[], key: string) => {
    const source = key === "missing" ? templateClauses : clauses;
    return source.filter((clause) =>
      values.some((value) =>
        key === "missing"
          ? value.targetClauseId === clause.id
          : value.sourceClauseId === clause.id
      )
    );
  };
  const handleClauseSummaryClick = (key: string, clauseId: number) => {
    const containerId =
      key === "missing" ? "target-container" : "source-container";

    let foundValue;
    let newTargetClauseId = null;

    if (containerId === "source-container") {
      foundValue = Object.values(mappedClauses).find(
        (value) => value.sourceClauseId === clauseId
      );
      if (foundValue) {
        newTargetClauseId = foundValue.targetClauseId;
      }
    } else if (containerId === "target-container") {
      foundValue = Object.values(mappedClauses).find(
        (value) => value.targetClauseId === clauseId
      );
      if (foundValue) {
        newTargetClauseId = foundValue.sourceClauseId;
      }
    }
    setSelected({
      eventType: "Text",
      clauseId: clauseId,
      paramName: null,
    });

    setTarget({
      eventType: "Text",
      clauseId: newTargetClauseId,
      paramName: null,
    });
    setContainerId(containerId);
    onClose();
  };
  return (
    <div>
      <div className="modal-backdrop"></div>
      <div id="contractz-lab">
        <div className="modal d-flex justify-content-center align-items-center">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  {t("contractCompliancePage.modalTitle")}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={onClose}
                  aria-label="Close"
                  style={{ marginRight: language === "ar" ? "80%" : undefined }}
                ></button>
              </div>

                <div className="modal-body pb-3">
                  <div
                    style={{
                      width: "100%",
                      marginTop: "10px",
                      minHeight: "80vh",
                    }}
                  >
                    <p
                      style={{
                        marginBottom: "20px",
                        color: "#6c757d",
                        fontSize: "14px",
                      }}
                    >
                      {t("contractCompliancePage.modalDescription")}
                    </p>
                    {[
                      {
                        key: "acceptable",
                        label: t(
                          "contractCompliancePage.categories.acceptable"
                        ),
                        values: acceptable,
                        className: "green",
                      },
                      {
                        key: "nonStandard",
                        label: t(
                          "contractCompliancePage.categories.nonStandard"
                        ),
                        values: nonStandard,
                        className: "yellow",
                      },
                      {
                        key: "unacceptable",
                        label: t("contractCompliancePage.categories.noMatch"),
                        values: unacceptable,
                        className: "red",
                      }, 
                      {
                        key: "missing",
                        label: t("contractCompliancePage.categories.missing"),
                        values: missing,
                        className: "grey",
                      },
                    ].map(({ key, label, values, className }) => (
                      <div
                        key={key}
                        className={`summary-category ${className}`}
                      >
                        <div
                          className={`category-header ${
                            expandedCategory === key ? "expanded" : ""
                          }`}
                          onClick={() => toggleCategory(key)}
                        >
                          <span>{label}</span>
                          <div>
                            <span>{values.length}</span>
                            <span>
                              {expandedCategory === key ? (
                                <BiChevronUp size={32} />
                              ) : (
                                <BiChevronDown size={32} />
                              )}
                            </span>
                          </div>
                        </div>

                        {expandedCategory === key && (
                          <div
                            className={`clauses-list ${
                              expandedCategory === key ? "expanded" : ""
                            }`}
                          >
                            {getClausesForCategory(values, key).map((clause) =>
                              clause?.id ? (
                                <div
                                  key={clause.id}
                                  className={`edit-contract-compliance-clause selectable px-4 py-2 mx-1 my-2 clause-${clause.id}`}
                                  id={`${clause.id}`}
                                  style={{
                                    backgroundColor: "white",
                                  }}
                                  onClick={() =>
                                    handleClauseSummaryClick(key, clause.id)
                                  }
                                >
                                  <div className="edit-contract-clause-header">
                                    <div>
                                      <b className="mx-2">{clause.name}</b>
                                    </div>
                                    <div
                                      className="view-details"
                                    >
                                      {t(
                                        "contractCompliancePage.buttons.viewDetails"
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ) : null
                            )}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                  <div className="form-group">
                    <div className="modal-buttons">
                      <button className="btn view-details-btn" onClick={onClose}>
                        {t("contractCompliancePage.buttons.viewDetails")}
                      </button>
                
                    </div>
                  </div>
                </div>
             
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SummaryModal;
