import React from 'react'
import { useTranslation } from '../../../../contexts/TranslationProvider'
import { FormType } from '../../../../domain/types/FormParams';
import { EditFormTemplateContext } from "../../../../contexts/EditFormTemplateContext";

function FileRender({ children, param }: {
  children: React.JSX.Element;
  param: FormType.AnyParam | FormType.CSVParam;
}) {
  const { } = React.useContext(EditFormTemplateContext);
  const { t, language } = useTranslation();

  const translationTypes = "pages.contractTemplateParam.params"

  return (
    <div className="input-param">
      <div className="input-type">{t(`${translationTypes}.${param.type}`)}</div>
      {children}
    </div>
  )
}

export default FileRender