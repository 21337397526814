import React, { useContext, useMemo, useState } from 'react'
import { EditFormTemplateContext } from "../../../../contexts/EditFormTemplateContext";
import { useTranslation } from "../../../../contexts/TranslationProvider";
import CustomCombobox from '../../../../components/common/CustomCombobox';
import CustomPropertiesContext from '../../../../contexts/CustomPropertiesContext';
import { getAllParams } from '../../../../domain/FormTemplate';
import ParamCombobox from '../../../ContractTemplate/components/ParamCombobox';
import { FormType } from '../../../../domain/types/FormParams';

function PropertyRender({ children, isEditing, param }: {
  children: React.JSX.Element;
  param: FormType.PropertyParam;
  isEditing: boolean;
}) {
  const { onParamChanged, formTemplate, onParamTypeChange } = React.useContext(EditFormTemplateContext);
  const { t, language } = useTranslation();

  const [isOpen, setIsOpen] = useState(false)
  const [isParentOpen, setIsParentOpen] = useState(false)
  const { tags, tagMapping } = useContext(CustomPropertiesContext)

  const translationPath = t("pages.editionFormTemplate.edition");

  const parentTagParams = useMemo(() => {
    const params = getAllParams(formTemplate)
    if (param.args.tag && tagMapping[param.args.tag]) {
      const parentTag = tagMapping[param.args.tag]
      return params.filter(p => p.type == 'property' && p.args.tag == parentTag)
    }
    return []
  }, [formTemplate, param.args.tag])

  return (
    <div className={`input-param`}>
      <ParamCombobox param={param} onChangeParamType={(type) => onParamTypeChange(param.name, type as any)} />
      {children}
      <div className="">
        {isEditing && (
          <div style={{ width: "95%" }}>
            <CustomCombobox
              label={""}
              options={tags}
              fullWidth
              value={param?.args?.tag ?? ""}
              onChange={(value: string) => {
                param.args.tag = value
                onParamChanged(param.name, { ...param })
              }}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
            />
          </div>
        ) || (
            <label style={{ justifyContent: language === "ar" ? "right" : "left" }} className="custom-form-label mt-2" htmlFor={param.args.tag}>
              {translationPath.parameterTag}: {param.args.tag || ""}
            </label>
          )
        }
        {parentTagParams.length > 0 && (
          isEditing && (
            <div style={{ width: "95%" }}>
              <CustomCombobox
                label={""}
                options={parentTagParams.map(p => p.name)}
                fullWidth
                value={param?.args?.parentParam ?? ""}
                onChange={(value: string) => {
                  param.args.parentParam = value
                  onParamChanged(param.name, { ...param })
                }}
                isOpen={isParentOpen}
                setIsOpen={setIsParentOpen}
              />
            </div>
          ) || (
            <label style={{ justifyContent: language === "ar" ? "right" : "left" }} className="custom-form-label mt-2" htmlFor={param.args.parentParam}>
              {translationPath.parentTag}: {param.args.parentParam || ""}
            </label>
          )
        )
        }
      </div>
    </div>

  )
}

export default PropertyRender