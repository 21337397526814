import React, { useState } from 'react'
import expand from '../../../assets/icons/expand'
import { useTranslation } from '../../../contexts/TranslationProvider';
import { SegmentedClauseParam } from '../../../domain/types/ClauseParams';
import { FormParam } from '../../../domain/types/FormParams';


function ParamCombobox({
	param,
	onChangeParamType
}:
	{
		param: SegmentedClauseParam | FormParam,
		onChangeParamType: (type: SegmentedClauseParam['type'] | FormParam['type']) => void
	}
) {
	const [expanded, setExpanded] = useState(false)
	const { t, language } = useTranslation();
	const translationTypes = "pages.contractTemplateParam.params"

	const handleExpand = () => {
		setExpanded(!expanded)
	}
	const types: (SegmentedClauseParam['type'] | FormParam['type'])[] = ['enum', 'list', 'table', 'boolean', 'number', 'string', 'date']
	const currentTypes = types.filter(type => type !== param.type)
	return (
		<div style={{ width: "77px" }} className='ms-2' onClick={handleExpand}>
			<div className='param-current'>
				<span className='param-current-name'>{t(`${translationTypes}.${param.type}`)}</span>
				&nbsp;&nbsp;
				<span className={`rotate-expand ${expanded && 'rotate-expand-active'}`}>{expand}</span>
			</div>
			{expanded && (
				<div className='param-type-list'>
					{currentTypes.map(type => (
						<div className='param-type-list-item' key={type} onClick={() => onChangeParamType(type)}
						>
							{t(`${translationTypes}.${type}`)}
						</div>
					))}
				</div>
			)}
		</div>

	)
}

export default ParamCombobox