import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./ContractCompliancePage.scss";
import Loading from "../../components/common/Loading";
import SaveDraftModel from "../../components/modals/SaveDraftModal";
import { PromiseSub, createPromiseSub } from "../../utils/promise";
import useApiClientWithLoading from "../../services/api/ApiClient";
import { useState, useEffect, useMemo } from "react";
import ContractCompliance from "./components/ContractCompliance";
import { RessourceScoringClient } from "../../services/api/RessourceScoring";
import {
  ClauseScoringEntity,
  RessourceScoringEntity,
} from "../../domain/entities";
import SelectionContext, {
  SelectedType,
} from "../../contexts/SelectionContext";
import { ClauseScoringClient } from "../../services/api/ClauseScoringClient";
import { toast } from "react-toastify";
import { useTranslation } from "../../contexts/TranslationProvider";
import EventManager from "../../services/EventManager";
import ExportComplianceModal from "../../components/modals/ExportComplianceModal";
import ReorderModal from "./components/ReorderModal";

function ContractCompliancePage() {
  const { t } = useTranslation();
  const location = useLocation();
  const [selected, setSelected] = useState<SelectedType>({
    eventType: null,
    clauseId: null,
    paramName: null,
  });
  const [target, setTarget] = useState<SelectedType>({
    eventType: null,
    clauseId: null,
    paramName: null,
  });
  const [isClauseEditing, setIsClauseEditing] = useState(false);
  const [containerId, setContainerId] = useState<"source-container" | "target-container" | null>(null);
  const { ressourceScoringId } =
    useParams<{ ressourceScoringId?: string }>() || {};
  const apiClient = useApiClientWithLoading();
  const ressourceScoringClient = new RessourceScoringClient(apiClient);
  const clauseScoringClient = new ClauseScoringClient(apiClient);
  const [draft, setSaveDraft] = useState(false);
  const [sub, setSub] = useState<PromiseSub>(null);
  const [openExportModal, setOpenExportModal] = useState(false);
  const [openReorderModal, setOpenReorderModal] = useState(false);
  const navigate = useNavigate();
  const [isDataReady, setIsDataReady] = useState(false);
  const [data, setData] = useState<RessourceScoringEntity>(null)
  const updateRessource = async () => {
    try {
      const data = await ressourceScoringClient.getById(
        parseInt(ressourceScoringId)
      );
      setData(data);
    } catch (e) {
      toast.error(t("contractCompliancePage.errorMessage"))
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsDataReady(false);
        await updateRessource();
        setIsDataReady(true);
      } catch (e) {
        toast.error(t("contractCompliancePage.errorMessage"))
      }
    };
    fetchData();
  }, [ressourceScoringId]);
  async function updateClausesInDB(
    clauses: ClauseScoringEntity[],
    clauseToAdd: ClauseScoringEntity,
    clauseScoringClient: {
      update: (
        id: number,
        data: ClauseScoringEntity,
        schema?: string
      ) => Promise<ClauseScoringEntity>;
    },
    schema?: string
  ): Promise<void> {
    for (const clause of clauses) {
      if (
        clause.index &&
        clauseToAdd.index &&
        Number(clause.index) >= Number(clauseToAdd.index)
      ) {
        const updatedClause: ClauseScoringEntity = {
          ...clause,
          index: (Number(clause.index) + 1).toString(),
        };
        await clauseScoringClient.update(clause.id, updatedClause, schema);
      }
    }
  }

  const handleClauseAdd = async (clauseId: number) => {
    try {
      const clauseToAdd = data.template.clauses.find(
        (clause) => clause.id === clauseId
      );
      const mappedClause: ClauseScoringEntity = {
        ressourceScoringId: data.id,
        name: clauseToAdd.name,
        segmentation: clauseToAdd.segmentation,
        index: clauseToAdd.index,
      };
      const addedClause = await clauseScoringClient.create(mappedClause);
      const newClause: ClauseScoringEntity = {
        ...addedClause,
        scoring: {
          score: 1,
          sourceClauseId: addedClause.id,
          targetClauseId: clauseId,
        },
      };
      const updatedClause = await clauseScoringClient.update(
        addedClause.id,
        newClause
      );
      setTarget({
        clauseId: updatedClause.id,
        eventType: "Text",
        paramName: null,
      });
      updateClausesInDB(data.clauses, clauseToAdd, clauseScoringClient);
      await updateRessource();
    } catch (e) {
      toast.error(t("contractCompliancePage.errorMessage"))
    }
  };

  const handleClauseDismiss = async (clauseId: number) => {
    try {
      await clauseScoringClient.delete(clauseId);
      await updateRessource();
    } catch (e) {
      toast.error(t("contractCompliancePage.errorMessage"))
    }
  };
  const handleClauseReplace = async (
    clauseId: number,
    replacedById: number
  ) => {
    try {
      const replacementClause = data.template.clauses.find(
        (clause) => clause.id === replacedById
      );
      const clauseScoringEntity: ClauseScoringEntity = {
        id: clauseId,
        segmentation: replacementClause.segmentation,
        scoring: {
          sourceClauseId: clauseId,
          targetClauseId: replacedById,
          score: 1 as 1 | 2 | 3 | 4,
        },
      };
      await clauseScoringClient.update(clauseId, clauseScoringEntity);
      await updateRessource();
    } catch (e) {
      toast.error(t("contractCompliancePage.errorMessage"))
    }
  };
  const handleSaveRessourceScoring = async () => {
    try{
      if(!data)
        {
          console.error("No data available to save.");
          return;
        }
      const ressourceScoring = await ressourceScoringClient.update(data.id, data);
      if(ressourceScoring){
        return ressourceScoring;
      }
    }catch(error){
      console.error(error)
    }
  };
  const saveRessourceScoringHandler = async () => {
    const ressourceScoring = await handleSaveRessourceScoring();
    if (ressourceScoring) {
      toast.success(t("contractCompliancePage.toasts.saveSuccess"))
      navigate(-1);
    }else{
      toast.error(t("contractCompliancePage.toasts.saveError"))
    }
  };
  const recompileRessourceScoringHandler = async () => {
    try {
      setIsDataReady(false);
      const newScoring = await handleSaveRessourceScoring();
      const ressourceScoring = await ressourceScoringClient.mapRessourceClauses(newScoring.id);
      if (ressourceScoring) {
        updateRessource();
        toast.success(t("contractCompliancePage.toasts.recompileSuccess"))
      }
    } catch (error) {
      console.error(error);
      toast.error(t("contractCompliancePage.toasts.recompileError"))
    }finally{
      setIsDataReady(true);
    }
  };
  const handleReorderClauses = async (orderedClauses: ClauseScoringEntity[]) => {
    try {
      setIsDataReady(false);
      const originalClauses: ClauseScoringEntity[] = data.clauses;
      const reorderedClauses = orderedClauses.map((clause, index) => {
        const originalClause = originalClauses.find(
          (originalClause) => originalClause.id === clause.id
        );
        return {
          ...originalClause,
          index: `${index + 1}`,
        };
      });
      const reorderedRessourceScoring = { ...data, clauses: reorderedClauses };
      const result = await ressourceScoringClient.update(data.id, reorderedRessourceScoring, "full");
      if (result) {
        toast.success(t("contractCompliancePage.toasts.reorderSuccess"))
        await updateRessource();
      }
    } catch (error) {
      console.error(error);
      toast.error(t("contractCompliancePage.toasts.reorderError"))
    }finally{
      setIsDataReady(true);
    }
  }
  const openModalHandler = () => {
    setOpenExportModal(true);
  }
  const openReorderModalHandler = () => {
    setOpenReorderModal(true);
  }
  
  useEffect(() => {
    EventManager.subscribe('SaveRessourceScoring', saveRessourceScoringHandler);
    EventManager.subscribe('ExportRessourceScoring', openModalHandler);
    EventManager.subscribe('RecompileRessourceScoring', recompileRessourceScoringHandler);
    EventManager.subscribe('ReorderRessourceScoring', openReorderModalHandler);
    return () => {
      EventManager.unsubscribe('SaveRessourceScoring', saveRessourceScoringHandler);
      EventManager.unsubscribe('ExportRessourceScoring', openModalHandler);
      EventManager.unsubscribe('ReorderRessourceScoring', openReorderModalHandler);
      EventManager.unsubscribe('RecompileRessourceScoring', recompileRessourceScoringHandler);
    };

  }, [ressourceScoringId, data]);
  const { previousPathname } = location?.state || { previousPathname: [] };
  const selectionContextValue = useMemo(
    () => ({
      selected,
      setSelected,
      isClauseEditing,
      setIsClauseEditing,
      target,
      setTarget,
      containerId,
      setContainerId,
    }),
    [
      selected,
      setSelected,
      isClauseEditing,
      setIsClauseEditing,
      target,
      setTarget,
      containerId,
      setContainerId,
    ]
  );
  return (
    <div className="contract-container d-flex justify-content-between align-items-center w-100">
      {openExportModal && (<ExportComplianceModal onClose={() => setOpenExportModal(false)} ressource={data} template={data?.template}/>)}
      {openReorderModal && (<ReorderModal 
      onClose={() => setOpenReorderModal(false)} 
      clauseList={data?.clauses}
      handleReorderClauses={handleReorderClauses}
      />)}

      {!isDataReady ? (
        <div className="contract-loading-container">
          <Loading height="90vh" />
        </div>
      ) : (
        <div className="edition-contract-page">
          <div className="px-2 container-paths">
            {previousPathname &&
              previousPathname?.map((item, index) => (
                <span key={index}>
                  <span
                    className="previous-pathname"
                    onClick={() => navigate(item.link)}
                  >
                    {item.label}
                  </span>
                  <span className="path-separator"> {">"} </span>
                </span>
              ))}
            {previousPathname && previousPathname.length > 0 && (
              <span className="current-pathname">{data.name}</span>
            )}
          </div>
          <SelectionContext.Provider value={selectionContextValue}>
            <ContractCompliance
              clauses={data?.clauses}
              contractName={data?.name}
              templateData={data?.template}
              mappedClauses={data?.mappedClauses}
              updateRessource={updateRessource}
              handleClauseAdd={handleClauseAdd}
              handleClauseDismiss={handleClauseDismiss}
              handleClauseReplace={handleClauseReplace}
            />
          </SelectionContext.Provider>
        </div>
      )}

      {draft && (
        <SaveDraftModel
          type="contract"
          onClose={() => {
            setSaveDraft(false);
            sub.reject();
          }}
          onSave={(saved: boolean) => {
            sub.resolve();
          }}
        />
      )}
    </div>
  );
}

export default ContractCompliancePage;
