import { calculateContractClauseCompletionPercentage } from "../helpers/CompletionPercentage";
import { ClauseEntity, GroupEntity } from "./entities";
import { evaluateSingleConstraint } from "./Form";
import { SegmentedClauseParam } from "./types/ClauseParams";
import { FormParam } from "./types/FormParams";
import { RessourceStatus, statuses } from "./types/status";
import { WorkflowTransitionCondition } from "./Workflow";


type GroupTransitionConditionData = {
  validatedGroups: GroupEntity[];
  status: RessourceStatus;
  params: (FormParam | SegmentedClauseParam)[];
  values: Record<string, number>;
  clauses: ClauseEntity[];
}
export function isConditionMet(condition: WorkflowTransitionCondition, data: GroupTransitionConditionData): boolean {
  const { validatedGroups, status, values, params, clauses } = data
  switch (condition.type) {
    case 'constraint':
      return evaluateSingleConstraint(condition.value, values, params)
    case 'status':
      const currentIndex = statuses.findIndex((s) => s == status)
      const targetIndex = statuses.findIndex((s) => s == condition.status)
      return targetIndex <= currentIndex
    case 'validation':
      return !!validatedGroups.find(g => g.id == condition.groupId)
    case 'clause':
      const clause = clauses?.find(c => c.id == condition.clauseId)
      const completion = calculateContractClauseCompletionPercentage(values, clause)
      return completion == 100
  }
}

export function areConditionsMet(conditions: WorkflowTransitionCondition[], data: GroupTransitionConditionData): WorkflowTransitionCondition[] {
  return conditions.filter((condition) => !isConditionMet(condition, data))
}